export const toString = date => {
  try {
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const esMonths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return `${day} / ${esMonths[month]} / ${year}`
  } catch (error) {
    console.log('date.toString', error)
    return ''
  }
}

export const addDays = (dateOrString, days) => {
  try {
    var date = typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString
    date.setDate(date.getDate() + days)
    return date
  } catch (error) {
    console.log('date.addDaysError', error)
    return ''
  }
}
