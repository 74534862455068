import React from 'react'
import styled from 'styled-components'
import coverImage from '../../../assets/products.png'
import Header from '../../components/header'

const Content = styled.section`
  padding: 1px;
  min-height: 70vh;
  background-image: url(${coverImage});
  background-size: cover;
  background-position: center;
  @media screen and (max-width:700px) {
    min-height: 45vh;
  }
`
const Title = styled.h1`
  text-align: center;
  font-size: 5rem;
  font-family: var(--front-font);
  color: #fff;
  padding: 60px 0px;
  @media screen and (max-width:1250px) {
    font-size: 4rem;
  }
  @media screen and (max-width:700px) {
    font-size: 3rem;
  }
  @media screen and (max-width:520px) {
    font-size: 2rem;
  }
`

const Cover = props => {
  return (
    <Content>
      <Header />
      <Title>
        Amor y dedicación
      </Title>
    </Content>
  )
}

export default Cover
