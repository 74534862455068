import React from 'react'
import styled from 'styled-components'
import Picture from './picture'
import propTypes, { string, arrayOf, object } from 'prop-types'
import lisrx from '../../../assets/li.png'

const Data = props => {
  return (
    <ItemContent>
      <PictureBox>
        <Picture
          pictures={[props.picture, ...props.pictures]}
        />
      </PictureBox>
      <DataBox>
        <Title>Ingredientes</Title>
        <Boxes>
          {props.ingredients && props.ingredients.map((ing, key) => ing.url && ing.url.length
            ? (<Item key={key}><Icon src={lisrx} /><IngredientLink href={ing.url} target='_blank'>{ing.title}</IngredientLink></Item>)
            : (<Item key={key}><Icon src={lisrx} /><Ingredient>{ing.title}</Ingredient></Item>)
          )}
        </Boxes>
        <Title>Procedimiento</Title>
        <Boxes>
          <div dangerouslySetInnerHTML={{ __html: props.process }} />
        </Boxes>
      </DataBox>
    </ItemContent>
  )
}

Data.propTypes = {
  picture: propTypes.string,
  pictures: propTypes.array,
  process: string,
  ingredients: arrayOf(object)
}

const Icon = styled.img`
  margin-right: 10px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 1em;
`
const IngredientLink = styled.a`
  flex-grow: 1; 
  color: inherit;
  :hover {
    color: var(--front-red);
  }
`
const Ingredient = styled.span`
  color: inherit;
  flex-grow: 1; 
`

const Title = styled.div`
  font-size: 2.5rem;
  font-family: var(--front-font);
  color: var(--front-red);
  font-weight: bold;
  line-height: 1em;
  @media screen and (max-width:1600px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width:1300px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width:1100px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  @media screen and (max-width:380) {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
`

const ItemContent = styled.div`
  border-radius: 5px;
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width:1600px) {
    margin: auto;
    padding: 0px;
  }
  @media screen and (max-width:959px) {
    flex-direction: column-reverse
  }
`
const PictureBox = styled.div`
  padding: 10px;
  width: 60%;
  @media screen and (max-width:959px) {
    width: 100%;
    padding: 0px;
  }
`
const DataBox = styled.div`
  padding: 10px;
  width: 38%;
  @media screen and (max-width:959px) {
    width: 100%;
    padding: 20px;
    padding: 0px;
    margin-top: 20px;
  }
`
const Boxes = styled.div`
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  color: var(--front-red-dark);
  font-size: 1.4em;
  font-family: sans-serif;
  margin-bottom: 25px;
  margin-top: 15px;
  @media screen and (max-width:1600px) {
    font-size: 1.2em;
  }
`

export default Data
