import React from 'react'
import styled from 'styled-components'
import currencyParser from '../../../helpers/currency'
import CreateInput from '../../components/input'
import { Alert } from '@material-ui/lab'
import { Fade } from '@material-ui/core'
import propTypes, { string } from 'prop-types'
import { categories, subFamilies, categoriesWithSubFamilies } from '../../../constants'
import filterObject from '../../../helpers/filterObject'
import JoditEditor from 'jodit-react'

const GeneralInfo = (props) => {
  if (props.currentStep !== 0) return null

  const handleDescriptionChange = value => {
    if (typeof value === 'object' && value.target && value.target.innerHTML) {
      return props.handleChange({ name: 'description', value: value.target.innerHTML })
    }
    props.handleChange({ name: 'description', value })
  }

  return (
    <Content>
      <Fade in={props.errors.length !== 0}>
        <GroupForm>
          <Alert severity='error'>Algunos campos son necesarios</Alert>
        </GroupForm>
      </Fade>
      <GroupForm>
        <Label>Nombre del producto</Label>
        <TitleInput
          type='text'
          name='title'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>SKU</Label>
        <TitleInput
          type='text'
          name='sku'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>¿Cuál es el precio de tu producto?</Label>
        <TitleInput
          value={props.price ? currencyParser.toCurrency(props.price) : ''}
          onChange={({ name, value }) => props.handleChange({ name, value: currencyParser.toNumber(value) })}
          type='text'
          name='price'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>Presentación</Label>
        <TitleInput
          type='text'
          name='presentation'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>Categoria</Label>
        <TitleInput
          type='select'
          name='category'
          {...props}
          options={categories}
          size='large'
        />
      </GroupForm>
      {!!props.category && (
        <GroupForm>
          <Label>Subfamilia</Label>
          <TitleInput
            type='select'
            name='subFamily'
            {...props}
            options={filterObject(subFamilies, categoriesWithSubFamilies[props.category])}
            size='large'
          />
        </GroupForm>
      )}
      <GroupForm>
        <Label>Descripción</Label>
        <div style={{ border: '1px solid var(--main-blue)', borderRadius: '5px', overflow: 'hidden' }}>
          <JoditEditor
            config={{ height: 200, askBeforePasteHTML: false }}
            onBlur={handleDescriptionChange}
            value={props.description || ''}
          />
        </div>
      </GroupForm>
    </Content>
  )
}

GeneralInfo.propTypes = {
  errors: propTypes.array,
  currentStep: propTypes.number,
  price: propTypes.number,
  handleChange: propTypes.func,
  category: string,
  description: string
}

const Content = styled('div')`
  max-width: 800px;
  margin: auto;
`
const GroupForm = styled('div')`
  margin: auto;
  margin-bottom: 10px;
  width: 80%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
const Label = styled('div')`
  color: var(--main-blue-dark);
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.1em;
`
const TitleInput = styled(CreateInput)`
  width: 100%;
  .MuiTextField-root{
    color: red
  }
`

export default GeneralInfo
