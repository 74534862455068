/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react'
import { getList } from '../modules/shipping'

const useShippingCost = () => {
  const [list, setList] = useState(null)

  const handleFetch = async () => {
    console.log('fetch shipping const list')
    const data = await getList()
    setList(data)
  }

  useEffect(() => {
    handleFetch()
  }, [])

  return (postalCode = '') => {
    if (!list || !list.length) return 0

    // calculate specific cp
    const matchedSpecificCp = list.filter(oneRegister => oneRegister.cp == postalCode)[0]
    console.log('matchedSpecificCp', matchedSpecificCp)
    if (matchedSpecificCp) return parseInt(matchedSpecificCp.cost)

    // calculate cost based on state
    const matchedState = list.filter(oneRegister => {
      const codeState = parseInt(oneRegister.cp.toString().slice(0, 2))
      const userCodeState = parseInt(postalCode.toString().slice(0, 2))
      return codeState == userCodeState
    })[0]

    console.log('matchedState', matchedState)

    if (matchedState) return parseInt(matchedState.cost)

    console.log('cp notFound')
    return 0
  }
}

export default useShippingCost
