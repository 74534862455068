/* eslint-disable no-unused-vars */
import React from 'react'
import { Content, Radio, Option, Icon, Text, Alert, Row, ButtonContent, Picture, Container } from './styled'
import { Alert as AlertBase } from '@material-ui/lab'
import { string, func, object } from 'prop-types'
import { CreditCard, LocalAtm, AccountBalance, AttachMoney } from '@material-ui/icons'
import { Grid, Box, Typography, Button, useMediaQuery } from '@material-ui/core'
import paypal from '../../../../../assets/openpay/paypal.png'
import styled from 'styled-components'
import paynet from '../../../../../assets/openpay/paynet.png'
import spei from '../../../../../assets/openpay/spei.png'
import card from '../../../../../assets/openpay/cardicon.png'

const Shipping = props => {
  const onOptionClick = value => event => {
    props.onChange({ target: { name: 'methodPay', value } })
    props.onFocus({ target: { name: 'methodPay', value } })
  }

  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Container>
      {props.errorMessage && (
        <Alert as={AlertBase} severity='error'>{props.errorMessage}</Alert>
      )}
      <Box>
        <Row container as={Grid} spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography style={{ fontWeight: 'bold', color: 'gray' }} variant='subtitle1' align={isMobile ? 'left' : 'right'}>Nombre:</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='left'>{`${props.state.name} ${props.state.lastname}`}</Typography>
          </Grid>
        </Row>
        <Row container as={Grid} spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography style={{ fontWeight: 'bold', color: 'gray' }} variant='subtitle1' align={isMobile ? 'left' : 'right'}>Enviar a:</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='left'>{`${props.state.street_number}, ${props.state.suburb}, ${props.state.postal_code}, ${props.state.city}, ${props.state.state}`}</Typography>
          </Grid>
        </Row>
        <Grid container justify='flex-end'>
          <Button onClick={event => props.goToStep(0)} style={{ color: 'gray', textTransform: 'none' }}>Cambiar</Button>
        </Grid>
      </Box>

      <Content value={props.state.methodPay || ''}>
        <Option onClick={onOptionClick('card')}>
          <Radio value='card' />
          <OptionData>
            <div>Tarjeta</div>
            <Picture src={card} style={{ marginLeft: '15px' }} />
          </OptionData>
        </Option>
        <Option onClick={onOptionClick('paypal')}>
          <Radio value='paypal' />
          <OptionData>
            <Picture src={paypal} />
          </OptionData>
        </Option>
        <Option onClick={onOptionClick('cash')}>
          <Radio value='cash' />
          <OptionData>
            Depósitos en tiendas
            <Picture src={paynet} style={{ marginLeft: '15px' }} />
          </OptionData>
        </Option>
        <Option onClick={onOptionClick('spei')}>
          <Radio value='spei' />
          <OptionData>
            Transferencia electrónica
            <Picture src={spei} style={{ marginLeft: '15px' }} />
          </OptionData>
        </Option>
      </Content>
    </Container>
  )
}

Shipping.propTypes = {
  errorMessage: string,
  onChange: func,
  state: object,
  onFocus: func,
  goToStep: func
}

const OptionData = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.2em;
`
export default Shipping
