/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import currency from '../../../helpers/currency'
import { Box, Grid, Typography } from '@material-ui/core'
import PictureResponsive from '../../../components/picture'

const Summary = (props) => {
  if (props.currentStep !== 3) return null

  return (
    <Container>
      <Title>{props.title}</Title>
      <Picture src={typeof props.picture === 'string' ? props.picture : props.picture.preview} />
      <Price>$ {currency.formatMoney(props.price)} MN</Price>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </Description>
      {!!props.colors.length && (
        <Box mt={2}>
          <Price><span>Colores:</span></Price>
          <Grid container spacing={1}>
            {props.colors.map((color, index) => (
              <Grid item xs={3} key={index}>
                <PictureResponsive src={typeof color.picture === 'string' ? color.picture : color.picture.preview} />
                <Typography color='secondary' variant='subtitle1'>{color.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  )
}

Summary.propTypes = {
  currentStep: propTypes.number,
  title: propTypes.string,
  description: propTypes.string,
  price: propTypes.number,
  picture: propTypes.oneOfType([propTypes.object, propTypes.string])
}

const Container = styled('div')`
  max-width: 500px;
  margin: auto;
  
`
const Title = styled('h2')`
  color: var(--main-blue-dark);
`
const Picture = styled('img')`
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin: auto;
  display: block;
`
const Price = styled('h1')`
  color: var(--main-blue-dark);
`
const Description = styled('p')`
  color: var(--main-blue-dark);
  font-size: 1.2em;
`

export default Summary
