/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Picture from './picture'
import propTypes, { any } from 'prop-types'
import { Box, Grid, Hidden } from '@material-ui/core'
import ButtonBase from '../../../components/link'
import InputQuiantity from '../../components/input_quantity'
import Currency from '../../../helpers/currency'
import cartSrc from '../../../assets/cart.png'
import Pic from '../../components/picture'

const Data = props => {
  // scroll and focus content
  useEffect(() => {
    setTimeout(() => {
      try {
        const distance = document.getElementById('productSelected').offsetTop
        window.scrollTo(0, distance - 160)
      } catch (error) {
        console.log('error')
      }
    }, 0)
  }, [props.title])

  return (
    <ItemContent id='productSelected'>
      <PictureBox>
        <Hidden mdUp>
          <Title>{props.title}</Title>
        </Hidden>
        <Picture
          pictures={[props.picture, ...props.pictures]}
        />
      </PictureBox>
      <DataBox>
        <Hidden smDown>
          <Title>{props.title}</Title>
        </Hidden>
        <Boxes>
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              <Price>$ {Currency.formatMoney(props.price)}</Price>
            </Grid>
            <Grid item xs={6}>
              <Cuantity>
                <QuiantityText>Cantidad:</QuiantityText>
                <InputQuiantity
                  $background='#fff'
                  onQuantityChange={props.onQuantityChange}
                  quantity={props.quantity}
                  setQuantity={props.setQuantity}
                />
              </Cuantity>
            </Grid>
          </Grid>
        </Boxes>
        <Boxes>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item xs={6}>
              Presentación
            </Grid>
            <Size>
              {props.presentation ? `${props.presentation}` : ''}
            </Size>
          </Grid>
        </Boxes>
        {!!props.colors && !!props.colors.length && (
          <Boxes>
            <Grid container justify='space-between' alignItems='center' spacing={2}>
              <Grid item xs={12}>
                Variantes
              </Grid>
              {/* <Box mb={7} /> */}
              <Grid item xs={12} container spacing={2}>
                {!!props.colors && !!props.colors.length && props.colors.map((color, index) => (
                  <ColorBox key={index} item xs={3} md={3} lg={2} $seleted={props.color && color.picture === props.color.picture}>
                    <>
                      <Pic src={color.picture} onClick={e => props.onColorChange(color)} height={100} />
                      <Box fontSize='.65em' lineHeight='1em' textAlign='center'>{color.name}</Box>
                    </>
                  </ColorBox>
                ))}
              </Grid>
            </Grid>
          </Boxes>
        )}
        <Boxes>
          Descripción
          <Descripcion>
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </Descripcion>
        </Boxes>

        <ButtonContainer>
          <Button to='/productos' variant='outlined'>Seguir comprando</Button>
          <Button handleClick={props.AddToCart} variant='contained'>
            <Cart src={cartSrc} />
            Agregar al carrito
          </Button>
        </ButtonContainer>
      </DataBox>
    </ItemContent>
  )
}

Data.propTypes = {
  picture: propTypes.string,
  pictures: propTypes.array,
  description: propTypes.string,
  price: propTypes.number,
  title: propTypes.string,
  AddToCart: propTypes.func,
  onQuantityChange: propTypes.func,
  quantity: propTypes.oneOfType([propTypes.number, propTypes.string]),
  setQuantity: propTypes.func,
  presentation: any
}

const ColorBox = styled(Grid)`
  width: 80px;
  width: 100%;
  margin-bottom: .0em;
  height: auto;
  img {
    width: 100%;
    cursor: pointer;
    border: ${props => props.$seleted ? '2px solid #000' : ''};
  }
`

const Title = styled.div`
  font-size: 2.5rem;
  font-family: var(--front-font);
  color: var(--front-red);
  font-weight: bold;
  @media screen and (max-width:1600px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width:1300px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width:1100px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  @media screen and (max-width:380) {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
`
const Price = styled.div`
  font-size: 2rem;
  color: var(--front-red-dark-2);
  font-family: sans-serif;
  font-weight: bold;
  @media screen and (max-width:1600px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width:1300px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 1.2rem;
  }
`

const Size = styled.div`
  font-family: sans-serif;
  font-size: 1.6rem;
  color: var(--front-red-dark-2);
  @media screen and (max-width:1600px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width:1300px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width:1100px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width:959px) {
    font-size: 1.1rem;
  }
`
const QuiantityText = styled.div`
  margin-right: 10px;
   @media screen and (max-width:1100px) {
    font-size: .8rem;
    margin-right: 10px;
  }
`
const Descripcion = styled.div`
  color: var(--front-red-dark-2);
  font-size: 1.1rem;
  font-family: sans-serif;
  font-weight: normal;
  margin-top: 10px;
  @media screen and (max-width:1600px) {
    font-size: 1rem;
  }
  @media screen and (max-width:1300px) {
    font-size: .9rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`

const Button = styled(ButtonBase)`
  font-size: 1.2em;
  padding: 15px;
  border-radius: 5px;
  color: ${props => props.variant === 'contained' ? '#fff' : 'var(--front-red)'};
  border: ${props => props.variant === 'contained' ? '' : '1px solid #cdcdcd'};
  background: ${props => props.variant === 'contained' ? 'var(--front-red)' : 'none'};
  @media screen and (max-width:1600px) {
    font-size: 1.1rem;
    padding: 10px;
  }
  @media screen and (max-width:1600px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-width:1000px) {
    font-size: .9rem;
    padding: 5px;
  }
`
const Cart = styled.img`
  width: 30px;
  margin-right: 10px;
`
const ItemContent = styled.div`
  border-radius: 5px;
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width:1600px) {
    margin: auto;
    padding: 0px;
  }
`
const PictureBox = styled.div`
  padding: 10px;
  width: 50%;
  @media screen and (max-width:959px) {
    width: 100%;
    padding: 0px;
  }
`
const DataBox = styled.div`
  padding: 10px;
  width: 45%;
  @media screen and (max-width:1600px) {
    width: 48%;
  }
  @media screen and (max-width:959px) {
    width: 100%;
    padding: 20px;
    padding: 0px;
    margin-top: 20px;
  }
`
const Boxes = styled.div`
  background: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  color: var(--front-red);
  font-size: 1.4rem;
  font-family: var(--front-font);
  margin-bottom: 25px;
  font-weight: bold;
  @media screen and (max-width:1600px) {
    padding: 17px;
    font-size: 1.3rem;
  }
  @media screen and (max-width:1300px) {
    padding: 10px;
    font-size: 1.2rem;
  }
  @media screen and (max-width:959px) {
    padding: 10px;
    font-size: 1rem;
  }
  
`
const Cuantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 10px 0px;
  font-family: sans-serif;
  color: gray;
  font-weight: normal;
`

export default Data
