import React from 'react'
import styled from 'styled-components'
import { Container as ContainerBase } from './main'
import fillSrc from '../../assets/fill_cover_secondary.png'
import wheatSrc from '../../assets/wheat.png'
import { string, oneOfType, object, node, element, array, bool } from 'prop-types'

const Fill = styled.div`
  background-image: ${props => props.$disableFill ? 'none' : 'url(' + fillSrc + ')'};
`
const Container = styled(ContainerBase)`
  padding: 50px 0px;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
`

const Picture = styled.img`
  width: 100px;
  margin-bottom: 20px;
  @media screen and (max-width:400px) {
    width: 70px;
  }
`
const HeaderText = styled.div`
  color: gray;
  margin-bottom: 10px;
  font-size: 1.1rem;
  @media screen and (max-width:700px) {
    font-size: 1rem;
  }
  @media screen and (max-width:400px) {
    font-size: .7rem;
  }
`
const Footer = styled.div`
  color: gray;
  margin-bottom: 10px;
  font-size: 1.4rem;
  @media screen and (max-width:700px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width:400px) {
    font-size: .7rem;
  }
`
const ContentText = styled.div`
  font-family: var(--front-font);
  color: var(--front-red-dark);
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 800px;
  @media screen and (max-width:1400px) {
    font-size: 2.5rem;
    max-width: 600px;
  }
  @media screen and (max-width:700px) {
    font-size: 1.6rem;
    max-width: 400px;
  }
`

const CoverSecondary = props => {
  return (
    <Fill $disableFill={props.$disableFill}>
      <Container>
        <Content>
          <Picture src={wheatSrc} />
          {props.header && (
            <HeaderText>
              {props.header}
            </HeaderText>
          )}
          <ContentText>
            {props.content}
          </ContentText>
          {props.footer && (
            <Footer>
              {props.footer}
            </Footer>
          )}
          {props.controls && (
            <Footer>
              {props.controls}
            </Footer>
          )}
        </Content>
      </Container>
    </Fill>
  )
}

CoverSecondary.propTypes = {
  header: string,
  content: string,
  footer: string,
  controls: oneOfType([string, array, object, node, element]),
  $disableFill: bool
}

export default CoverSecondary
