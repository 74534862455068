import React, { useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout'
import { Container as ContainerBase } from '../../components/main'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import about1 from '../../../assets/about1.PNG'
import about2 from '../../../assets/about2.png'
import fill from '../../../assets/fill_cover_secondary.png'
import mision from '../../../assets/mision.png'
import vision from '../../../assets/vision.png'
import values from '../../../assets/values.png'

const Paragraph = styled.p`
  color: var(--front-red-dark);
  font-size: 1.5em;
`
const Picture = styled.img`
  border-radius: 30px;
  display: block;
  margin: auto;
`
const Subtitle = styled.div`
  font-size: 3.3em;
  line-height: 1em;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  @media screen and (max-width:1600px) {
    font-size: 3.1em;
  }
  @media screen and (max-width:1250) {
    font-size: 2.8em;
  }
  @media screen and (max-width:960px) {
    font-size: 2.2em;
  }
`
const Limiter = styled.div`
  width: 90%;
  @media screen and (max-width:1250px) {
    width: 100%;
  }
`

const SubtitleSecondary = styled(Subtitle)`
  font-size: 2.7em;
  line-height: 1em;
  color: var(--front-red);
  @media screen and (max-width:1600px) {
    font-size: 2.5em;
  }
  @media screen and (max-width:1250) {
    font-size: 2.3em;
  }
  @media screen and (max-width:960px) {
    font-size: 2em;
  }
`

const Container = styled(ContainerBase)`
  @media screen and (max-width:1600px) {
    font-size: .9em;
  }
  @media screen and (max-width:1250px) {
    font-size: .7em;
  }
`

const Card = styled.div`
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const FullWidthSecond = styled(Box)`
  background-image: url(${fill});
  text-align: center;
`
const PictureSecondary = styled.img`
  display:  block;
  width: 100%;
`

const About = () => {
  const isMedium = useMediaQuery('(max-width:960px)')

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <Layout>
      <Cover />
      <CoverSecondary content='NUESTRA HISTORIA' />
      <Box pt={7} pb={7}>
        <Container style={{ overflow: 'hidden' }}>
          <Grid container spacing={5} justify='space-between' alignItems='center' direction={isMedium ? 'column-reverse' : 'row'}>
            <Grid item xs={12} md={6}>
              <Paragraph>
                En 1991 nace en Tijuana (al noroeste de México) el conglomerado que ha brindado apoyo y solución al mercado de la panadería y pastelería: Grupo Santa Cruz.
              </Paragraph>
              <Paragraph>
                La pasión, la necesidad de resolver y el compromiso para con la industria, fueron motivo para comenzar con este viaje que hasta hoy, sigue. Y fuerte.
              </Paragraph>
              <Paragraph>
                A lo largo de estos años, nuestra responsabilidad ha sido latente en ofrecer en cada rincón del país, las mejores marcas a nivel global, el servicio y entrega puntual.
              </Paragraph>
              <Paragraph>
                Este gran viaje nos motiva a ser competitivos y ofrecer las mejores herramientas para hacer crecer negocios, para trabajar en equipo con nuestros socios comerciales y al final, ver todos esos logros llevados de la mano, uno a uno.
              </Paragraph>
            </Grid>
            <Grid item xs={12} md={6}>
              <Picture src={about1} />
            </Grid>
          </Grid>
          <Box mt={isMedium ? 0 : 5}>
            <Grid container spacing={5} justify='space-between' alignItems='center'>
              <Grid item xs={12} md={6}>
                <Picture src={about2} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Limiter>
                  <Subtitle> Más de 30 años de presencia en el mercado </Subtitle>
                  <Paragraph style={{ color: '#000' }}>
                    Somos una empresa 100% dedicada a fortalecer su negocio y ofrecerle los mejores productos e insumos para la repostería y pastelería.
                  </Paragraph>
                  <Paragraph style={{ color: '#000' }}>
                    Reunimos lo mejor para ponerlo a su disposición en nuestras más de 10 sucursales por todo el país.
                  </Paragraph>
                  <Subtitle>
                    Nuestro objetivo
                  </Subtitle>
                  <Paragraph style={{ color: '#000' }}>
                    Brindar un servicio de calidad a precios competitivos, siendo un apoyo en el crecimiento de nuestros clientes.
                  </Paragraph>
                </Limiter>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <FullWidthSecond pt={7} pb={7}>
        <Container style={{ overflow: 'hidden' }}>
          <Box mb={5}>
            <SubtitleSecondary>Nuestra Filosofía</SubtitleSecondary>
            <Paragraph style={{ margin: '0px' }}>Excelencia en servicio y calidad de nuestros servicios</Paragraph>
          </Box>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={12} md={4}>
              <Card>
                <Box p={3}>
                  <SubtitleSecondary>Misión</SubtitleSecondary>
                  <Paragraph style={{ color: '#000' }}>Es nuestro compromiso contribuir al desarrollo y crecimiento de nuestros clientes y personal proporcionando productos de alta calidad, servicio oportuno y asesoría eficaz </Paragraph>
                </Box>
                <PictureSecondary src={mision} />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <Box p={3}>
                  <SubtitleSecondary>Visión</SubtitleSecondary>
                  <Paragraph style={{ color: '#000' }}>Convertirnos en un proveedor líder nacional con la línea más completa de productos y equipo en la industria de la panadería y pastelería </Paragraph>
                </Box>
                <PictureSecondary src={vision} />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <Box p={3}>
                  <SubtitleSecondary>Valores</SubtitleSecondary>
                  <Paragraph style={{ color: '#000' }}>
                    Honestidad <br />
                    Respeto <br />
                    Lealtad <br />
                    Responsabilidad <br />
                    Servicio <br />
                  </Paragraph>
                </Box>
                <PictureSecondary src={values} />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </FullWidthSecond>
    </Layout>
  )
}

export default About
