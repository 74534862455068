/* eslint-disable no-unused-vars */
import { CircularProgress } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'
import api from '../../../api'
import { db } from '.././../../modules/firebase'
import snapshoparser from '../../../helpers/snapshotparser'
import { setInitialState } from '../../../flux/cart'
import { useDispatch, useSelector } from 'react-redux'
import checkoutData from '../../../helpers/checkoutdata'
import renderHtml from '../../../templateEmail'

const FullwWidth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const Verify = props => {
  var { path } = useParams()
  const location = useLocation()
  const { id } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const history = useHistory()
  const dispatch = useDispatch()
  const itemsOncart = useSelector(state => state.cart)

  path = window.atob(path)

  const fetcher = async () => {
    const data = await api.payouts.get(id)
    if (data.status === 'failed') {
      // error operations
      await db.doc(path).delete()
      history.push('/checkout', data)
    } else {
      // success operations
      var documentatdb = await db.doc(path).get()
      documentatdb = snapshoparser(documentatdb)

      const dataInputs = checkoutData.get()

      const template = renderHtml(itemsOncart, { ...dataInputs, total: data.amount, orderId: data.authorization })
      api.email.send({
        email: dataInputs.email,
        subject: 'Confirmación de compra',
        html: template
      })

      dispatch(setInitialState())
      checkoutData.delete()
      history.push('/success', { ...data, ...documentatdb, user: { ...documentatdb.user, ...documentatdb.shipTo } })
    }
  }

  useEffect(() => {
    fetcher()
  }, [])

  return (
    <FullwWidth>
      <CircularProgress />
    </FullwWidth>
  )
}

export default Verify
