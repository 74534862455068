import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const MenuContent = styled.div`
  /* background: red; */
  width: 25%;
  margin-right: 15px;
  @media (max-width: 1279) {
    width: 30%;
  }
`
export const Body = styled.div`
  width: 100%;
`
export const Title = styled.h1`
  color: var(--front-red);
  margin: 0px;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: var(--front-font);
  margin-bottom: 10px;
  @media screen and (max-width:1500px) {
    font-size: 2rem;
  }
  @media screen and (max-width:1200px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 1.5rem;
    max-width: 80%;
    margin: 10px auto;
  }
`
