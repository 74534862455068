import React from 'react'
import styled from 'styled-components'
import { ButtonBase, Grid, Box } from '@material-ui/core'
import { categories, subFamilies, categoriesWithSubFamilies } from '../../../constants'
import { string, func } from 'prop-types'
import filterObject from '../../../helpers/filterObject'

const Content = styled(Grid)`

`
const Button = styled(ButtonBase)`
  font-weight: bold;
  padding: 10px 8px;
  background: ${props => props.$selected ? 'var(--front-gray)' : 'var(--front-rose)'};
  width: 100%;
  box-sizing: border-box;
  color: var(--front-red-light);
  border-radius: 10px;
  height: 100%;
  @media (max-width:500px) {
    font-size: .7rem;
  }
`

const GridTitle = styled(Grid)`
  margin-top: 15px;
  margin-bottom: -7px;
  color: var(--front-red-dark);
  font-weight: bold;
`

const MenuMobile = props => {
  const currentSubFamilies = props.currentCategory ? Object.keys(filterObject(subFamilies, categoriesWithSubFamilies[props.currentCategory])) : []

  return (
    <Box>
      <Content container spacing={1}>
        <GridTitle item xs={12}>
          Categoría
        </GridTitle>
        <Grid item xs={3}>
          <Button $selected={!props.currentCategory} onClick={event => props.setCurrentCategory(null)}>
            Todos
          </Button>
        </Grid>
        {Object.keys(categories).map(item => (
          <Grid key={item} item xs={3}>
            <Button $selected={item === props.currentCategory} onClick={event => props.setCurrentCategory(item)}>
              {categories[item]}
            </Button>
          </Grid>
        ))}
      </Content>
      {!!props.currentCategory && (
        <Content container spacing={1}>
          <GridTitle item xs={12}>
            {/* Subfamilia */}<br />
          </GridTitle>
          {currentSubFamilies.map(item => (
            <Grid key={item} item xs={3}>
              <Button $selected={item === props.currentSubFamily} onClick={event => props.setCurrentSubFamily(item)}>
                {subFamilies[item]}
              </Button>
            </Grid>
          ))}
        </Content>
      )}
    </Box>
  )
}

MenuMobile.propTypes = {
  currentCategory: string,
  setCurrentCategory: func,
  setCurrentSubFamily: func,
  currentSubFamily: string
}

export default MenuMobile
