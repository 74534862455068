import React from 'react'
import styled from 'styled-components'
import { string, oneOfType, number } from 'prop-types'
import Picture from '../../components/picture'
import { ButtonBase, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Button = styled(ButtonBase)`
  font-weight: bold;
  padding: 20px 30px;
  border-radius: 10px;
  color: gray;
  background: var(--front-red);
  color: #fff;
  margin-top: 20px;
  font-size: 1.3em;
  @media screen and (max-width:1600px) {
    font-size: 1.2em;
    padding: 15px 20px;
  }
  @media screen and (max-width:1000px) {
    font-size: 1em;
    padding: 10px 15px;
    margin-top: 8px;
  }
  @media screen and (max-width:500px) {
    font-size: .6em;
  }
`
const DataContainer = styled.div`
  position: absolute;
  bottom: -30px;
  text-align: center;
  width: 90%;
  margin: auto;
  @media screen and (max-width:1000px) {
    bottom: -20px;
  }
  @media screen and (max-width:500px) {
    bottom: -15px;
  }
`
const Time = styled.div`
  font-size: 1.5em;
  color: #fff;
  margin-top: 8px;
  @media screen and (max-width:1600px) {
    font-size: 1.3em;
  }
  @media screen and (max-width:1000px) {
    font-size: 1em;
    margin-top: 0px;
  }
  @media screen and (max-width:500px) {
    font-size: .6em;
  }
`
const Content = styled(Link)`
  position: relative;
  border-radius: 10px;
  height: 100%;
  min-height: 150px;
  border: 1px solid var(--front-gray);
  text-decoration: none;
  display: flex;
  justify-content: center;
`
const Title = styled.h6`
  color: #fff;
  line-height: 1em;
  font-size: 2.5em;
  font-family: var(--front-font);
  margin: 0px;
  @media screen and (max-width:1600px) {
    font-size: 2.2em;
  }
  @media screen and (max-width:1000px) {
    font-size: 1.5em;
  }
  @media screen and (max-width:500px) {
    font-size: .9em;
  }
`

const Wrapper = styled.div`
  padding-bottom: 30px;
  @media screen and (max-width:500px) {
    padding-bottom: 20px;
  }
  img {
    border-radius: 10px;
  }
`

const Article = props => {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Wrapper>
      <Content to={`/receta/${props.id}`}>
        <Picture height={isMobile ? 125 : 115} src={props.picture} />
        <DataContainer>
          <Title>{props.title}</Title>
          <Time>Tiempo de preparación {props.time}</Time>
          <Button>
            Ver receta
          </Button>
        </DataContainer>
      </Content>
    </Wrapper>
  )
}

Article.propTypes = {
  title: string,
  picture: string,
  id: string,
  time: oneOfType([string, number])
}

export default Article
