import styled from 'styled-components'
import { RadioGroup, Radio as RadioBase } from '@material-ui/core'

export const Content = styled(RadioGroup)`
  background: #fff;
`

export const Radio = styled(RadioBase)`
  margin-right: 10px;
  margin-left: 20px;
  @media screen and (max-width:700px) {
    margin-left: 10px;
  }
`

export const Option = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f1f1f1;
  min-height: 70px;
  border-radius: 10px;
  margin-bottom: 10px;
`

export const Icon = styled.img`
  margin-right: 10px;
  padding: 3px;
  background: #cdcdcd4d;
  font-size: 30px;
  border-radius: 50%;
  color: #008ffd;
`

export const Text = styled('div')`
  font-size: 1.3em;
  margin-right: 10px;
`
export const Alert = styled.div`
  margin-bottom: 15px;
`
export const Row = styled.div`
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`
export const ButtonContent = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Picture = styled.img`
  display: block;
  height: 30px;
  @media screen and (max-width:600px) {
    height: 20;
  }
`
export const Container = styled.div`
  margin-right: 40px;
  background: #fff;
  border-radius: 5px;
  padding: 60px 10px;
  @media screen and (max-width:600px) {
    padding: 0px;
  }
  @media screen and (max-width:1600px) {
    margin-right: 30px;
  }
  @media screen and (max-width:1000px) {
    margin-right: 20px;
  }
  @media screen and (max-width:800px) {
    margin-right: 0px;
  }
`
