import React from 'react'
import styled from 'styled-components'
import wheat from '../../../assets/wheat.png'
import { MailOutline, Phone } from '@material-ui/icons'
import { string } from 'prop-types'

const Body = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  @media screen and (max-width:1400px) {
    padding: 20px;
  }
`
const Icon = styled.img`
  display: block;
  margin: auto;
  width: 80px;
  margin-bottom: 15px;
`
const Title = styled.div`
  font-size: 2.3em;
  text-align: center;
  color: var(--front-red);
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: var(--front-font);
  font-weight: bold;
`
const Text = styled.div`
  font-size: 1.3em;
  text-align: center;
  margin-top: 20px;
  @media screen and (max-width:1550px) {
    font-size: 1.1em;
  }
  svg {
    color: var(--front-red);
    font-size: 30px;
  }
  span {
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
`
const Card = props => {
  return (
    <Body>
      <Icon src={wheat} />
      <Title>{props.Title}</Title>
      <Text>{props.address}</Text>
      <Text>
        <Phone />
        <div>Teléfono: {props.tel}</div>
        <div>MATRIZ LADA SIN COSTO: <span>664-902-0177</span></div>
      </Text>
      <Text>
        <MailOutline />
        <div>{props.email}</div>
      </Text>
    </Body>
  )
}

Card.propTypes = {
  Title: string,
  address: string,
  email: string,
  tel: string
}

export default Card
