import React from 'react'
import styled from 'styled-components'
import coverImage from '../../../assets/cover_home.png'
import Header from '../../components/header'

const Content = styled.section`
  padding: 1px;
  background-image: url(${coverImage});
  background-size: cover;
  background-position: center;
  height: 1300px;
  @media screen and (max-width:1700px) { height: 1100px; }
  @media screen and (max-width:1400px) { height: 1000px; }
  @media screen and (max-width:1000px) { height: 800px; }
  @media screen and (max-width:700px) { height: 700px; }
  @media screen and (max-width:500px) { height: 600px; }
  @media screen and (max-width:400px) { height: 500px; }
`
const Title = styled.h1`
  text-shadow: 0px 2px 0px #fff;
  font-weight: bold;
  color: #c97510!important;
  text-align: center;
  font-size: 9rem;
  font-family: var(--front-font);
  margin: 0px;
  @media screen and (max-width:1600px) {
    font-size: 7rem;
  }
  @media screen and (max-width:1200px) {
    font-size: 6rem;
  }
  @media screen and (max-width:700px) {
    font-size: 5rem;
  }
  @media screen and (max-width:500px) {
    font-size: 4rem;
  }
  @media screen and (max-width:400px) {
    font-size: 3rem;
  }
`
const SubTitle = styled.div`
  font-size: 1.8rem;
  text-align: center;
  margin-top: -40px;
  color: var(--front-red-dark);
  font-weight: bold;
  @media screen and (max-width:1600px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width:1200px) {
    font-size: 1.2rem;
    margin-top: -30px;
  }
  @media screen and (max-width:500px) {
    font-size: 1rem;
    margin-top: -25px;
  }
  @media screen and (max-width:400px) {
    font-size: .75rem;
    margin-top: -15px;
  }
`
const Box = styled.div`
  text-shadow: 0px 1px 0px #fff;
  padding: 180px 0px;
  @media screen and (max-width:1600px) {
    padding: 110px 0px;
  }
  @media screen and (max-width:1200px) {
    padding: 80px 0px;
  }
  @media screen and (max-width:900px) {
    padding: 50px 0px;
  }
`

const Cover = props => {
  return (
    <Content>
      <Header />
      <Box>
        <Title>
          Tradición
        </Title>
        <SubTitle>
          Somos una empresa con historia y tradición
        </SubTitle>
      </Box>
    </Content>
  )
}

export default Cover
