/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Container as ContainerBase } from '../../components/main'
import fillSrc from '../../../assets/wheat_cover.png'
import Header from '../../components/header'

const Fill = styled.div`
  background-image: url(${fillSrc});
  background-position: center;
  background-size: cover;
`
const Container = styled(ContainerBase)`
  padding: 50px 0px;
  margin-top: 80px;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ContentText = styled.div`
  font-family: var(--front-font);
  color: #fff;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  @media screen and (max-width:1400px) {
    font-size: 2.5rem;
    max-width: 600px;
  }
  @media screen and (max-width:700px) {
    font-size: 1.6rem;
    max-width: 400px;
  }
`

const CoverSecondary = props => {
  return (
    <Fill>
      <Header />
      <Container>
        <Content>
          <ContentText>
            {props.title || 'CON AMOR Y DEDICACIÓN'}
          </ContentText>
        </Content>
      </Container>
    </Fill>
  )
}

export default CoverSecondary
