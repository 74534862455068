// import React from 'react'
import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'

export const Container = styled.div`
  padding: ${props => props.$padding || '15px'};
  width: 80%;
  margin: auto;
  @media (max-width:600px) {
    width: 100%;
  }
`

export const Button = styled(ButtonBase)`
  text-decoration: none!important;
  background-color: ${props => props.$primary ? 'var(--front-red)' : '#fff'};
  padding: 20px 30px;
  font-size: 1.3em;
  color: ${props => !props.$primary ? '#e900337a' : '#fff'};
  border-radius: 5px;
`
