import currency from '../helpers/currency'

const products = productsList => {
  return productsList.map(item => `
      <tbody>
      <tr>
        <td width="70 px" height="70 px"
          style="text-align:center;padding-bottom:10px;padding-top:15px;padding-left:10px;vertical-align:middle">
          <img width="70 px" height="70 px" style="text-align:center;padding-left:15px"
            src="${item.picture}"
            alt="Imagen Producto" class="CToWUd">
        </td>
        <td
          style="text-align:left;padding-bottom:10px;padding-left:15px;color:#103562;font-weight:bold;vertical-align:middle">
          ${item.title}</td>
        <td width="50px"
          style="text-align:right;padding-bottom:10px;color:#103562;font-weight:bold;vertical-align:middle">
          ${item.quantity}x</td>
        <td
          style="text-align:center;padding-bottom:10px;padding-right:15px;padding-left:10px;color:#103562;font-weight:bold;vertical-align:middle">
          ${currency.formatMoney(item.price)}</td>
      </tr>
    </tbody>
  `).join(' ')
}

export default products
