import React from 'react'
import { oneOfType, string, element, array, object } from 'prop-types'
import MyTheme from '../../theme'
import Footer from '../footer'

const Layout = props => {
  return (
    <MyTheme>
      <div style={{ backgroundColor: '#fff' }}>
        {props.children}
        <Footer />
      </div>
    </MyTheme>
  )
}

Layout.propTypes = {
  children: oneOfType([string, element, array, object])
}

export default Layout
