const checkoutData = {
  save: data => {
    try {
      return window.localStorage.setItem('checkouttempdata', JSON.stringify(data))
    } catch (error) {
      console.log('error')
    }
  },
  get: () => {
    try {
      let val = window.localStorage.getItem('checkouttempdata')
      val = JSON.parse(val)
      return val || null
    } catch (error) {
      console.log('error', error)
    }
  },
  delete: () => {
    try {
      window.localStorage.removeItem('checkouttempdata')
    } catch (error) {
      console.log(error)
    }
  }
}

export default checkoutData
