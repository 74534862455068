/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Content, ActionsContent, ButtonContent } from './styled'
import Layout from '../../components/layout'
import { Container } from '../../components/main'
import Summary from './components/summary'
import Shipping from './components/shiping'
import MethodPay from './components/method_pay'
import InfoCard from './components/credit_card_form'
import Confirm from './components/confirm'
import Cover from '../my_cart/cover'
import { number, string, arrayOf, func } from 'prop-types'
import styled from 'styled-components'

const ContainerStyled = styled(Container)`
  max-width: 1200px;
  @media screen and (max-width:900px) {
    max-width: 100%!important;
    width: 100%!important;
  }
`

const View = props => {
  return (
    <Layout>
      <Cover />
      <ContainerStyled>
        {props.currentStep < 2 && (
          <Content>
            <ActionsContent>
              {props.currentStep === 0 && (
                <Shipping {...props} />
              )}
              {props.currentStep === 1 && (
                <MethodPay {...props} />
              )}
            </ActionsContent>
            <Summary {...props} />
          </Content>
        )}
        {props.currentStep === 2 && (
          <InfoCard {...props} />
        )}
      </ContainerStyled>
    </Layout>
  )
}

View.propTypes = {
  currentStep: number,
  steps: arrayOf(string),
  goToStep: func,
  handleNext: func
}

export default View
