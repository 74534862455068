import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from './main'
import { Grid, TextField, InputAdornment, Hidden, useMediaQuery } from '@material-ui/core'
import { Search, ArrowForwardIos } from '@material-ui/icons'
import { array, func, string } from 'prop-types'
import { useLocation } from 'react-router-dom'

const TextFieldStyled = styled(TextField)`
  background: #fff;
  border-radius: 10px;
  width: 33%;
  & fieldset {
    border: none;
  }
  @media screen and (max-width:959px) {
    width: 100%;
  }
`
const Body = styled.div`
  background: var(--front-rose);
  min-height: 70px;
`
const StepContent = styled.div`
  text-transform: uppercase;
  color: ${props => props.$current ? 'var(--front-red)' : 'gray'};
  font-weight: ${props => props.$current ? 'bold' : 'normal'};
`

const Step = styled.div`
  display: flex;
  align-items: center;
  & svg {
    color: gray;
    font-size: 1rem;
    margin: 0px 15px;
  }
`
const ContainerFixed = styled.div`
  position: ${props => props.$fixed ? 'fixed' : 'static'};
  width: ${props => props.$width}px;
  top: 125px;
  background-color: var(--front-rose);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  @media screen and (max-width:600px) {
    top: 90px;
  }
  z-index: 10;
`
const BackDrop = styled.div`
  width: 100%;
  right: 0px;
  height: 180px;
  background: #fff;
  position: fixed;
  z-index: 1;
  top: 0px;
  @media screen and (max-width:600px) {
    height: 140px;
  }
`

const Searcher = props => {
  const isSm = useMediaQuery('(max-width:959px)')
  const [isFixed, setFixed] = useState(false)
  const [width, setWidth] = useState('100%')
  const { state = {} } = useLocation()

  // handleScroll
  useEffect(() => {
    const handleScroll = event => {
      if (window.scrollY >= document.getElementById('parentSearcher').offsetTop - 140 && !isFixed) {
        setFixed(true)
      } else if (window.scrollY < document.getElementById('parentSearcher').offsetTop - 140 && isFixed) {
        setFixed(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFixed])

  // setWidthFixed
  useEffect(() => {
    const handleResise = event => {
      const parentWidth = document.getElementById('parentSearcher').offsetWidth
      setWidth(parentWidth)
    }
    handleResise()
    window.addEventListener('resize', handleResise)
    return () => window.removeEventListener('resize', handleResise)
  }, [])

  // update state keyworks
  useEffect(() => {
    if (state.keywords) {
      props.setKeywords(state.keywords)
      document.getElementById('searcher').focus()
    }
  }, [])

  return (
    <Body>
      <Container $padding={isFixed ? '0px 15px' : '15px'}>
        <div id='parentSearcher'>
          {isFixed && (
            <BackDrop />
          )}
          <ContainerFixed $fixed={isFixed} $width={width}>
            <Grid container spacing={2} justify='space-between' alignItems='center'>
              <Hidden smDown>
                <Grid item xs={6} container>
                  {props.steps.map((step, index) => (
                    <Step key={index}>
                      {!!index && (
                        <ArrowForwardIos />
                      )}
                      <StepContent $current={index === props.steps.length - 1}>
                        {step}
                      </StepContent>
                    </Step>
                  ))}
                </Grid>
              </Hidden>
              <TextFieldStyled
                id='searcher'
                value={props.keywords}
                onChange={event => props.setKeywords(event.target.value)}
                size={isSm ? 'small' : 'medium'}
                variant='outlined'
                focused
                placeholder='Buscar'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Search style={{ color: 'var(--front-red)' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </ContainerFixed>
        </div>
      </Container>
    </Body>
  )
}

Searcher.propTypes = {
  steps: array,
  setKeywords: func,
  keywords: string
}

export default Searcher
