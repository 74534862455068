import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toArray } from '../../../helpers/transformer'
import { fetchAll } from '../../../flux/recipes'
import paginate from '../../../helpers/paginate'
import View from './view'
import lastPosition from '../../../helpers/last_position'

const Home = props => {
  const [currentCategory, setCurrentCategory] = useState('sweets')
  const [renderedItems, setItemsRendered] = useState([])

  const dispatch = useDispatch()
  const { items, loading, limit } = useSelector(state => state.recipes)
  const arrayOfItems = toArray(items).filter(item => item.isActive)
  let itemsFiltered = arrayOfItems
  if (currentCategory) itemsFiltered = itemsFiltered.filter(item => item.category === currentCategory)

  useEffect(() => {
    if (arrayOfItems.length < limit) {
      dispatch(fetchAll())
    }
  }, [])

  // paginate result
  const next = useMemo(() => { return paginate(itemsFiltered, 10) }, [currentCategory, loading])
  useEffect(() => {
    setItemsRendered(next())
  }, [currentCategory, loading])

  // pagination with scrolling
  useEffect(event => {
    const handleScroll = event => {
      const isInLastPosition = lastPosition(100)
      if (!loading && isInLastPosition) {
        setItemsRendered([...renderedItems, ...next()])
      }
    }
    window.addEventListener('scroll', handleScroll)
    return event => window.removeEventListener('scroll', handleScroll)
  }, [currentCategory, loading, renderedItems])

  return (
    <View
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      items={itemsFiltered}
      itemsPaginated={renderedItems}
      loading={loading}
    />
  )
}

export default Home
