import React from 'react'
import styled from 'styled-components'
import { Section, Title as TitleBase } from './main'
import { Container } from '../../components/main'
import ImageBack from '../../../assets/fill_cover_secondary.png'
import { Grid, Box, ButtonBase, Hidden } from '@material-ui/core'
import team1 from '../../../assets/team1.png'
import team2 from '../../../assets/team2.png'
import team3 from '../../../assets/team3.png'
import { Link } from 'react-router-dom'

const Background = styled.div`
  background-image: url(${ImageBack});
`

const Picture = styled.img`
  width: 100%;
  @media screen and (max-width:959px) {
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
`

const Title = styled(TitleBase)`
  font-size: 3.2em;
  line-height: 1.2em;
  @media screen and (max-width:959px) {
    text-align: center;
  }
  @media screen and (max-width:959px) {
    font-size: 2.8em;
  }
`
const Text = styled.div`
  font-size: 1.5em;
  color: var(--front-red-dark);
  @media screen and (max-width:959px) {
    text-align: center;
  }
`
const Button = styled(ButtonBase)`
  background: rgb(249, 178, 0);
  padding: 15px;
  margin-top: 25px;
  color: var(--front-red-dark)!important;
  font-size:1.2em;
  border-radius: 5px;
  @media screen and (max-width:1650px) {
    padding: 11px;
    margin-top: 18px;
  }
  @media screen and (max-width:959px) {
    margin: auto;
    margin-top: 16px;
    display: block;
  }
  a {
    color: var(--front-red-dark)!important;
  }
`
const Limiter = styled.div`
  width: 85%;
  margin: auto;
  @media screen and (max-width:1280px) {
    width: 95%;
  }
`

const Team = () => {
  return (
    <Background>
      <Container>
        <Hidden smDown>
          <Limiter>
            <Section>
              <Grid container spacing={2}>
                <Grid item xs md={7}>
                  <Picture src={team1} />
                </Grid>
                <Grid item xs md={5}>
                  <Box p={{ lg: 4, xl: 8 }}>
                    <Title>Nuestra fuerza </Title>
                    <Title>Es nuestra gente trabajadora</Title>
                    <Box mt={3}>
                      <Text>
                        Así como nuestros clientes, proveedores y socios comerciales
                      </Text>
                    </Box>
                    <Button>
                      <Link to='nosotros'>
                        Nuestra Historia
                      </Link>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs md={5}>
                  <Picture style={{ marginLeft: '12%', width: '88%' }} src={team2} />
                </Grid>
                <Grid item xs md={7}>
                  <Picture style={{ width: '95%' }} src={team3} />
                </Grid>
              </Grid>
            </Section>
          </Limiter>
        </Hidden>

        <Hidden mdUp>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Title>Nuestra fuerza Es nuestra gente trabajadora</Title>
                <Box mt={3}>
                  <Text>
                    Así como nuestros clientes, proveedores y socios comerciales
                  </Text>
                </Box>
                <Box mb={3}>
                  <Button>Nuestra Historia</Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Picture src={team1} />
              </Grid>
              <Grid item xs={4}>
                <Picture src={team2} />
              </Grid>
              <Grid item xs={8}>
                <Picture src={team3} />
              </Grid>
            </Grid>
          </Section>
        </Hidden>
      </Container>
    </Background>
  )
}

export default Team
