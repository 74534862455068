import React from 'react'
import styled from 'styled-components'
import { Container as ContainerBase } from '../../components/main'
import fillSrc from '../../../assets/wheat_cover.png'
import wheatSrc from '../../../assets/wheat.png'
import Header from '../../components/header'

const Fill = styled.div`
  background-image: url(${fillSrc});
  background-position: center;
  background-size: cover;
`
const Container = styled(ContainerBase)`
  padding: 50px 0px;
  margin-top: 80px;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Picture = styled.img`
  width: 100px;
  margin-bottom: 20px;
  @media screen and (max-width:400px) {
    width: 70px;
  }
`
const HeaderText = styled.div`
  color: var(--front-red-dark);
  margin-bottom: 10px;
  font-size: 1.1rem;
  @media screen and (max-width:700px) {
    font-size: 1rem;
  }
  @media screen and (max-width:400px) {
    font-size: .7rem;
  }
`
const ContentText = styled.div`
  font-family: var(--front-font);
  color: var(--front-red-dark);
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  @media screen and (max-width:1400px) {
    font-size: 2.5rem;
    max-width: 600px;
  }
  @media screen and (max-width:700px) {
    font-size: 1.6rem;
    max-width: 400px;
  }
`

const CoverSecondary = props => {
  return (
    <Fill>
      <Header />
      <Container>
        <Content>
          <Picture src={wheatSrc} />
          <HeaderText>
            CARRITO
          </HeaderText>
          <ContentText>
            RESUMEN DE TU PEDIDO
          </ContentText>
        </Content>
      </Container>
    </Fill>
  )
}

export default CoverSecondary
