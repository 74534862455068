import React from 'react'
import styled from 'styled-components'
import { Box, Hidden } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import propTypes from 'prop-types'
import Picture from '../../components/picture'
import { Button } from '../../components/main'
import { Link } from 'react-router-dom'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const PictureViewer = props => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const handleThumbSelect = (evenet) => {
    console.log(evenet.target.id)
    setActiveStep(parseInt(evenet.target.id))
  }

  return (
    <>
      <Box mb={2}>
        <Hidden smDown>
          <Link to='/recetas'>
            <Button>Regresar</Button>
          </Link>
        </Hidden>
      </Box>
      <PicturesContainer>
        <AutoPlaySwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
          {props.pictures.map(step => (
            <Picture height={80} key={step} src={step} alt={step} style={{ borderRadius: '10px' }} />
          ))}
        </AutoPlaySwipeableViews>
      </PicturesContainer>

      <ThumbList>
        {props.pictures.map((picture, index) => (
          <Thumb
            onClick={handleThumbSelect}
            id={index}
            src={picture}
            key={picture}
            selected={props.pictures[activeStep] === picture}
            height={80}
          />
        ))}
      </ThumbList>

    </>
  )
}

PictureViewer.propTypes = {
  pictures: propTypes.array
}
const Thumb = styled(Picture)`
  border-radius: 5px;
  width: 13%;
  margin-right: 7px;
  border: ${props => props.selected === true ? '3px solid #E91E63' : 'none'};
`

const ThumbList = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`

const PicturesContainer = styled('div')`
  position: relative;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
`

export default PictureViewer
