import styled from 'styled-components'
import InputBase from '../../../../../components/inputs/GroupInput'

export const Content = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 40px 10px;
  @media screen and (max-width:600px) {
    padding: 0px;
  }
`
export const Box = styled.div`
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  margin: auto;
`
export const Input = styled(InputBase)`
  background: #fff;
  margin-bottom: 15px;
  margin-right: ${props => props.marginRight || '0px'};
`
export const Leggend = styled.div`
  font-size: 1em;
  font-style: italic;
  color: gray;
`
export const BoxSeparator = styled.div`
  background: #f1f1f1;
  padding:  20px 40px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;
  @media screen and (max-width:1600px) {
    padding: 20px 30px;
  }
  @media screen and (max-width:1200px) {
    padding: 18px;
  }
`
