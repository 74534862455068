import React, { useEffect } from 'react'
import Layout from '../../components/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSpecificItem } from '../../../flux/recipes'
import { Container } from '../../components/main'
import Data from './data'
import Skeleton from './skeleton'
import styled from 'styled-components'
import Related from './related'
import Cover from './cover'
import fill from '../../../assets/fill_cover_secondary.png'

const FullWidthPattern = styled.div`
  background-image: url(${fill});
`

const Recipe = props => {
  const { id } = useParams()
  const dispatch = useDispatch()
  var { items, loading } = useSelector(state => state.recipes)
  const itemSelected = items[id] || null

  console.log('itemSelected', itemSelected, items, id)

  // fetch data
  useEffect(() => {
    if (!itemSelected) dispatch(fetchSpecificItem(id))
  }, [])

  // scroll and focus content
  useEffect(() => {
    setTimeout(() => {
      try {
        const distance = document.getElementById('recipeSelected').offsetTop
        window.scrollTo(0, distance - 160)
      } catch (error) {
        console.log('error')
      }
    }, 0)
  }, [loading])

  return (
    <Layout>
      <Cover
        title={itemSelected ? itemSelected.title : ''}
      />
      <FullWidthPattern>
        {loading && (
          <Container>
            <Skeleton />
          </Container>
        )}
        {!loading && (
          <Container id='recipeSelected'>
            <Data {...itemSelected} />
          </Container>
        )}
      </FullWidthPattern>

      <Related category={itemSelected ? itemSelected.category : null} />

    </Layout>
  )
}

export default Recipe
