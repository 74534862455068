/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import View from './view'
import useObjectState from '../../../hooks/useObjectState'
import orders from '../../../modules/orders'
import { useSelector } from 'react-redux'
import { setInitialState } from '../../../flux/cart'
import sumPrice from '../../../helpers/sumPrice'
import { activeLoading, hideLoading } from '../../../flux/loading'
import { dispatch } from '../../../store'
import { useHistory, useLocation } from 'react-router-dom'
import validateForm from '../../../helpers/validateform'
import { merchantId, taxPorcent } from '../../../config'
import api from '../../../api'
import checkoutData from '../../../helpers/checkoutdata'
import AlertShipping from '../../../components/AlertShipping'
import renderHtml from '../../../templateEmail'
import useShippingCost from '../../../hooks/useShippingCost'

const { validateCardNumber, validateCVC, validateExpiry } = window.OpenPay.card

// important
// successData = orderId created when pay is width spei or deposit
// metadadata = successInfo when pay is with card or paypal

const initialData = process.env.NODE_ENV === 'production' ? {} : {
  email: 'armando@gmail.com',
  name: 'armando de jesus',
  lastname: 'santiz lopez',
  street_number: 'lazaro cardenas numero 7',
  suburb: 'cenrto',
  city: 'san cristobal de las casas',
  state: 'chiapas',
  postal_code: '29247',
  number: '8959841651',
  State: 'chiapas'
}

const Checkout = props => {
  const history = useHistory()
  const location = useLocation()
  const itemsOncart = useSelector(state => state.cart)
  const [data, setData] = useObjectState(initialData)
  const [currentStep, setCurrenStep] = useState(0)
  const [input, setInputError] = useObjectState({ errors: [], message: null })
  const [errorShipping, setErrorShipping] = useState(false)

  const calculatorShipping = useShippingCost()
  const isShippingFree = !!calculatorShipping(data.postal_code) && (sumPrice(itemsOncart) + Math.ceil(sumPrice(itemsOncart) * (taxPorcent / 100))) > 1000

  const shipping = isShippingFree ? 0 : calculatorShipping(data.postal_code)

  const steps = ['Datos generales', 'Pagar', 'tarjeta']
  const subTotal = sumPrice(itemsOncart)
  const tax = Math.ceil((subTotal + shipping) * (taxPorcent / 100))
  const totalPrice = subTotal + shipping + tax

  // verify wen have error only credit card
  useEffect(() => {
    if (location.state) {
      const errorMesage = 'Tarjeta rechazada'
      setCurrenStep(1)
      setInputError({ message: errorMesage })
      const data = checkoutData.get()
      if (data) setData(data)
    }
  }, [])

  const onAnyInputChange = event => {
    setData({ [event.target.name]: event.target.value })
  }

  const goToStep = step => {
    setCurrenStep(step)
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  const successOperation = async ({ orderId, id, successData = {}, metadata = {} }) => {
    sendMail(orderId)
    await orders.success({ id, metadata })
    await new Promise(resolve => setTimeout(resolve, 3000))
    endProcess()
    dispatch(setInitialState())
    history.push('/success', { totalPrice, subTotal, tax, items: itemsOncart, user: data, ...successData, methodPay: data.methodPay, orderId })
  }

  const failedOperation = async id => {
    await orders.failed(id)
    endProcess()
    setCurrenStep(1)
  }

  const saveOperation = async () => {
    startProcess()
    const result = await orders.create({
      ...data,
      subTotal,
      shipping,
      totalPrice,
      tax,
      itemsOncart
    })
    return result
  }

  const handleNext = _event => {
    window.scrollTo(0, 0)
    if (currentStep === 0) {
      const requires = ['email', 'name', 'lastname', 'street_number', 'suburb', 'city', 'state', 'number', 'postal_code']
      const requiresForFact = ['fact_name', 'fact_lastname', 'fact_rfc', 'fact_rs', 'fact_adress', 'fact_email', 'fact_tel', 'fact_type', 'cfdi']
      const requiresCalculatedByWillFact = data.willFact ? [...requires, ...requiresForFact] : requires
      const inputWidthErrors = validateForm.requires(data, requiresCalculatedByWillFact)

      if (inputWidthErrors) setInputError({ errors: inputWidthErrors, message: 'Todos los campos son requeridos' })
      else if (!validateForm.validateEmail(data.email)) {
        setInputError({ errors: ['email'], message: 'El correo no es válido' })
      } else if (data.name.toString().length < 4) {
        setInputError({ errors: ['name'], message: 'El nombre no es válido' })
      } else if (data.number.toString().length < 6) {
        setInputError({ errors: ['number'], message: 'El número de contacto no es válido' })
      } else if (!shipping && !isShippingFree) {
        setErrorShipping(true)
      } else {
        setCurrenStep(currentStep + 1)
      }
    }
    if (currentStep === 1) {
      setCurrenStep(currentStep + 1)
    }
  }

  // remove error on input focus
  const onAnyInputFocus = event => {
    const newErrors = input.errors.filter(error => (event.target.name || event.target.id) !== error)
    const NewErrorMessage = newErrors.length ? input.message : null
    setInputError({
      errors: newErrors,
      message: NewErrorMessage
    })
  }

  // handle pay
  const handlePay = event => {
    if (data.methodPay === 'card') return payWidthCard()
    if (data.methodPay === 'cash') return payWithStore()
    if (data.methodPay === 'spei') return payWithSpei()
    setInputError({ ...input, message: 'Selecciona tu método de pago' })
  }

  // send email
  const sendMail = async (id) => {
    try {
      const data_ = {
        streetNNumber: data.street_number,
        col: data.suburb,
        city: data.city,
        state: data.state,
        zip: data.postal_code,
        birthdate: data.date,
        usermail: data.email,
        ulastname: data.lastname,
        ufirstname: data.name,
        order_id: id.toString(),
        products: itemsOncart.map(item => ({
          url: item.picture,
          name: item.title,
          price: item.price.toString(),
          qty: item.quantity
        }))
      }
      console.log(data_)
      // await functions.httpsCallable('sendRecipeMail')(data_)
    } catch (error) {
      console.error('_error_', error)
    }
  }

  const payWidthCard = () => {
    const deviceSessionId = window.OpenPay.deviceData.setup()
    checkoutData.save({ ...data, shipping })
    startProcess()
    window.OpenPay.token.create({
      card_number: data.card_number,
      holder_name: data.card_name,
      expiration_year: data.card_year,
      expiration_month: data.card_month,
      cvv2: data.card_cvv
    },
    async response => { // on success token
      var { id: idCreated, path } = await saveOperation()
      try {
        const token = response.data.id
        var payStatus = await api.payouts.card({
          path,
          iva: tax,
          subtotal: subTotal.toString(),
          method: 'card',
          deviceId: deviceSessionId,
          description: 'checkout',
          token,
          name: data.card_name,
          phone: data.number,
          mail: data.email,
          amount: totalPrice.toString(),
          redirect_url: `${window.location.origin}/verify/${window.btoa(path)}`
        })
        if (payStatus.status === 'error') {
          setInputError({ message: 'Tarjeta rechazada' })
          failedOperation(idCreated)
        } else {
          window.location.href = payStatus.charge.payment_method.url
        }
      } catch (error) {
        setInputError({ message: 'Tarjeta rechazada' })
        failedOperation(idCreated)
      }
    },
    error => {
      console.log('error pay width card', error)
      if (error.data && error.data.error_code) {
        setInputError({ message: 'Tarjeta rechazada' })
      } else {
        setInputError({ message: 'Tarjeta rechazada' })
        setCurrenStep(1)
      }
      endProcess()
    })
  }

  const payWithStore = async () => {
    var { id: idCreated, path, order_id } = await saveOperation()
    try {
      const response = await api.payouts.store({
        path,
        pId: idCreated,
        iva: tax,
        subtotal: subTotal.toString(),
        method: 'store',
        deviceId: window.OpenPay.deviceData.setup(),
        description: 'Pago de productos santa cruz',
        name: `${data.name} ${data.lastname}`,
        phone: data.number,
        mail: data.email,
        amount: totalPrice.toString()
      })
      if (response.status === 'success') {
        const template = renderHtml(itemsOncart, { ...data, total: totalPrice, orderId: order_id, shipping }, null, response.charge.payment_method)
        api.email.send({
          email: data.email,
          subject: 'Confirmación de compra',
          html: template,
          urlPdf: `https://dashboard.openpay.mx/paynet-pdf/${merchantId}/${response.charge.payment_method.reference}`
        })
        return successOperation({ id: idCreated, successData: response.charge, metadata: {}, orderId: order_id })
      }
      failedOperation(idCreated)
      setInputError({ message: 'Error, intenta con un método diferente' })
    } catch (error) {
      failedOperation(idCreated)
      setInputError({ message: 'Error, intenta con un método diferente' })
    }
  }

  const payWithSpei = async () => {
    var { id: idCreated, path, order_id } = await saveOperation()
    try {
      const response = await api.payouts.spei({
        path,
        pId: idCreated,
        iva: tax,
        subtotal: subTotal.toString(),
        method: 'bank_account',
        deviceId: window.OpenPay.deviceData.setup(),
        description: 'checkout',
        name: `${data.name} ${data.lastname}`,
        phone: data.number,
        mail: data.email,
        amount: totalPrice.toString()
      })
      if (response.status === 'success') {
        const template = renderHtml(itemsOncart, { ...data, total: totalPrice, orderId: order_id, shipping }, response.charge.payment_method)
        api.email.send({
          email: data.email,
          subject: 'Confirmación de compra',
          html: template,
          urlPdf: `https://dashboard.openpay.mx/spei-pdf/${merchantId}/${response.charge.id}`
        })
        return successOperation({ id: idCreated, successData: response.charge, metadata: {}, orderId: order_id })
      }
      failedOperation(idCreated)
      setInputError({ message: 'Error, intenta con un método diferente' })
    } catch (error) {
      failedOperation(idCreated)
      setInputError({ message: 'Error, intenta con un método diferente' })
    }
  }

  // validate form card and  afther process
  const handlePayWidthcard = () => {
    const inputWidthErrors = validateForm.requires(data, ['card_cvv', 'card_year', 'card_month', 'card_number', 'card_name'])
    if (inputWidthErrors) {
      return setInputError({
        errors: inputWidthErrors,
        message: 'Todos los campos son requeridos'
      })
    }
    if (!validateCardNumber(data.card_number)) {
      return setInputError({
        errors: ['card_number'],
        message: 'El número de la tarjeta no es válido'
      })
    }
    if (!validateCVC(data.card_cvv)) {
      return setInputError({
        errors: ['card_cvv'],
        message: 'El código de seguridad no es válido'
      })
    }
    if (!validateExpiry(data.card_month, data.card_year)) {
      return setInputError({
        errors: ['card_month', 'card_year'],
        message: 'La fecha de vencimiento no es válida'
      })
    }
    handlePay()
  }

  const startProcess = () => dispatch(activeLoading('Estamos procesando tu pago, porfavor espere un momento...'))
  const endProcess = () => dispatch(hideLoading())

  return (
    <>
      <AlertShipping open={errorShipping} onClose={event => setErrorShipping(false)} />
      <View
        isShippingFree={isShippingFree}
        onPayWidthCard={handlePayWidthcard}
        onFocus={onAnyInputFocus}
        startProcess={startProcess}
        endProcess={endProcess}
        data={data}
        state={data}
        onChange={onAnyInputChange}
        saveOperation={saveOperation}
        shipping={shipping}
        totalPrice={totalPrice}
        subTotal={subTotal}
        currentStep={currentStep}
        goToStep={goToStep}
        steps={steps}
        errors={input.errors}
        setErrors={setInputError}
        handleNext={handleNext}
        errorMessage={input.message}
        itemsOncart={itemsOncart}
        handlePay={handlePay}
        successOperation={successOperation}
        failedOperation={failedOperation}
        tax={tax}
      />
    </>
  )
}

export default Checkout
