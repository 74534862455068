/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react'
import { Container } from '../../components/main'
import Layout from '../../components/layout'
import { Wrapper, MenuContent, Body, Title } from './styled'
import Skeleton from './skeleton'
import { bool, array, string } from 'prop-types'
import { Grid, Hidden, useMediaQuery, Box } from '@material-ui/core'
import Article from '../../components/item'
import SideBar from './sidebar'
import MenuMobile from './categoryMobile'
import { categories, subFamilies } from '../../../constants'
import Searcher from '../../components/searcher'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import Empty from '../../components/empty'

const View = props => {
  const isMobile = useMediaQuery('(max-width:600px)')
  let steps = ['productos']
  if (props.currentCategory) steps = [...steps, categories[props.currentCategory], subFamilies[props.currentSubFamily] || 'TODOS']

  const emptyMessage = !props.currentCategory && !props.keywords ? 'Aún no hay productos en la tienda'
    : props.keywords
      ? 'No encontramos resultados de busqueda'
      : 'No encontramos resultados'

  // scroll and focus content
  useEffect(() => {
    setTimeout(() => {
      try {
        const distance = document.getElementById('products').offsetTop
        window.scrollTo(0, distance - 150)
      } catch (error) {
        console.log('error')
      }
    }, 0)
  }, [props.currentCategory, props.keywords, props.currentSubFamily])

  return (
    <Layout>
      <Cover />
      <CoverSecondary
        header='PRODUCTOS'
        content='¡TE AYUDAMOS A HACER CRECER TU NEGOCIO!'
      />
      <Searcher
        {...props}
        steps={steps}
      />
      <Box paddingTop={{ xs: 3, md: 5 }} />
      <Container id='products'>
        {props.loading && (<Skeleton />)}
        {!props.loading && (
          <Wrapper>
            <Hidden smDown>
              <MenuContent>
                <SideBar items={props.items} {...props} />
              </MenuContent>
            </Hidden>
            <Body>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Hidden mdUp>
                  <Grid item xs={12}>
                    <MenuMobile {...props} />
                  </Grid>
                </Hidden>
                <Grid item xs={12}>
                  {props.currentSubFamily
                    ? (<Title>{subFamilies[props.currentSubFamily]}</Title>)
                    : (<Title>Todos los productos {props.currentCategory && `de ${categories[props.currentCategory]}`} </Title>)
                  }
                </Grid>
                {!!props.itemsPaginated.length && props.itemsPaginated.map(item => (
                  <Grid key={item.id} item xs={6} lg={4}>
                    <Article {...item} />
                  </Grid>
                ))}
                {!props.itemsPaginated.length && (
                  <Empty
                    minHeight='50vh'
                    message={emptyMessage}
                  />
                )}
              </Grid>
            </Body>
          </Wrapper>
        )}
      </Container>
    </Layout>
  )
}

View.propTypes = {
  loading: bool,
  items: array,
  currentCategory: string,
  itemsPaginated: array,
  keywords: string,
  currentSubFamily: string
}

export default View
