import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout'
import { Container } from '../../components/main'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import validateForm from '../../../helpers/validateform'
import useObjectState from '../../../hooks/useObjectState'
import Form from './form'
import message from '../../../modules/message'
import { CircularProgress, Grid, Paper, Box, useMediaQuery } from '@material-ui/core'

const Contact = () => {
  const [currentView, setCurrentView] = useState('form') // form || loading || sucess
  const [data, setData] = useObjectState({})
  const [errors, setErrors] = useState({ errors: [], message: null })

  const isMd = useMediaQuery('(max-width:1280px)')

  const handleChange = ({ name, value }) => {
    setData({ [name]: value })
  }

  const handleSend = async event => {
    const inputsWithErrors = validateForm.requires(data, ['name', 'email', 'number', 'content'])
    if (inputsWithErrors) setErrors({ errors: inputsWithErrors, message: 'Todos los campos son requeridos' })
    else if (!validateForm.validateEmail(data.email)) {
      setErrors({ errors: ['email'], message: 'El correo no es válido' })
    } else {
      setCurrentView('loading')
      await message.add(data)
      setCurrentView('success')
    }
  }

  const handleRemoveErrors = params => {
    const NewErrors = errors.errors.filter(error => error !== params.name)
    setErrors({
      errors: NewErrors,
      message: NewErrors.length > 0 ? errors.message : null
    })
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Cover />
      <CoverSecondary $disableFill />
      <Container>
        <Grid container spacing={isMd ? 3 : 10}>
          <Grid item xs={12} md={6}>
            <Primary>
              Distribuciones Santa Cruz es una compañía que crece gracias a su gente y a
              cada uno de sus colaboradores y socios.
            </Primary>
            <Secondary>
              Siempre estamos en busca del mejor talento, llena nuestro formulario para conocerte y pornernos en contacto contigo.
            </Secondary>
            <Secondary>
              También puedes enviarnos tu C.V. a
              <span>
                trabajos@distribucionessantacruz.com
              </span>
            </Secondary>
          </Grid>
          <Grid item xs={12} md={6}>
            {currentView === 'form' && (
              <Form
                data={data}
                handleChange={handleChange}
                handleSend={handleSend}
                errors={errors.errors}
                errorMessage={errors.message}
                handleRemoveErrors={handleRemoveErrors}
              />
            )}
            {currentView === 'loading' && (
              <Grid container justify='center' alignItems='center' style={{ minHeight: '100px', marginBottom: '50px' }}>
                <CircularProgress />
              </Grid>
            )}
            {currentView === 'success' && (
              <Grid container justify='center' alignItems='center' style={{ minHeight: '100px', marginBottom: '50px' }}>
                <Paper elevation={3}>
                  <Box p={5}>
                    <ContentText>
                      Listo, hemos enviado tu mensaje
                    </ContentText>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
const ContentText = styled.div`
  font-family: var(--front-font);
  color: var(--front-red);
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  @media screen and (max-width:1400px) {
    font-size: 2.5rem;
    max-width: 600px;
  }
  @media screen and (max-width:700px) {
    font-size: 1.6rem;
    max-width: 400px;
    text-align: center;
  }
`

const Primary = styled.div`
  font-size: 2.5em;
  line-height: 1.1em;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  margin-bottom: 30px;
  @media screen and (max-width:1600px) {
    font-size: 2em;
  }
  @media screen and (max-width:1200px) {
    font-size: 1.7em;
  }
  @media screen and (max-width:700px) {
    font-size: 1.3em;
    text-align: center;
  }
`
const Secondary = styled.div`
  color: var(--front-red-dark);
  font-size: 1.6em;
  margin-bottom: 30px;
  span {
    display: block;
    font-weight: bold;
    font-family: var(--front-font);
  }
  @media screen and (max-width:1600px) {
    font-size: 1.4em;
  }
  @media screen and (max-width:1200px) {
    font-size: 1.2em;
  }
  @media screen and (max-width:700px) {
    font-size: 1em;
    text-align: center;
  }
`
export default Contact
