/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { categories, subFamilies, categoriesWithSubFamilies } from '../../../constants'
import { ArrowDropDown } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import filterObject from '../../../helpers/filterObject'

const Content = styled.aside`
  position: sticky;
  top: 220px;
`
const CategoryStyled = styled.div`
  border-radius: 10px;
  background: var(--front-gray);
  padding: 15px;
  margin-bottom: 15px;
  color: var(--front-red);
  text-transform: uppercase;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    color: var(--front-red);
    font-weight: bold;
  }
`

const List = styled.div`
  margin-left: 10px;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  max-height: 300px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
const Title = styled(Link)`
  text-decoration: none;
  display: block;
  color: #ff5c6b;
  text-transform: uppercase;
  font-size: .9rem;
  margin-bottom: 5px;
  :hover {
    color: var(--front-red);
    font-weight: bold;
  }
`

const SubFamilyRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  color: #e90033;
  background: #ebe6da;
  margin-bottom: 10px;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    color: var(--front-red);
    font-weight: bold;
  }
`

const ListSubfamilies = styled.div`
  max-height: 400px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ProductsList = props => {
  return (
    <>
      {props.currentCategory === props.name && (
        <List>
          {props.items.map(item => (
            <Title to={`/producto/${item.id}`} key={item.id}>{item.title}</Title>
          ))}
        </List>
      )}
    </>
  )
}

const Category = props => {
  const subFamiliesOnThisCategory = categoriesWithSubFamilies[props.name] ? filterObject(subFamilies, categoriesWithSubFamilies[props.name]) : {}

  return (
    <>
      <CategoryStyled onClick={event => props.setCurrentCategory(props.name === 'all' ? null : props.name)}>
        <div>
          {props.name === 'all' ? 'Todos' : categories[props.name]}
        </div>
        <ArrowDropDown />
      </CategoryStyled>
      {props.currentCategory === props.name && (
        <ListSubfamilies>
          {Object.keys(subFamiliesOnThisCategory).map(currentSubFamily => (
            <Fragment key={currentSubFamily}>
              <SubFamilyRow onClick={event => props.setCurrentSubFamily(currentSubFamily)}>
                {subFamilies[currentSubFamily]}
                <ArrowDropDown />
              </SubFamilyRow>
              {props.currentSubFamily === currentSubFamily && (
                <ProductsList
                  items={props.items.filter(item => item.subFamily === currentSubFamily)}
                />
              )}
            </Fragment>
          ))}
        </ListSubfamilies>
      )}
    </>
  )
}

const Sidebar = props => {
  return (
    <Content>
      <Category {...props} name='all' items={[]} />
      {Object.keys(categories).map(cat => (
        <Category
          {...props}
          key={cat}
          name={cat}
          items={props.items.filter(item => item.category === cat)}
        />
      ))}
    </Content>
  )
}

export default Sidebar
