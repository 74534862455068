import { db, firebase } from './firebase'
import snapshotParser from '../helpers/snapshotparser'

export const getSingle = async (cp) => {
  try {
    let data = await db.doc('/config/shippingCost').get()
    data = snapshotParser(data)
    data = parseInt(data[cp].cost)
    return data
  } catch (error) {
    return 0
  }
}

export const getList = async () => {
  try {
    let data = await db.doc('config/shippingCost').get()
    data = snapshotParser(data)
    delete data._id
    console.log('data', data)
    return Object.keys(data).map(keyname => data[keyname]) || []
  } catch (error) {
    console.log('error at list shipping cost', error)
    return []
  }
}

export const deleteOne = async (id) => {
  await db.doc('config/shippingCost').update({
    [id]: firebase.firestore.FieldValue.delete()
  })
}

export const add = async data => {
  try {
    await db.doc('config/shippingCost').update({ [data.cp]: data })
  } catch (error) {
    await db.doc('config/shippingCost').set({ [data.cp]: data })
  }
}
