import { Box, Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import LayoutUser from '../../../front/components/layout'
import { Container } from '../../components/main'
import Cover from '../about/cover'

const PaperStyled = styled(Paper)`
  max-width: 1000px;
  margin: auto;
  p {
    margin-bottom: 1.5em!important;
  }
  @media screen and (max-width:1600px) {
    font-size: .9em;
  }
  @media screen and (max-width:1000px) {
    font-size: .8em;
  }
  @media screen and (max-width:700px) {
    font-size: .7em;
  }
`

const NoticeOfPrivacy = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutUser>
      <Cover title='TÉRMINOS Y CONDICIONES' />
      <Container>
        <Box p={{ xs: 2, md: 3, lg: 4 }}>
          <PaperStyled elevation={3}>
            <Box p={{ xs: 2, md: 3, lg: 4 }}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
          </PaperStyled>
        </Box>
      </Container>
    </LayoutUser>
  )
}

export default NoticeOfPrivacy

const html = `
  <div>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Relación
    Contractual</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Las
  presentes condiciones (las “</SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I>Condiciones</I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">”),
  rigen el acceso y el uso que usted haga -desde cualquier parte del
  mundo- a los sitios web, redes sociales y/o servicios digitales (los
  “</SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I>Servicios</I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">”)
  desarrollados, manejados y/o administrados por </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><B>Distribuciones
  Santa Cruz, S.A. de C.V.</B></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  (y/o sus controladoras, filiales o subsidiarias) (“</SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I>DSC</I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">”).</SPAN></FONT></FONT></P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Si
  usted no está de acuerdo con cualquiera de las disposiciones
  establecidas en las presentes Condiciones, deberá abstenerse de
  utilizar los Servicios y deberá abandonar, desinstalar o dar de baja
  inmediatamente cualquier plataforma o cuenta que le permita acceder a
  los mismos.</SPAN></FONT></FONT></P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  se reserva el derecho de cambiar, modificar y/o reemplazar las
  presentes Condiciones en cualquier momento y sin necesidad de previo
  aviso. Las nuevas Condiciones entrarán en vigor al día siguiente a
  aquél en que hayan sido modificadas o reemplazadas. El uso de los
  Servicios después de la publicación correspondiente implica su
  consentimiento a vincularse y obligarse conforme a las Condiciones y
  sus respectivas modificaciones.</SPAN></FONT></FONT></P
  <div START=2>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><B>Condiciones
    de uso de los Servicios</B></SPAN></FONT></FONT></P>
  </div>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Para
  poder utilizar los Servicios, usted debe de contar con edad y
  capacidad suficiente -de conformidad con la legislación que le
  resulte aplicable- a efecto de estar en condiciones de obligarse
  conforme a las presentes Condiciones.</SPAN></FONT></FONT></P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Usted
  es responsable de adquirir y actualizar el hardware compatible o los
  dispositivos necesarios para acceder y utilizar los Servicios. DSC no
  garantiza que los Servicios o cualquier parte de estos funcionen en
  cualquier hardware o dispositivo en particular. Los Servicios pueden
  ser objeto de disfunciones o retrasos inherentes al uso de datos,
  incompatibilidades informáticas y la interacción con otras
  comunicaciones electrónicas.</SPAN></FONT></FONT></P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Usted
  reconoce que la contratación de bienes y/o servicios por medios
  electrónicos o digitales requiere la utilización de su firma
  electrónica y que la misma es vinculante en los términos del Código
  de Comercio aplicable para los Estados Unidos Mexicanos. Cualquier
  aceptación o confirmación que usted haga en relación con los
  Servicios (ya sea como usuario registrado o como visitante)
  representan su firma electrónica y, consecuentemente, su
  consentimiento expreso o tácito a la operación correspondiente,
  según corresponda. Si usted sospecha o por cualquier motivo
  considera que se está haciendo mal uso de su firma electrónica o
  que existe una suplantación de su identidad, deberá hacerlo del
  conocimiento inmediato de DSC a efecto de que DSC tome las medidas de
  seguridad correspondientes. De lo contrario, toda la actividad
  asociada a su firma electrónica será considerada como plenamente
  vinculante. </SPAN></FONT></FONT>
  </P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  se reserva el derecho de negar los Servicios a cualquier persona que
  incumpla los términos establecidos en estas Condiciones o que
  incurra en prácticas desleales, abusivas, ilícitas o fraudulentas.</SPAN></FONT></FONT></P
  <div START=3>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Alcance
    de los Servicios</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">A
  través de los Servicios, DSC facilita una plataforma de comercio
  electrónico en la que:</SPAN></FONT></FONT></P>
  <P LANG="es-MX" ALIGN=JUSTIFY STYLE="margin-left: 1in; margin-bottom: 0in">
  <BR>
  </P>
  <div>
    <div TYPE=a>
      <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Se
      comercializan alimentos, insumos, ingredientes, materiales,
      equipos, máquinas, piezas y, en general, bienes relacionados con
      la repostería, la pastelería y la cocina en general. Esta
      comercialización incluye tanto bienes propios como bienes de
      terceros; y</SPAN></FONT></FONT></P>
    </div>
  </div>
  <P LANG="es-MX" ALIGN=JUSTIFY STYLE="margin-left: 0.31in; text-indent: -0.31in; margin-bottom: 0in">
  <BR>
  </P>
  <div>
    <div TYPE=a START=2>
      <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Se
      proporcionan servicios de atención, apoyo técnico y asistencia al
      cliente en materia culinaria y de negocios.</SPAN></FONT></FONT></P>
    </div>
  </div
  <div START=4>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Entregas</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Como
  regla general, el plazo de entrega será el que se indica en la
  confirmación de compra que se emita al cliente. Si la confirmación
  de compra no indica un plazo de entrega, la entrega deberá
  verificarse dentro de los cinco días siguientes a la confirmación
  de la compra. DSC realizará las entregas en días y horas hábiles,
  de lunes a viernes, en un horario de 8:00 a 4:00 p.m. En días
  feriados o inhábiles, la entrega se hará hasta el día hábil
  siguiente.</SPAN></FONT></FONT></P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  hace del conocimiento de sus clientes que algunos productos son
  enviados, entregados o distribuidos directamente por un tercero. En
  estos casos, DSC lo hará del conocimiento del cliente y las
  entregas, garantías y responsabilidades por el producto deberá
  entenderse directamente entre el cliente y dichos terceros.</SPAN></FONT></FONT></P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">La
  entrega se entenderá con la persona que atienda el pedido en el
  domicilio de entrega señalado en la compra. DSC podrá (pero no
  estará obligado a) confirmar la identidad de la persona que reciba
  la entrega. Es responsabilidad del cliente asegurarse que su timbre,
  interfón, teléfono o cualquier punto de contacto o acceso al
  domicilio de entrega esté habilitado, funcione y/o que haya una
  persona encargada de recibir el pedido. Cualquier pedido que no sea
  recibido o que no sea posible entregar en el domicilio señalado para
  la entrega, se cobrará al cliente y éste podrá recogerlo
  directamente en las instalaciones de DSC, en las instalaciones del
  proveedor de logística utilizado para las entregas o en las
  instalaciones del productor, según corresponda. En alimentos o
  bienes perecederos, DSC no asume ningún tipo de responsabilidad
  después del segundo día en que el producto no sea recolectado por
  el cliente. En equipos y otros productos, el cliente contará con un
  plazo de cinco días hábiles después de que no se pudo realizar la
  entrega para hacer la recolección. </SPAN></FONT></FONT>
  </P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  podrá realizar las entregas de forma personal o a través de
  terceros, en cuyo caso los términos y condiciones de dichos terceros
  aplicarán de forma previa a lo dispuesto en estas Condiciones.</SPAN></FONT></FONT></P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">La
  política de entrega podrá modificarse, suspenderse o cancelarse en
  situaciones de caso fortuito o fuerza mayor, lo cual incluye -pero no
  se limita a- temblores, terremotos, inundaciones (y desastres
  naturales en general), contingencias sanitarias, situaciones
  violentas o de extremo peligro, manifestaciones, huelgas, cortes
  generalizados de agua, gas o electricidad (o cualquier suspensión o
  interrupción de servicios o de infraestructura), desabasto
  generalizado de insumos y, en general, cualquier situación
  generalizada </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(i)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  que no haya sido previsible, </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(ii)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  que no sea atribuible a DSC y </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(iii)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  que impida o dificulte el cumplimiento de las obligaciones por parte
  de DSC.</SPAN></FONT></FONT></P>
 
  <div START=5>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Devoluciones
    y Cancelaciones</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Los
  alimentos y los bienes perecederos sólo podrán ser rechazados o
  devueltos en caso de que el pedido sea incorrecto o se presente en
  mal estado, en cuyo caso,  será responsabilidad del cliente
  acreditar estas circunstancias de forma inmediata e incontrovertible
  a DSC a través de fotos o videos tomados de forma inmediata a la
  entrega. </SPAN></FONT></FONT>
  </P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  informa a sus clientes que a través de los Servicios se
  comercializan bienes y productos propios y de terceros. Las
  garantías, devoluciones o cancelaciones respecto de los productos de
  terceros se regirán por las políticas que para tal efecto éstos
  tengan vigentes. DSC solo asume responsabilidad por defectos de
  fábrica o por vicios ocultos en los productos que comercializa.
  Salvo que expresamente se indique algo diferente en la confirmación
  de compra, el plazo máximo para solicitar la devolución de un
  equipo será de tres días, contados a partir de la fecha efectiva de
  entrega. En todo caso, los equipos deben ser devueltos en el mismo
  estado en el que fueron recibidos, sin alteraciones, daños o
  indicios de intervenciones por personal distinto a DSC o al
  fabricante correspondiente. El reembolso de un equipo y su
  acreditación pueden variar en función de las condiciones en que el
  equipo sea devuelto, el tiempo que se tuvo posesión del mismo y la
  forma en que fue comprado. </SPAN></FONT></FONT>
  </P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Salvo
  que usted acepte un método de reembolso diferente, el método
  predeterminado de reembolso será vía reembolso o abono al método
  de pago que haya utilizado para realizar la compra. En caso de ser
  procedente, el reembolso se realizará dentro de los quince días
  siguientes a la fecha en que se haya realizado la devolución.</SPAN></FONT></FONT></P>
 
  <div START=6>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Atención
    al Cliente</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  pone a disposición de sus clientes o usuarios los siguientes medios
  de contacto:</SPAN></FONT></FONT></P>
 
  

        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em">Correo
        electrónico:</FONT></FONT></P>

        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em">atencionenlinea@distribucionessantacruz.com</FONT></FONT></P>

        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em">Teléfono:</FONT></FONT></P>
      
        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em">664-902-0177</FONT></FONT></P>

        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em">Domicilio:</FONT></FONT></P>
     
        <P STYLE="border: none; padding: 0in; widows: 0; orphans: 0"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">CALLE
        ALBA #4355 int: 18. Tijuana, Baja California. C.P. 22115</SPAN></FONT></FONT></P>


 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  es una sociedad mercantil constituida de conformidad con la
  legislación mexicana, tal y como consta en la escritura número
  2,177 de fecha 21/02/1997, pasada ante la fe del Lic. Carlos E.
  Ahumada Arruti,  titular de la notaría pública número 13 de
  Tijuana, Baja California.</SPAN></FONT></FONT></P>
 
  <div START=7>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Conflictos
    o Controversias</B></FONT></FONT></P>
  </div>
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Usted
  y DSC se comprometen a hacer sus mejores esfuerzos para resolver
  cualquier conflicto o controversia que llegare a presentarse de forma
  amigable y profesional, antes de acudir a otras instancias legales
  -para lo cual usted deberá enviar un correo electrónico o
  comunicarse a los medios de contacto proporcionados en estas
  Condiciones indicando la información de su pedido, su inconformidad
  con el mismo y la solución específica que usted propone para
  solucionarlo. </SPAN></FONT></FONT>
  </P>
 
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Cualquier
  conflicto insuperable en relación </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(i)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  con los Servicios, </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(ii)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  con los bienes o servicios comercializados por DSC o </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(iii)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  con las presentes Condiciones será sometida y resuelta -a su
  elección-, a través de la jurisdicción administrativa de la
  Procuraduría Federal del Consumidor o bien a través de un arbitraje
  obligatorio conducido por la Comisión de Mediación y Arbitraje de
  la Cámara Nacional de Comercio (&quot;CANACO&quot;) por lo que tanto
  usted como nosotros renunciamos a cualquier otra jurisdicción que
  pudiera corresponder por razón de domicilios o por cualquier otra
  causa.  </SPAN></FONT></FONT>
  </P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">En
  todo caso, la legislación aplicable será la de los Estados Unidos
  Mexicanos.</SPAN></FONT></FONT></P>
 
  <div START=8>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Actualizaciones</B></FONT></FONT></P>
  </div
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  podrá, en cualquier momento, realizar actualizaciones informáticas,
  mejoras y/o mantenimientos a los Servicios.</SPAN></FONT></FONT></P
  <div START=9>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Propiedad
    Industrial / Intelectual</B></FONT></FONT></P>
  </div
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  es y seguirá siendo titular de los derechos de propiedad industrial
  e intelectual relacionados con </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(i)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  la denominación Distribuciones Santa Cruz S.A. de C.V. y con
  cualquier signo distintivo, marca o denominación directa o
  indirectamente relacionados con la misma; así como con </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><I><B>(ii)</B></I></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
  los elementos que integran las plataformas a través de las cuales se
  prestan los Servicios, tales como software, código informático,
  interfaz visual y demás derechos relacionados.</SPAN></FONT></FONT></P
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">Usted
  se compromete a no distribuir, copiar, modificar, editar, adaptar,
  crear trabajos derivados o de otra manera hacer uso no autorizado de
  los Servicios sin autorización previa y por escrito de DSC y/o del
  titular de los derechos respectivos. De igual forma, usted deberá
  abstenerse de desarmar, descompilar, aplicar ingeniería inversa o de
  cualquier otro modo tratar de obtener acceso o comercializar el
  código fuente o los protocolos inherentes.</SPAN></FONT></FONT></P
  <div START=10>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Privacidad
    de la información</B></FONT></FONT></P>
  </div
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">DSC
  hace de su conocimiento que los datos personales que recaba en
  relación con los Servicios serán tratados de conformidad con lo
  dispuesto en el Aviso de Privacidad, disponible para su consulta aqui
  </SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX"><SPAN STYLE="background:"><a href='/aviso-de-privacidad'>LINK</a></SPAN></SPAN></FONT></FONT><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">.</SPAN></FONT></FONT></P>
  <div START=10>
    <div><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><B>Pagos</B></FONT></FONT></P>
  </div
  <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in"><FONT FACE="Economica, serif"><FONT SIZE=3 STYLE="font-size: 1.3em"><SPAN LANG="es-MX">
    Todas las transacciones con tarjetas serán procesadas por OpenPay
  </SPAN>
  </P>
 
`
