import { Box, Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import LayoutUser from '../../../front/components/layout'
import { Container } from '../../components/main'
import Cover from '../about/cover'

const PaperStyled = styled(Paper)`
  max-width: 1000px;
  margin: auto;
  p {
    margin-bottom: 1.5em!important;
  }
  @media screen and (max-width:1600px) {
    font-size: .9em;
  }
  @media screen and (max-width:1000px) {
    font-size: .8em;
  }
  @media screen and (max-width:700px) {
    font-size: .7em;
  }
`

const NoticeOfPrivacy = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutUser>
      <Cover title='AVISO DE PRIVACIDAD' />
      <Container>
        <Box p={{ xs: 2, md: 3, lg: 4 }}>
          <PaperStyled elevation={3}>
            <Box p={{ xs: 2, md: 3, lg: 4 }}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
          </PaperStyled>
        </Box>
      </Container>
    </LayoutUser>
  )
}

export default NoticeOfPrivacy

const html = `
  <P ALIGN=CENTER  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.4em"><B><SPAN STYLE="background: #ffffff">DISTRIBUCIONES
  SANTA CRUZ, S.A. DE C.V.</SPAN></B></FONT></FONT></FONT></P><br />
  <P ALIGN=CENTER  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Distribuciones
  Santa Cruz, S.A. de C.V. (“DSC”), como Responsable del
  tratamiento de los Datos Personales que recaba, pone a disposición
  del público en general, así como de sus clientes y usuarios (cada
  uno referido como “Titular”) el presente Aviso de Privacidad (el
  “Aviso”).</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Para
  estar en condiciones de prestar sus servicios, DSC requiere someter a
  tratamiento determinados datos personales, los cuales puede obtener:
  (i) personalmente del Titular, a través de formatos, llamadas,
  mensajes, correos y otras comunicaciones presenciales o electrónicas;
  (ii) a través de su sitio web
  (</SPAN></FONT></FONT></FONT><A HREF="https://distribucionessantacruz.com.mx/"><FONT COLOR="#0000ff"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><U><SPAN STYLE="background: #ffffff">https://mitiendasantacruz.com/</SPAN></U></FONT></FONT></FONT></A><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">)
  y/o a través de cualquier vínculo, micrositio o acceso directamente
  relacionado con el mismo (el “Sitio”); (iii) a través de las
  redes sociales administradas por DSC y (iv) a través de
  transferencias de terceros, siempre que hayan sido debidamente
  informadas y consentidas por los respectivos Titulares.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">DSC
  se reserva el derecho de modificar en cualquier momento el contenido
  del presente Aviso. Cualquier cambio en el Aviso le será informado
  al Titular a través de una notificación general en el Sitio. La
  modificación correspondiente entrará en vigor a partir del día
  siguiente de su publicación.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Identidad,
  Domicilio y Contacto</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">DSC
  es una sociedad mercantil debidamente constituida de conformidad con
  la legislación mexicana, tal y como consta en la escritura número
  2,177 de fecha 21/02/1997, pasada ante la fe del Lic. Carlos E.
  Ahumada Arruti, titular de la notaría pública número 13 de
  Tijuana, Baja California.</SPAN></FONT></FONT></FONT></P>
  
  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Su
  domicilio se encuentra ubicado en Calle Alba 4355-18; Las Brisas
  Norte; Centro Industrial Bustamante; Tijuana, B.C.; México.</SPAN></FONT></FONT></FONT></P>
  
  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Para
  todo lo relacionado con el presente Aviso y el ejercicio de los
  derechos que en él y en la Ley se confieren, DSC pone a disposición
  de los Titulares el siguiente correo electrónico
  atencionlegal@distribucionessantacruz.com</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Datos
  Personales Sujetos a Tratamiento</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
  datos del Titular que DSC someterá a tratamiento son los siguientes:</SPAN></FONT></FONT></FONT></P>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">De
    sus clientes (actuales o potenciales) y usuarios: nombre, teléfono,
    correo electrónico, domicilio, datos de facturación, perfil de
    usuario y hábitos o preferencias generales de compra o consumo. </SPAN></FONT></FONT></FONT>
    </P>
  </UL>
 
  <P ALIGN=JUSTIFY STYLE="margin-left:  line-height: 0.19in">
  <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">En
  relación con los datos bancarios, se informa a los Titulares que DSC
  podrá utilizar o contratar los servicios de un tercero procesador de
  pagos, quien -en todo caso- quedará sujeto a lo dispuesto en el
  presente Aviso y en la legislación aplicable.</SPAN></FONT></FONT></FONT></P>
 
  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">De
    los candidatos laborales/profesionales: historial académico,
    historial profesional y datos que se relacionen con su nivel de
    ingresos.</SPAN></FONT></FONT></FONT></P>
  </UL>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">DSC
  en ningún momento recabará del Titular datos que puedan ser
  considerados como sensibles.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Finalidades
  del tratamiento</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
  datos personales tratados por DSC serán utilizados para llevar a
  cabo las siguientes finalidades:</SPAN></FONT></FONT></FONT></P>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Finalidades
    primarias:</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Identificar
    al Titular y corroborar su identidad;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Ofrecer
    y prestar servicios de asesoría culinaria, apoyo técnico y
    comercialización de productos en general;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">En
    caso de registro, crear un perfil de usuario a través del cual
    puedan monitorearse sus preferencias y hábitos de consumo, así
    como ofrecerse de forma particularizada los servicios ofrecidos por
    DSC;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Transferir
    sus datos a proveedores y/o prestadores de servicio de DSC, siempre
    que su política de privacidad sea consistente con el presente Aviso
    y siempre que éstos se obliguen a cumplir con lo dispuesto en la
    legislación aplicable en materia de protección de datos
    personales;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">En
    su caso, evaluar la idoneidad profesional/laboral del candidato para
    integrarse al equipo de DSC.</SPAN></FONT></FONT></FONT></P>
  </UL>
 
  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Finalidades
    secundarias</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Ofrecer
    actualizaciones, noticias y/o publicidad que pudiera ser de interés
    para el Titular; y</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Utilizar
    los datos personales para fines mercadotécnicos, publicitarios o
    prospección comercial.</SPAN></FONT></FONT></FONT></P>
  </UL>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
  Titular podrá manifestar su negativa al tratamiento de sus datos con
  las finalidades secundarias, a través del envío de un correo
  electrónico a DSC. El correo electrónico deberá incluir, en todo
  caso, la información que permita identificar al Titular, la
  información sobre la cual niega su tratamiento y cualquier otra
  información que resulte necesaria para tramitar la negativa
  correspondiente.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Consentimiento
  del Titular</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
  Titular manifiesta que:</SPAN></FONT></FONT></FONT></P>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Ha
    leído, entendido y aceptado los términos y condiciones
    establecidos en el presente Aviso, por lo que otorga su
    consentimiento respecto del tratamiento de sus datos personales en
    los términos que en él se establecen;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
    uso de o la permanencia en el Sitio y/o la contratación por
    cualquier forma de los bienes o servicios ofrecidos por DSC,
    constituyen re-confirmaciones de su consentimiento a lo dispuestos
    en el presente Aviso.</SPAN></FONT></FONT></FONT></P>
  </UL>
  
  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
  Titular deberá abstenerse de utilizar el Sitio o de contratar los
  bienes o servicios de DSC en caso de que no consienta las
  disposiciones establecidas en el presente Aviso.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">DSC
  asume que la información proporcionada por el Titular le pertenece a
  este último. En caso de que no sea así, el Titular deberá informar
  dicha circunstancia inmediatamente a DSC, a través del envío de un
  correo electrónico que deberá contener su información de contacto
  y cualquier otra información que permita dar respuesta a dicho
  informe, absteniéndose de proporcionar información adicional de la
  que no sea titular o de la que no tenga autorización para
  transferir.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
  Titular podrá revocar el consentimiento antes referido en todo
  momento. Para revocar el consentimiento proporcionado, el Titular
  deberá comunicar dicha circunstancia a DSC por medio de un correo
  electrónico, indicando las causas que lo motivan a comunicar la
  revocación, así como la información que permita a DSC responder y
  dar seguimiento a dicha revocación.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">En
  caso de que el Titular desee limitar el uso o la divulgación de
  alguno de sus datos personales, podrá en cualquier momento enviar la
  manifestación de dicha limitación a través de un correo
  electrónico, en el cual deberá incluir los datos personales cuyo
  tratamiento desea limitar, los motivos por los cuales desea
  limitarlos, así como la información que permita a DSC dar
  seguimiento a dicha solicitud. En caso de ser procedente la solicitud
  del Titular, DSC registrará al Titular en la lista de exclusión que
  tendrá elaborada para tal efecto.</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Derechos
  de Acceso, Rectificación, Cancelación y Oposición (“ARCO”)</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
  Titulares tienen derecho a: </SPAN></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><I><SPAN STYLE="background: #ffffff">(i)</SPAN></I></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">
  conocer qué datos personales son tratados por DSC y las finalidades
  de su tratamiento (derecho de acceso); </SPAN></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><I><SPAN STYLE="background: #ffffff">(ii)</SPAN></I></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">
  solicitar la corrección de sus datos personales en caso de que estén
  desactualizados, sean inexactos o estén incompletos (derecho de
  rectificación); </SPAN></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><I><SPAN STYLE="background: #ffffff">(iii)</SPAN></I></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">
  que sus datos personales sean eliminados de los registros o bases de
  datos de DSC cuando consideren que no están siendo utilizados
  adecuadamente (derecho de cancelación); y </SPAN></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><I><SPAN STYLE="background: #ffffff">(iv)</SPAN></I></FONT></FONT></FONT><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">
  oponerse al uso de sus datos personales para fines específicos
  (derecho a oposición) (en su conjunto, los derechos “ARCO”)</SPAN></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
  derechos ARCO pueden ser ejercidos en cualquier momento a través del
  envío de un correo electrónico dirigido a DSC. La solicitud de
  cualquiera de los derechos ARCO deberá ir acompañada de lo
  siguiente:</SPAN></FONT></FONT></FONT></P>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
    nombre del titular y/o su domicilio o correo electrónico, a efecto
    de que DSC pueda comunicarle la respuesta a su solicitud;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
    documentos que acrediten la identidad o, en su caso, la
    representación legal del titular;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">La
    descripción clara y precisa de los datos personales respecto de los
    que se busca ejercer alguno de los derechos ARCO;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Los
    motivos que fundamentan o justifican el ejercicio del derecho ARCO
    correspondiente;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">El
    formato o el medio en el que desea que DSC dé respuesta a su
    solicitud, cuando resulte aplicable;</SPAN></FONT></FONT></FONT></P>
  </UL>

  <UL>
    <LI><P ALIGN=JUSTIFY  line-height: 0.19in">
    <FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">Cualquier
    otro elemento o documento que facilite el seguimiento a la solicitud
    del Titular.</SPAN></FONT></FONT></FONT></P>
  </UL>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><B><SPAN STYLE="background: #ffffff">Uso
  de cookies, web beacons o tecnologías similares o análogas</SPAN></B></FONT></FONT></FONT></P>

  <P ALIGN=JUSTIFY  line-height: 0.19in"><FONT COLOR="#00000a"><FONT FACE="Economica, serif"><FONT SIZE=5 STYLE="font-size: 1.3em"><SPAN STYLE="background: #ffffff">DSC
  podrá utilizar cookies, web beacons y otras tecnologías para
  monitorear su comportamiento como usuario de internet, con la
  finalidad de brindarle un mejor servicio y experiencia de usuario al
  navegar en el Sitio, así como ofrecerle nuevos productos basados en
  su preferencia. Los datos personales que DSC obtiene de estas
  tecnologías de rastreo son particularmente los siguientes: horario
  de navegación, tiempo de navegación, secciones consultadas y
  páginas de internet accedidas previamente, dirección IP de origen,
  navegador utilizado, sistema operativo, siendo posible monitorear su
  comportamiento como usuario de los servicios de internet.</SPAN></FONT></FONT></FONT></P>
  
`
