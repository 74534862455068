import React from 'react'
import { Content, Box, Input, Leggend, BoxSeparator } from './styled'
import { Grid, useMediaQuery, Typography, Checkbox, Box as BoxMaterial, Collapse, FormHelperText } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { array, func, object, string } from 'prop-types'
import cfdiOptions from './cfdiOptions'

const Shipping = props => {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Content>
      <Box>
        {!!props.errorMessage && (
          <Alert style={{ marginBottom: '10px' }} severity='error'>{props.errorMessage}</Alert>
        )}
        <BoxSeparator as={Grid} container>
          <Grid item xs={12}>
            <Typography color='primary' style={{ fontWeight: 'bold', fontFamily: 'var(--front-font)' }} variant={isMobile ? 'subtitle1' : 'h6'}>Información de contacto</Typography>
          </Grid>
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='email' placeholder='Correo electrónico' maxlength={70} />
        </BoxSeparator>
        <BoxSeparator container as={Grid}>
          <Grid item xs={12}>
            <Typography color='primary' style={{ fontWeight: 'bold', fontFamily: 'var(--front-font)' }} variant={isMobile ? 'subtitle1' : 'h6'}>Dirección de envío</Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingRight: isMobile ? '0px' : '10px' }}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='name' placeholder='Nombre' maxlength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='lastname' placeholder='Apellidos' maxlength={50} />
          </Grid>
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='street_number' placeholder='Calle y número' maxlength={100} />
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='suburb' placeholder='Colonia' maxlength={50} />
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='city' placeholder='Ciudad' maxlength={50} />
          <Grid item xs={6} style={{ paddingRight: '10px' }}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='state' placeholder='Estado' maxlength={50} />
          </Grid>
          <Grid item xs={6}>
            <Input size={isMobile ? 'small' : 'medium'} {...props} name='postal_code' placeholder='Código Postal' filter='number' maxlength={5} />
          </Grid>
          <Input size={isMobile ? 'small' : 'medium'} {...props} name='number' placeholder='Tel./cel.' filter='number' maxlength={10} />
          <Leggend>En caso de que tengamos que contactarte sobre tu pedido</Leggend>
        </BoxSeparator>

        <BoxSeparator>
          <Grid container>
            <Grid item xs={12}>
              <Typography color='primary' style={{ fontWeight: 'bold', fontFamily: 'var(--front-font)' }} variant={isMobile ? 'subtitle1' : 'h6'}>Datos de facturación</Typography>
            </Grid>
            <Grid item xs={12} container alignItems='center'>
              <Checkbox onChange={event => props.onChange({ target: { name: 'willFact', value: event.target.checked } })} checked={props.data.willFact} />
              <BoxMaterial fontSize='1.2em'>
                Quiero facturar
              </BoxMaterial>
            </Grid>
          </Grid>

          <Collapse in={props.data.willFact}>
            <Grid container>
              <Grid item xs={12} sm={6} style={{ paddingRight: isMobile ? '0px' : '10px' }}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_name' placeholder='Nombre' maxlength={50} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_lastname' placeholder='Apellidos' maxlength={50} />
              </Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_rfc' placeholder='RFC' maxlength={50} />
              </Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_rs' placeholder='Razón social' maxlength={50} />
              </Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_adress' placeholder='Dirección' maxlength={50} />
              </Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_email' placeholder='Correo electrónico' maxlength={50} />
              </Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='fact_tel' placeholder='Teléfono' maxlength={10} filter='number' />
              </Grid>
              {props.errors && props.errors.includes('fact_type') && (
                <Grid item xs={12}>
                  <FormHelperText error>Elige una opción</FormHelperText>
                </Grid>
              )}
              <Grid item xs={6} container alignItems='center'>
                <Checkbox disabled={!props.data.willFact} onClick={event => props.onChange({ target: { name: 'fact_type', value: 'Persona moral' } })} name='fact_type' checked={props.data.fact_type === 'Persona moral'} />
                <BoxMaterial fontSize='1.2em'>
                  Persona moral
                </BoxMaterial>
              </Grid>
              <Grid item xs={6} container alignItems='center'>
                <Checkbox disabled={!props.data.willFact} onClick={event => props.onChange({ target: { name: 'fact_type', value: 'Persona física' } })} name='fact_type' checked={props.data.fact_type === 'Persona física'} />
                <BoxMaterial fontSize='1.2em'>
                  Persona física
                </BoxMaterial>
              </Grid>
              <Grid item xs={12}><BoxMaterial marginBottom='.5em' /></Grid>
              <Grid item xs={12}>
                <Input disabled={!props.data.willFact} size={isMobile ? 'small' : 'medium'} {...props} name='cfdi' placeholder='Uso de CFDI' type='select' options={cfdiOptions(props.data.fact_type === 'Persona moral')} />
              </Grid>
            </Grid>
          </Collapse>

        </BoxSeparator>
      </Box>
    </Content>
  )
}

Shipping.propTypes = {
  errors: array,
  errorMessage: string,
  onChange: func,
  data: object
}

export default Shipping
