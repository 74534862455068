import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../components/layout'
import { Container } from '../../components/main'
import Cover from './cover'
import { Box, Grid, Divider, useMediaQuery, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Picture from '../../../components/picture'
import currency from '../../../helpers/currency'
import paypal from '../../../assets/openpay/paypal.png'
import Logosrc from '../../../assets/logo.png'
import { payDayLimitForStore, payDayLimitForBank, companyName } from '../../../config'
import { addDays, toString } from '../../../helpers/date'
import paynetsrc from '../../../assets/openpay/paynet.png'
import openpaylogo from '../../../assets/openpay/logo.png'

const Success = props => {
  var { state = {} } = useLocation()
  const isMobile = useMediaQuery('(max-width:600px)')

  // scroll to top
  useEffect(() => {
    window.scrollTo()
  }, [])

  var payDayLimit = toString(addDays(state.operation_date, payDayLimitForStore))
  var payDayLimitSpei = toString(addDays(state.operation_date, payDayLimitForBank))

  console.log('state', state)

  if (state.methodPay === 'spei') {
    return (
      <Layout>
        <Cover />
        <Container>
          <Box mt={5} mb={5}>
            <Limiter>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box p={5}>
                    <TitlePrimary>
                      Gracias por tu compra
                    </TitlePrimary>
                    <Box mt={5} textAlign='center'>
                      <img src={openpaylogo} style={{ width: isMobile ? '110px' : '150px' }} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} container justify='center' alignItems='center'>
                  <Box>
                    <Text>
                      Fecha límite de pago
                    </Text>
                    <Title style={{ fontFamily: 'var(--front-font)' }} $bold $red>{payDayLimitSpei}</Title>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <RowSeparated>
                    <Title $bold $red>Tu número de orden</Title>
                    <Title $bold $red>{state.orderId || '000000000'}</Title>
                  </RowSeparated>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <RowSeparated>
                    <Text $bold $red>Detalles de pedido</Text>
                  </RowSeparated>
                </Grid>
                {!!state.items && state.items.map(item => (
                  <Grid item xs={12} key={item.id}>
                    <ShadowBox style={{ display: 'flex', alignItems: 'center' }}>
                      <Picture src={item.picture} style={{ width: '20%', marginRight: '20px' }} />
                      <RowSeparated style={{ flexGrow: 1 }}>
                        <div>
                          <Text $bold style={{ marginRight: '15px' }}>{item.title}</Text>
                          {item.color && (
                            <Typography color='primary' style={{ fontSize: '1.1em' }}><span style={{ color: '#000', fontWeight: 'bold' }}>Presentación: </span>{item.color.name}</Typography>
                          )}
                        </div>
                        <Text $bold>{currency.formatMoney(item.price)}</Text>
                      </RowSeparated>
                    </ShadowBox>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <ShadowBox style={{ backgroundColor: 'var(--front-red)', fontSize: '1.2em' }}>
                    <RowSeparated style={{ flexGrow: 1 }}>
                      <Text style={{ marginRight: '15px', color: '#fff' }}>Total {state.methodPay === 'paypal' || state.methodPay === 'card' ? 'pagado' : 'a pagar'}</Text>
                      <Title $bold style={{ color: '#fff', fontFamily: 'var(--front-font)' }}>${currency.formatMoney(state.totalPrice)}</Title>
                    </RowSeparated>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <Box />
                </Grid>

                <Grid item xs={12}>
                  <Title $red $bold>Tus datos</Title>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Nombre:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.user.name}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <Text style={{ textAlign: 'right' }} error>Email:</Text>
                      </Grid>
                      <Grid item sm={9}>
                        <Text $bold>{state.user.email}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Dirección:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>
                          {state.user.street_number}
                          , {state.user.suburb}
                          , {state.user.postal_code}
                          , {state.user.city}
                          , {state.user.state}
                        </Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <Text style={{ color: 'gray', textAlign: 'center' }}>
                    Nota: Se envió esta misma información al correo proporcionado.
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Box />
                </Grid>

                <Grid item xs={12}>
                  <Title $red $bold>Transferencia desde BBVA</Title>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Número de Convenio CIE:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.payment_method.agreement}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Referencia:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.payment_method.name}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Importe:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{currency.formatMoney(state.totalPrice)}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>

                <Grid item xs={12}>
                  <Title $red $bold>Desde cualquier otro banco</Title>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Beneficiario:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{companyName}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Banco Destino:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>BBVA Bancomer</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>CLABE:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.payment_method.clabe}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Concepto de Pago:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.payment_method.name}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
                <Grid item xs={12}>
                  <ShadowBox>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Text style={{ textAlign: 'right' }} error>Referencia:</Text>
                      </Grid>
                      <Grid item xs={9}>
                        <Text $bold>{state.payment_method.agreement}</Text>
                      </Grid>
                    </Grid>
                  </ShadowBox>
                </Grid>
              </Grid>
            </Limiter>
          </Box>
        </Container>
      </Layout>
    )
  }

  if (state.methodPay === 'cash') {
    return (
      <Layout>
        <Cover />
        <Container>
          <Box mt={5} mb={5}>
            <Limiter>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box p={isMobile ? 0 : 2}>
                    <LogoOficial src={Logosrc} />
                    <Box mb={isMobile ? 2 : 5} />
                    <TitlePrimary>
                      Orden de pago <br />  para tiendas de Autoservicio
                    </TitlePrimary>
                    <Box mt={5} textAlign='center'>
                      <img src={openpaylogo} style={{ width: isMobile ? '110px' : '150px' }} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Text style={{ textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Orden OID:</span> {state.orderId || '__0000__'}</Text>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} container justify='space-between' alignItems='center'>
                  <Box>
                    <Text>
                      Fecha límite de pago
                    </Text>
                    <Title style={{ fontFamily: 'var(--front-font)' }} $bold $red>{payDayLimit}</Title>
                  </Box>
                  <Box>
                    <Text>
                      Servicio a pagar
                    </Text>
                    <img src={paynetsrc} style={{ width: isMobile ? '110px' : '150px' }} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <img src={state.payment_method.barcode_url} style={{ margin: 'auto', display: 'block' }} />
                  <Text style={{ textAlign: 'center' }}>{state.payment_method.reference}</Text>
                  <Box mt={2}>
                    <Text style={{ textAlign: 'center', color: 'gray', fontSize: '1em' }}>En caso de no poder leer el código de barras, escribir el código tal como se muestra</Text>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2} style={{ backgroundColor: 'var(--front-red)', borderRadius: '15px' }}>
                    <Text style={{ textAlign: 'center', color: '#fff', margin: 'auto' }}>Total a pagar</Text>
                    <TitlePrimary style={{ textAlign: 'center', color: '#fff', margin: 'auto' }}> $ {currency.formatMoney(state.totalPrice)}</TitlePrimary>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Title>Detalles de la compra</Title>
                  {state.items.map(item => (
                    <Title $red key={item.id} style={{ fontFamily: 'var(--front-font)' }}>
                      {item.title}
                    </Title>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Title $bold>Pasos para realizar el pago</Title>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>1</Number>Acude a cualquier tienda afiliada.
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>2</Number>Entrega al cajero el código de barras y menciona que realizarás un pago de servicio de Paynet.
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>3</Number> Realizar el pago en efectivo por ${currency.formatMoney(state.totalPrice)}
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>4</Number>Conserva el ticket para cualquier aclaración
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ color: 'gray', textAlign: 'center' }}>
                      En caso de dudas, Favor de comunicarse A Grupo Santa Cruz al télefono 000000000 o al correo contacto@distribucionessantacruz.com.mx
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Title $bold>Instrucciones para el cajero</Title>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>1</Number>Ingresa el menú de Pago de Servicio.
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>2</Number>Selecciona Paynet.
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>3</Number> Escanear el código de barras o ingresa el número de referencia.
                    </Text>
                  </Box>
                  <Box p={1}>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Number>4</Number>Cobrar al cliente el monto total más comisión.
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            </Limiter>
          </Box>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Cover />
      <Container>
        <Box mt={5} mb={5}>
          <Limiter>
            <Grid container spacing={2}>
              {state.methodPay === 'paypal' && (
                <Grid item xs={12}>
                  <Box p={5}>
                    <TitlePrimary>
                      Gracias por tu pago vía
                    </TitlePrimary>
                    <Logo src={paypal} />
                  </Box>
                </Grid>
              )}
              {state.methodPay === 'card' && (
                <Grid item xs={12}>
                  <Box p={5}>
                    <TitlePrimary>
                      Gracias por tu pago
                    </TitlePrimary>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <RowSeparated>
                  <Title $bold $red>Tu número de orden</Title>
                  <Title $bold $red>{state.orderId || '000000000'}</Title>
                </RowSeparated>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <RowSeparated>
                  <Text $bold $red>Detalles de pedido</Text>
                </RowSeparated>
              </Grid>
              {!!state.items && state.items.map(item => (
                <Grid item xs={12} key={item.id}>
                  <ShadowBox style={{ display: 'flex', alignItems: 'center' }}>
                    <Picture src={item.picture} style={{ width: '20%', marginRight: '20px' }} />
                    <RowSeparated style={{ flexGrow: 1 }}>
                      <div>
                        <Text $bold style={{ marginRight: '15px' }}>{item.title}</Text>
                        {item.color && (
                          <Typography color='primary' style={{ fontSize: '1.1em' }}><span style={{ color: '#000', fontWeight: 'bold' }}>Presentación: </span>{item.color.name}</Typography>
                        )}
                      </div>
                      <Text $bold>{currency.formatMoney(item.price)}</Text>
                    </RowSeparated>
                  </ShadowBox>
                </Grid>
              ))}
              <Grid item xs={12}>
                <ShadowBox style={{ backgroundColor: 'var(--front-red)', fontSize: '1.2em' }}>
                  <RowSeparated style={{ flexGrow: 1 }}>
                    <Text style={{ marginRight: '15px', color: '#fff' }}>Total {state.methodPay === 'paypal' || state.methodPay === 'card' ? 'pagado' : 'a pagar'}</Text>
                    <Title $bold style={{ color: '#fff', fontFamily: 'var(--front-font)' }}>${currency.formatMoney(state.totalPrice)}</Title>
                  </RowSeparated>
                </ShadowBox>
              </Grid>
              <Grid item xs={12}>
                <Box />
              </Grid>
              <Grid item xs={12}>
                <Title $red $bold>Tus datos</Title>
              </Grid>
              <Grid item xs={12}>
                <ShadowBox>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Text style={{ textAlign: 'right' }} error>Nombre:</Text>
                    </Grid>
                    <Grid item xs={9}>
                      <Text $bold>{state.user.name}</Text>
                    </Grid>
                  </Grid>
                </ShadowBox>
              </Grid>
              <Grid item xs={12}>
                <ShadowBox>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <Text style={{ textAlign: 'right' }} error>Email:</Text>
                    </Grid>
                    <Grid item sm={9}>
                      <Text $bold>{state.user.email}</Text>
                    </Grid>
                  </Grid>
                </ShadowBox>
              </Grid>
              <Grid item xs={12}>
                <ShadowBox>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Text style={{ textAlign: 'right' }} error>Dirección:</Text>
                    </Grid>
                    <Grid item xs={9}>
                      <Text $bold>
                        {state.user.street_number}
                        , {state.user.suburb}
                        , {state.user.postal_code}
                        , {state.user.city}
                        , {state.user.state}
                      </Text>
                    </Grid>
                  </Grid>
                </ShadowBox>
              </Grid>
              <Grid item xs={12}>
                <Text style={{ color: 'gray', textAlign: 'center' }}>
                  Nota: Se envió esta misma información al correo proporcionado.
                </Text>
              </Grid>
            </Grid>
          </Limiter>
        </Box>

      </Container>
    </Layout>
  )
}
const Title = styled.div`
  font-size: 1.4em;
  color: ${props => props.$red ? 'var(--front-red)' : '#000'};
  font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  @media screen and (max-width:1100px) {
    font-size: 1.1em;
  }
`

const Text = styled.div`
  font-size: 1.2em;
  color: ${props => props.$red ? 'var(--front-red)' : '#000'};
  font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  @media screen and (max-width:1100px) {
    font-size: 1em;
  }
`
const Number = styled.div`
  background: #f2f2f2;
  color: var(--front-red);
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 10px;
`
const RowSeparated = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`

const ShadowBox = styled.div`
  background: #f2f2f2;
  padding: 15px;
  border-radius : 10px;
`
const Logo = styled.img`
  display: block;
  margin: auto;
  max-width: 200px;
`
const TitlePrimary = styled.div`
  font-size: 2em;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width:800px) {
    font-size: 1.5em;
  }
`
const Limiter = styled.div`
  width: 45%;
  margin: auto;
  @media screen and (max-width:1400px) {
    width: 52%;
  }
  @media screen and (max-width:1100px) {
    width: 65%;
  }
  @media screen and (max-width:800px) {
    width: 90%;
  }
`
const LogoOficial = styled.img`
  display: block;
  margin: auto;
  width: 120px;
  @media (max-width:600px) {
    width: 100px;
  }
`
export default Success
