import React, { useEffect } from 'react'
import Layout from '../../components/layout'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import Cards from './cards'
import Provoders from './prividers'
import Team from './team'
// import Instagram from './instagram'

const Home = props => {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Cover />
      <CoverSecondary
        content='MÁS DE 30 AÑOS CONTRIBUYENDO'
        footer='Al desarrollo y crecimiento de nuestros clientes'
      />
      <Cards />
      <Provoders />
      <Team />
      {/* <Instagram /> */}
    </Layout>
  )
}

export default Home
