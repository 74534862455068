/* eslint-disable react/jsx-handler-names */
import React from 'react'
import styled from 'styled-components'
import { Typography, Container, Box, Grid, TextField, IconButton } from '@material-ui/core'
import Dropzone from './Dropzone'
import Picture from '../../../components/picture'
import { Alert } from '@material-ui/lab'
import { array, func, number } from 'prop-types'
import { Delete } from '@material-ui/icons'

const Colors = (props) => {
  // 'información general', 'Variantes', 'Multimedia', 'Resumen'
  if (!props.steps || props.steps[props.currentStep] !== 'Variantes') return null

  return (
    <Container maxWidth='md' disableGutters>
      {props.errors.length !== 0 && (
        <Alert severity='error'>{props.errors[0] === 'colors' ? 'Agrega al menos 1 color' : 'El nombre de las variantes es requerido'}</Alert>
      )}
      {props.colors.length < 100 && (
        <>
          <TextContainer>
            <Title>Variantes</Title>
            <SubTitle>Selecciona las imágenes que representan las variantes</SubTitle>
          </TextContainer>
          <Dropzone
            handleDrop={props.handleDropPictureColors}
            multiple
            {...props}
          />
        </>
      )}
      {!!props.colors.length && (
        <Box mt={3} mb={3}>
          <Grid container spacing={5}>
            {props.colors.map((color, index) => (
              <GridStyled item xs={3} key={index}>
                <IconDelete size='small' onClick={event => props.handleDeleteColor(index)}>
                  <Delete />
                </IconDelete>
                <Picture src={typeof color.picture === 'string' ? color.picture : color.picture.preview} />
                <TextField label='Nombre' fullWidth color='secondary' onChange={event => props.handleChangeColorName(event.target.value, index)} value={color.name || ''} />
              </GridStyled>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  )
}

Colors.propTypes = {
  errors: array,
  handleDelete: func,
  handleDrop: func,
  currentStep: number,
  handleDropPictureColors: func,
  colors: array,
  handleChangeColorName: func,
  handleDeleteColor: func,
  steps: array
}

const TextContainer = styled('div')`
  margin-top: 30px;
  margin-bottom: 30px;
`

const Title = styled('h6')`
  font-size: 1.5em;
  padding: 0px;
  margin: 0px;
  color: var(--main-blue-dark);
`

const SubTitle = styled(Typography)`
  color: var(--main-blue-dark);
`
const GridStyled = styled(Grid)`
  position: relative;
`
const IconDelete = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--main-blue);
  svg {
    color: #fff;
  }
  :hover {
    background-color: var(--main-blue-dark);
  }
`
export default Colors
