import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { fetchSpecificItem } from '../../../flux/items'
import { addItem } from '../../../flux/cart'
import { setNotification } from '../../../flux/notification'
import { Container } from '../../components/main'
import Data from './data'
import Skeleton from './skeleton'
import Related from './related'
import Cover from '../productos/cover'
import CoverSecondary from '../../components/coverSecondary'
import Searcher from '../../components/searcher'
import { categories } from '../../../constants'

const Item = props => {
  const history = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()
  var { items, loading } = useSelector(state => state.items)
  const itemSelected = items[id] || null
  const [quantity, setQuantity] = useState(1)
  const [color, setColor] = useState(null)

  // autoset primary color if exists
  useEffect(() => {
    if (itemSelected && itemSelected.colors && itemSelected.colors.length) {
      setColor(itemSelected.colors[0])
    }
  }, [items, loading])

  // fetch data
  useEffect(() => {
    if (!itemSelected) dispatch(fetchSpecificItem(id))
  }, [])

  const AddToCart = _event => {
    dispatch(setNotification({
      type: 'success',
      message: `Se han agregado ${quantity} ${itemSelected.title} en tu carrito`
    }))
    const secureData = { ...itemSelected, quantity }
    if (color) secureData.color = color
    dispatch(addItem(secureData))
  }

  const onQuantityChange = event => {
    var newValue = event.target.value
    if (newValue === '') {
      setQuantity('')
      return false
    }
    newValue = parseInt(newValue)
    if (isNaN(newValue)) newValue = 1
    setQuantity(newValue)
  }

  const handleSetKeywords = keywords => {
    history.push({ pathname: '/productos', state: { keywords } })
  }

  return (
    <Layout>
      <Cover />
      <CoverSecondary
        header='PRODUCTOS'
        content='¡TE AYUDAMOS A HACER CRECER TU NEGOCIO!'
      />
      <Searcher
        setKeywords={handleSetKeywords}
        steps={['PRODUCTOS', itemSelected ? categories[itemSelected.category] : 'CATEGORIA']}
      />
      {loading && (
        <Container>
          <Skeleton />
        </Container>
      )}
      {!loading && (
        <Container>
          <Data
            AddToCart={AddToCart}
            {...itemSelected}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            setQuantity={setQuantity}
            color={color}
            onColorChange={setColor}
          />
        </Container>
      )}

      <Related />

    </Layout>
  )
}

export default Item
