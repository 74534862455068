import styled from 'styled-components'

export const Section = styled.section`
padding: 20px 0px;
@media screen and (max-width:1600px) {
  font-size: .8em;
}
@media screen and (max-width:1150px) {
  font-size: .7em;
}
@media screen and (max-width:800px) {
  font-size: .5em;
  padding: 15px 0px;
}
`

export const Title = styled.div`
  font-size: 3em;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  @media (max-width:600px) {
    font-size: 2.5em;
  }
`
