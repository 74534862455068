import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import CreateInput from '../../components/input'
import { Alert } from '@material-ui/lab'
import { Fade, Button, Box } from '@material-ui/core'
import propTypes, { string, array } from 'prop-types'
import { recipeCategories } from '../../../constants'
import JoditEditor from 'jodit-react'
import { Delete } from '@material-ui/icons'

const GeneralInfo = (props) => {
  console.log('props', props)
  const [steps, setStep] = useState(props.ingredients || [])

  const handleProcessChange = value => {
    console.log('value', value)
    if (typeof value === 'object' && value.target && value.target.innerHTML) {
      return props.handleChange({ name: 'process', value: value.target.innerHTML })
    } else if (typeof value === 'string') {
      props.handleChange({ name: 'process', value })
    }
  }

  const handleAdd = () => setStep([...steps, { title: '', url: '' }])

  const handleChange = (target, step) => {
    console.log('target, step', target, step)
    const currentStep = step
    const currentData = [...steps]
    currentData[currentStep][target.name] = target.value
    setStep(currentData)
  }

  const handleDelete = indexToDelete => {
    const newData = steps.filter((_data, index) => indexToDelete !== index)
    setStep(newData)
  }

  // sync global state with local State
  useEffect(() => {
    props.handleChange({ name: 'ingredients', value: steps })
  }, [steps])

  if (props.currentStep !== 0) return null

  return (
    <Content>
      <Fade in={props.errors.length !== 0}>
        <GroupForm>
          <Alert severity='error'>Algunos campos son necesarios</Alert>
        </GroupForm>
      </Fade>
      <GroupForm>
        <Label>Nombre</Label>
        <TitleInput
          type='text'
          name='title'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>Categoria</Label>
        <TitleInput
          type='select'
          name='category'
          {...props}
          options={recipeCategories}
          size='large'
        />
      </GroupForm>
      <GroupForm>
        <Label>Tiempo de preparación</Label>
        <TitleInput
          type='text'
          name='time'
          {...props}
        />
      </GroupForm>
      <GroupForm>
        <Label>Procedimiento</Label>
        <div style={{ border: '1px solid var(--main-blue)', borderRadius: '5px', overflow: 'hidden' }}>
          <JoditEditor
            config={{ height: 250, askBeforePasteFromWord: false, askBeforePasteHTML: false }}
            onBlur={handleProcessChange}
            value={props.process || ''}
          />
        </div>
      </GroupForm>

      <GroupForm>
        <Label>Ingredientes</Label>
        {steps.map((value, index) => (
          <Fragment key={index}>
            <LabelSecondary>ingrediente {index + 1}</LabelSecondary>
            <div style={{ position: 'relative', border: '1px dashed #0e3f67', padding: '20px', borderRadius: '5px', marginTop: '5px' }}>
              <TitleInput
                placeholder='Ingrediente'
                size='small'
                place
                onChange={event => handleChange(event, index)}
                type='text'
                name='title'
                rows={2}
                value={value.title || ''}
                // {...props}
              />
              <Box pt={2}>
                <TitleInput
                  placeholder='url (opcional)'
                  size='small'
                  onChange={event => handleChange(event, index)}
                  type='text'
                  name='url'
                  rows={2}
                  value={value.url || ''}
                  // {...props}
                />
              </Box>
              <DeleteStyled onClick={_event => handleDelete(index)} />
            </div>
          </Fragment>
        ))}

        <ButtonContainer>
          <ButtonStyled onClick={handleAdd} variant='contained' color='secondary'> Agregar ingrediente </ButtonStyled>
        </ButtonContainer>
      </GroupForm>

    </Content>
  )
}

GeneralInfo.propTypes = {
  errors: propTypes.array,
  currentStep: propTypes.number,
  price: propTypes.number,
  handleChange: propTypes.func,
  process: string,
  ingredients: array
}

const Content = styled('div')`
  max-width: 800px;
  margin: auto;
`
const GroupForm = styled('div')`
  margin: auto;
  margin-bottom: 20px;
  width: 80%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
const Label = styled('div')`
  color: var(--main-blue-dark);
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.1em;
`
const TitleInput = styled(CreateInput)`
  width: 100%;
  .MuiTextField-root{
    color: red
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

const ButtonStyled = styled(Button)`
  min-width: 120px;
  margin-right: 20px;
  @media screen and (max-width:500px){
    &:last-of-type{
      margin-right: 0px;
    }
  }
`
const LabelSecondary = styled.div`
  color: var(--main-blue-dark);
  margin-top: 10px;
  font-weight: bold;
`
const DeleteStyled = styled(Delete)`
  position: absolute;
  top: 2px;
  right: 0;
  color: var(--main-blue);
  cursor: pointer;
`
export default GeneralInfo
