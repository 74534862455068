/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Container } from '../../components/main'
import { Grid, IconButton, Button, useMediaQuery } from '@material-ui/core'
import { Facebook, Instagram, ShoppingCartOutlined, Menu } from '@material-ui/icons'
import { Link as LinkCrud, useLocation } from 'react-router-dom'
import logoSrc from '../../../assets/logo.png'
import { useSelector } from 'react-redux'
import MenuMobile from './menuMobile'

const HeaderStyled = styled.header`
  color: #fff;
  font-family: var(--front-font);
`
const Link = styled(LinkCrud)``

const First = styled(Grid)`
  padding: 20px 0px;
`
const IconContainer = styled(IconButton)`
  position: relative;
  background: #f9b200;
  margin-right: 15px;
  svg {
    color: red;
    @media screen and (max-width:700px) {
      font-size:.9rem;
    }
  }
  &:hover {
    background: #f9b200;
  }
`
const Counter = styled.div`
  background: var(--front-red);
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  bottom: -8px;
  font-size: .8rem;
  font-weight: bold;
  @media (max-width:600px) {
    width: 15px;
    height: 15px;
    font-size: .7rem;
    bottom: -5px;
  }
`

const ContactContainer = styled.div`
  margin-right: 15px;
  background-color: rgb(0 0 0 / 25%);
  padding: 2px;
  border-radius: 12px;
`

const Text = styled.div`
  font-size: 1.2rem;
  @media screen and (max-width:700px) {
    font-size:.9rem;
  }
`

const TextNumber = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  @media screen and (max-width:700px) {
    font-size:.8rem;
  }
`
const Second = styled.div`
  box-sizing: border-box;
  top: 25px;
  position: ${props => props.fixed ? 'fixed' : 'relative'};
  width: ${props => props.fixed ? 'inherit' : 'auto'};
  padding:0px 20px;
  margin: 0px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  z-index: 10;
  @media (max-width:1400px) {
    height: 75px;
  }
  @media (max-width:959px) {
    padding: 15px;
    flex-direction: row-reverse;
  }
  @media screen and (max-width:700px) {
    height: 50px;
  }
`

const LinksOnMenu = styled(Grid)`
  text-decoration: none!important;
  & a {
    text-decoration: none;
  }
`
const Logo = styled.img`
  display: block;
  margin: auto;
  position: relative;
  width: ${props => props.fixed ? '120px' : '200px'};
  @media screen and (max-width:959px) {
    width: ${props => props.fixed ? '120px' : '140px'};
  }
  @media screen and (max-width:600px) {
    width: ${props => props.fixed ? '80px' : '100px'};
  }
`
const ButtonStyled = styled(Button)`
  font-family: var(--front-font);
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.variant === 'contained' ? '#fff' : 'gray'};
  text-decoration: none!important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  @media (max-width:1400px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.1rem;
  }
  @media (max-width:1080px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: .9rem;
  }
`

const Parent = styled.div`
  min-height: 90px;
  @media (max-width:1400px) {
    min-height: 75px;
  }
  @media screen and (max-width:700px) {
    min-height: 50px;
  }
`

const Header = props => {
  const { pathname } = useLocation()
  const [isFixed, setFixed] = useState(false)
  const itemsOnCart = useSelector(state => state.cart)
  const isMobile = useMediaQuery('(max-width:959px)')
  const [width, setWidth] = useState('100%')
  const [isMenuOpen, setMenu] = useState(false)

  // handleScroll
  useEffect(() => {
    const handleScroll = event => {
      if (window.scrollY >= 100 && !isFixed) {
        setFixed(true)
      } else if (window.scrollY < 100 && isFixed) {
        setFixed(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFixed])

  // setWidthFixed
  useEffect(() => {
    const handleResise = event => {
      const parentWidth = document.getElementById('parent').offsetWidth
      setWidth(parentWidth)
    }
    handleResise()
    window.addEventListener('resize', handleResise)
    return () => window.removeEventListener('resize', handleResise)
  }, [])

  return (
    <HeaderStyled>
      <Container>
        <First container justify='space-between' direction={isMobile ? 'row-reverse' : 'row'}>
          <div>
            <Link as='a' href='https://www.instagram.com/distribucionesantacruzmx/' target='_blank'>
              <IconContainer size='medium'>
                <Instagram />
              </IconContainer>
            </Link>
            <Link as='a' href='https://www.facebook.com/Distribuciones-Santa-Cruz-SA-de-CV-402912076567276' target='_blank'>
              <IconContainer size='medium'>
                <Facebook />
              </IconContainer>
            </Link>
          </div>
          <Grid item xs={6} container justify={isMobile ? 'flex-start' : 'flex-end'} alignItems='center'>
            <ContactContainer style={{ marginRight: '10px' }}>
              <Text style={{ textAlign: 'right' }}>
                Contacto
              </Text>
              <TextNumber>
                664-902-0177
              </TextNumber>
            </ContactContainer>
            {!isMobile && (
              <Link to='/mi-carrito'>
                <IconContainer size='medium'>
                  <ShoppingCartOutlined />
                  {itemsOnCart && !!itemsOnCart.length && (
                    <Counter>
                      {itemsOnCart.length}
                    </Counter>
                  )}
                </IconContainer>
              </Link>
            )}
          </Grid>
        </First>
        <Parent id='parent'>
          <Second id='fixeddiv' fixed={isFixed} style={{ width: width }}>
            <MenuMobile open={isMenuOpen} onClose={event => MenuMobile(false)} />
            {!isMobile && (
              <Grid container item xs={5}>
                <LinksOnMenu item xs>
                  <Link to='/'>
                    <ButtonStyled fullWidth variant={pathname === '/' ? 'contained' : 'text'} color={pathname === '/' ? 'primary' : 'default'}>
                      Home
                    </ButtonStyled>
                  </Link>
                </LinksOnMenu>
                <LinksOnMenu item xs>
                  <Link to='/productos'>
                    <ButtonStyled fullWidth variant={pathname === '/productos' ? 'contained' : 'text'} color={pathname === '/productos' ? 'primary' : 'default'}>
                      PRODUCTOS
                    </ButtonStyled>
                  </Link>
                </LinksOnMenu>
                <LinksOnMenu item xs>
                  <Link to='/recetas'>
                    <ButtonStyled fullWidth variant={pathname === '/recetas' ? 'contained' : 'text'} color={pathname === '/recetas' ? 'primary' : 'default'}>
                      RECETAS
                    </ButtonStyled>
                  </Link>
                </LinksOnMenu>
              </Grid>
            )}
            {isMobile && (
              <LinksOnMenu style={{ width: '38px' }}>
                <Menu style={{ color: 'red' }} onClick={event => setMenu(val => !val)} />
              </LinksOnMenu>
            )}
            <LinksOnMenu item xs>
              <Link to='/'>
                <Logo src={logoSrc} fixed={isFixed} />
              </Link>
            </LinksOnMenu>
            {!isMobile && (
              <>
                <Grid item container xs={5}>
                  <LinksOnMenu item xs>
                    <Link to='/nosotros'>
                      <ButtonStyled fullWidth variant={pathname === '/nosotros' ? 'contained' : 'text'} color={pathname === '/nosotros' ? 'primary' : 'default'}>
                        NOSOTROS
                      </ButtonStyled>
                    </Link>
                  </LinksOnMenu>
                  <LinksOnMenu item xs>
                    <Link to='/contacto'>
                      <ButtonStyled fullWidth variant={pathname === '/contacto' ? 'contained' : 'text'} color={pathname === '/contacto' ? 'primary' : 'default'}>
                        CONTACTO
                      </ButtonStyled>
                    </Link>
                  </LinksOnMenu>
                  {(isFixed || isMobile) && (
                    <LinksOnMenu style={{ marginLeft: '10px' }}>
                      <Link to='/mi-carrito'>
                        <IconContainer size='medium' style={{ marginRight: isMobile ? '0px' : '15px' }}>
                          <ShoppingCartOutlined />
                          {itemsOnCart && !!itemsOnCart.length && (
                            <Counter>
                              {itemsOnCart.length}
                            </Counter>
                          )}
                        </IconContainer>
                      </Link>
                    </LinksOnMenu>
                  )}
                </Grid>
              </>
            )}
            {(isMobile) && (
              <LinksOnMenu style={{ marginLeft: '10px' }}>
                <Link to='/mi-carrito'>
                  <IconContainer size='medium' style={{ marginRight: isMobile ? '0px' : '15px' }}>
                    <ShoppingCartOutlined />
                    {itemsOnCart && !!itemsOnCart.length && (
                      <Counter>
                        {itemsOnCart.length}
                      </Counter>
                    )}
                  </IconContainer>
                </Link>
              </LinksOnMenu>
            )}

          </Second>
        </Parent>
      </Container>
    </HeaderStyled>
  )
}

export default Header
