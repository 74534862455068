import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toArray } from '../../../helpers/transformer'
import { fetchAll } from '../../../flux/items'
import paginate from '../../../helpers/paginate'
import View from './view'
import lastPosition from '../../../helpers/last_position'

const Home = props => {
  const [currentCategory, setCurrentCategory] = useState(null)
  const [currentSubFamily, setCurrentSubFamily] = useState(null)
  const [keywords, setKeywords] = useState('')
  const [renderedItems, setItemsRendered] = useState([])

  const dispatch = useDispatch()
  const { items, loading, limit } = useSelector(state => state.items)
  const arrayOfItems = toArray(items).filter(item => item.isActive)
  let itemsFiltered = arrayOfItems
  if (currentCategory) itemsFiltered = itemsFiltered.filter(item => item.category === currentCategory)
  if (currentSubFamily) itemsFiltered = itemsFiltered.filter(item => item.subFamily === currentSubFamily)
  if (keywords) { itemsFiltered = itemsFiltered.filter(item => item.title.toLowerCase().includes(keywords.toLowerCase())) }

  useEffect(() => {
    if (arrayOfItems.length < limit) {
      dispatch(fetchAll())
    }
  }, [])

  // paginate result
  const next = useMemo(() => { return paginate(itemsFiltered, 10) }, [currentCategory, keywords, loading, currentSubFamily])
  useEffect(() => {
    setItemsRendered(next())
  }, [currentCategory, keywords, loading, currentSubFamily])

  // pagination with scrolling
  useEffect(event => {
    const handleScroll = event => {
      const isInLastPosition = lastPosition(200)
      if (!loading && isInLastPosition) {
        setItemsRendered([...renderedItems, ...next()])
      }
    }
    window.addEventListener('scroll', handleScroll)
    return event => window.removeEventListener('scroll', handleScroll)
  }, [currentCategory, keywords, loading, currentSubFamily, renderedItems])

  const handleCategoryChange = value => {
    if (currentSubFamily) setCurrentSubFamily(null)
    if (keywords) setKeywords('')
    setCurrentCategory(value)
  }

  const handleKeywordChange = value => {
    if (currentSubFamily) setCurrentSubFamily(null)
    if (currentCategory) setCurrentCategory(null)
    setKeywords(value)
  }

  const handleSubfamilyChange = value => {
    if (keywords) setKeywords('')
    setCurrentSubFamily(value)
  }

  return (
    <View
      setKeywords={handleKeywordChange}
      setCurrentSubFamily={handleSubfamilyChange}
      setCurrentCategory={handleCategoryChange}
      keywords={keywords}
      currentSubFamily={currentSubFamily}
      currentCategory={currentCategory}
      items={itemsFiltered}
      itemsPaginated={renderedItems}
      loading={loading}
    />
  )
}

export default Home
