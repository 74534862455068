import React from 'react'
import styled from 'styled-components'
import { string, number, array } from 'prop-types'
import Picture from './picture'
import { Box, Grid, ButtonBase, useMediaQuery } from '@material-ui/core'
import currency from '../../helpers/currency'
import { useDispatch } from 'react-redux'
import { addItem } from '../../flux/cart'
import { setNotification } from '../../flux/notification'
import { Link } from 'react-router-dom'

const Button = styled(ButtonBase)`
  font-size: .8rem!important;
  padding: 15px 10px;
  border: 1px solid var(--front-gray);
  border-radius: 10px;
  color: gray;
  @media (max-width:1600px) {
    padding: 12px 8px;
  }
  
  @media (max-width:1400px) {
    padding: 8px 5px;
    font-size: .8rem!important;
  }
  @media (max-width:600px) {
    padding: 8px 4px;
    font-size: .5rem!important;
    border-radius: 5px;
  }
`

const Content = styled(Link)`
  border-radius: 8px;
  height: 100%;
  min-height: 150px;
  overflow: hidden;
  border: 1px solid var(--front-gray);
  text-decoration: none;
  display: block;
`
const Title = styled.h6`
  color: var(--front-red);
  font-size: 1.4rem;
  font-family: var(--front-font);
  margin: 0px;
  font-size: 1.4rem;
  @media (max-width:1600px) {
    font-size: 1.3rem;
  }
  @media (max-width:1400px) {
    font-size: 1.2rem;
  }
  @media (max-width:600px) {
    font-size: .8rem;
  }
`

const Price = styled.div`
  font-size: 1.3rem;
  color: var(--front-red-dark);
  font-family: var(--front-font);
  font-weight: bold;
  @media (max-width:1600px) {
    font-size: 1.2rem;
  }
  @media (max-width:1450px) {
    font-size: 1.1rem;
  }
  @media (max-width:600px) {
    font-size: .8rem;
  }
`

const Article = props => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const dispatch = useDispatch()

  const addToCart = event => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setNotification({
      type: 'success',
      message: `Se han agregado 1 ${props.title} a tu carrito`
    }))
    dispatch(addItem({ ...props, quantity: 1 }))
  }

  return (
    <Content to={`/producto/${props.id}`}>
      <Picture src={props.picture} />
      <Grid p={isMobile ? 1 : 2} component={Box} container>
        <Grid item xs={12}>
          <Title>{props.title}</Title>
        </Grid>
        <Grid item xs={12}>
          <Box pt={isMobile ? 1 : 2} pb={isMobile ? 1 : 2}>
            <Grid container justify='space-between' alignItems='center' spacing={isMobile ? 1 : 2}>
              <Grid item xs={5}>
                <Price>
                  ${currency.formatMoney(props.price)}
                </Price>
              </Grid>
              <Grid item xs={7} container justify='flex-end'>
                {!!props.colors && !!props.colors.length && (
                  <Button>
                    Ver opciones
                  </Button>
                )}
                {(!props.colors || !props.colors.length) && (
                  <Button onClick={addToCart}>
                    Agregar al carrito
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Content>
  )
}

Article.propTypes = {
  title: string,
  picture: string,
  price: number,
  id: string,
  colors: array
}

export default Article
