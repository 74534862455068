import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton as SkeletonBase } from '@material-ui/lab'

const Skeleton = props => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <SkeletonBase variant='rect' height={200} />
        <SkeletonBase variant='text' height={50} />
      </Grid>
    </Grid>
  )
}

export default Skeleton
