import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled, { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { ThemeProvider } from '@material-ui/core'
import Alert from './components/alert'
import Notification from './components/notification'
import mytheme from './themeMaterial'
import DefineListeners from './components/define_listeners'
import theme from './theme'
import Fonts from './fonts'
import './index.css'

// pages
import CreateItem from './admin/pages/create_item'
import Login from './admin/pages/login'
import MyArticles from './admin/pages/my_articles'
import Messages from './admin/pages/messages'
import Orders from './admin/pages/orders'
import Order from './admin/pages/order'
import CreateRecipe from './admin/pages/create_recipe'
import MyRecipes from './admin/pages/my-recipes'
import Shipping from './admin/pages/shippingCost'

// front
import Home from './front/pages/home'
import Products from './front/pages/productos'
import Product from './front/pages/product'
import MyCart from './front/pages/my_cart'
import Checkout from './front/pages/checkout'
import Item from './user/pages/item'
import Recipes from './front/pages/recipes'
import Recipe from './front/pages/recipe'
import About from './front/pages/about'
import Geting from './front/pages/getting'
import Success from './front/pages/success'

import Contact from './front/pages/contact'
import admin from './admin/hoc/admin'
import Search from './user/pages/search'
import Loading from './components/loading'
import NoticeOfPrivacy from './front/pages/notice_of_privacy'
import Terms from './front/pages/terms'
import Verify from './front/pages/verify'

function App () {
  return (
    <ThemeProvider theme={mytheme}>
      <ThemeProviderStyled theme={theme}>
        <DefineListeners>
          <Background>
            <Fonts />
            <Loading />
            <Notification />
            <Alert />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/recetas' component={Recipes} />
              <Route exact path='/receta/:id' component={Recipe} />
              <Route exact path='/productos' component={Products} />
              <Route exact path='/producto/:id' component={Product} />
              <Route exact path='/mi-carrito' component={MyCart} />
              <Route exact path='/create-recipe' component={CreateRecipe} />
              <Route exact path='/my-recipes' component={MyRecipes} />
              <Route exact path='/create-item' component={CreateItem} />
              <Route exact path='/nosotros' component={About} />
              <Route exact path='/contacto' component={Contact} />
              <Route exact path='/empleo' component={Geting} />
              <Route exact path='/shipping' component={Shipping} />
              <Route exact path='/aviso-de-privacidad' component={NoticeOfPrivacy} />
              <Route exact path='/terminos-y-condiciones' component={Terms} />

              <Route exact path='/my-articles' component={MyArticles} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/item/:id' component={Item} />

              <Route exact path='/checkout' component={Checkout} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/messages' component={Messages} />
              <Route exact path='/orders' component={Orders} />
              <Route exact path='/order/:period/:id' component={Order} />
              <Route exact path='/admin' component={admin(props => (<Redirect to='/my-articles' />))} />
              <Route exact path='/search' component={Search} />
              <Route exact path='/success' component={Success} />
              <Route exact path='/verify/:path' component={Verify} />
            </Switch>
          </Background>
        </DefineListeners>
      </ThemeProviderStyled>
    </ThemeProvider>
  )
}

export default App

const Background = styled('div')`
  background: #f0f2f5;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
`
