import React from 'react'
import styled from 'styled-components'
import { Container } from '../../components/main'
import { Section, Title } from './main'
import { Grid, Box as BoxMaterial } from '@material-ui/core'
import provider1Src from '../../../assets/provider1.PNG'
import provider2Src from '../../../assets/provider2.PNG'
import provider3Src from '../../../assets/provider3.PNG'
import provider4Src from '../../../assets/provider4.PNG'
import provider5Src from '../../../assets/provider5.PNG'

const Text = styled.div`
  font-size: 1.45em;
  color: gray;
  text-align: center;
`
const Box = styled.div`
  background: #ebebec;
  padding: 15px;
  border-radius: 10px;
  img {
    max-width: 100%;
    display: block;
    margin: auto;
  }
`
const Prividers = props => {
  return (
    <Container>
      <Section style={{ padding: 0 }}>
        <Title style={{ textAlign: 'center' }}>Proveedores</Title>
        <Text>Dedicados a la distribución de materia prima nacional y de importación</Text>
        <Grid container spacing={2} component={BoxMaterial} pt={5}>
          <Grid item xs={6} sm={4} md={3} lg>
            <Box>
              <img src={provider1Src} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg>
            <Box>
              <img src={provider2Src} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg>
            <Box>
              <img src={provider3Src} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg>
            <Box>
              <img src={provider4Src} lg />
            </Box>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg>
            <Box>
              <img src={provider5Src} />
            </Box>
          </Grid>
        </Grid>
      </Section>
    </Container>
  )
}

export default Prividers
