import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { oneOfType, string, array, object, node } from 'prop-types'

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#e90033'
    }
  }
})

const MyTheme = props => {
  return (
    <ThemeProvider theme={myTheme}>
      {props.children}
    </ThemeProvider>
  )
}

MyTheme.propTypes = {
  children: oneOfType([string, array, object, node])
}

export default MyTheme
