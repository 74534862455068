import createFlux from '../createFlux'
import areEquals from '../helpers/areEquals'

const initialState = []
const flux = createFlux('CART')

// actions
export const addItem = flux.createAction('ADD_ITEM', (state, payload) => {
  const includeInList = state.filter(item => areEquals(item, payload)).length > 0

  if (includeInList) {
    return state.map(item => {
      if (item.id === payload.id) {
        const newItem = { ...item }
        newItem.quantity = (item.quantity + payload.quantity)
        return newItem
      } else {
        return item
      }
    })
  } else {
    return [...state, payload]
  }
})

export const RemoveItem = flux.createAction('REMOVE_ITEM', (state, ObjectOfData) => {
  const newItems = state.filter(item => !areEquals(item, ObjectOfData))
  return newItems
})

export const updateQuantity = flux.createAction('UPDATE_QUANTITY', (state, data) => {
  const { quantity } = data
  return state.map(item => {
    if (areEquals(item, data)) {
      const itemMatched = { ...item }
      itemMatched.quantity = quantity
      return itemMatched
    }
    return item
  })
})

export const setInitialState = flux.createAction('SET_INITIAL_STATE', any => [])

export default flux.createReducer(initialState)
