import { companyName } from '../config'

/* eslint-disable camelcase */
const spei = (paraams) => {
  return `
  <!--banamex transfer table-->
  <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
       font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
    <tbody>
      <tr style="text-align: center;">

        <td height="200px" style="background-color: #ededed;
           border-radius: 15px;">
          <table cellspacing="0" cellpadding="0" width="100%"
            style="line-height:16px;
             font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
            <tbody>
              <tr>
                <td colspan="2" width="98px"
                  style="text-align: center;padding-top: 20px; padding-bottom: 30px;  color: #000; font-weight: bolder; vertical-align: middle;">
                  Transferencia desde BBVA </td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 10px;  padding-left: 40px; color: #e90033; font-weight: bold; vertical-align: middle;">
                  Número de Convenio CIE: </td>
                <td
                  style="text-align: left;padding-bottom: 10px;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${paraams.agreement}</td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 10px; padding-top: 20px; padding-left: 40px;  color: #e90033; font-weight: bold; vertical-align: middle;">
                  Referencia: </td>
                <td
                  style="text-align: left;padding-bottom: 10px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${paraams.name}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
      </tr> <!-- Vertical Spacer-->
    </tbody>
  </table>


  <!--other bank-->
  <table cellspacing="0" cellpadding="0" width="100%" style="line-height:16px;
       font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
    <tbody>
      <tr style="text-align: center;">

        <td height="200px" style="background-color: #ededed;
           border-radius: 15px;">
          <table cellspacing="0" cellpadding="0" width="100%"
            style="line-height:16px;
             font-size:16px; border-collapse:collapse; border-spacing:0; border:0; margin:0;">
            <tbody>
              <tr>
                <td colspan="2" width="98px"
                  style="text-align: center;padding-top: 20px; padding-bottom: 30px;  color: #000; font-weight: bolder; vertical-align: middle;">
                  Desde cualquier otro banco </td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 10px;  padding-left: 40px; color: #e90033; font-weight: bold; vertical-align: middle;">
                  Beneficiario: </td>
                <td
                  style="text-align: left;padding-bottom: 10px;padding-left: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${companyName}</td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 10px; padding-top: 20px; padding-left: 40px;  color: #e90033; font-weight: bold; vertical-align: middle;">
                  Banco destino: </td>
                <td
                  style="text-align: left;padding-bottom: 10px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  BBVA Bancomer</td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 30px; padding-top: 20px; padding-left: 40px;  color: #e90033; font-weight: bold; vertical-align: middle;">
                  Clabe: </td>
                <td
                  style="text-align: left;padding-bottom: 30px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${paraams.clabe}</td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 30px; padding-top: 20px; padding-left: 40px;  color: #e90033; font-weight: bold; vertical-align: middle;">
                  Concepto de Pago: </td>
                <td
                  style="text-align: left;padding-bottom: 30px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${paraams.name}</td>
              </tr>
              <tr>
                <td width="98px"
                  style="text-align: right;padding-bottom: 30px; padding-top: 20px; padding-left: 40px;  color: #e90033; font-weight: bold; vertical-align: middle;">
                  Referencia: </td>
                <td
                  style="text-align: left;padding-bottom: 30px;padding-left: 20px;padding-top: 20px;color: #000; font-weight:600;vertical-align: middle;">
                  ${paraams.agreement}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td style="font-size: 0; line-height: 0;" height="10px">&nbsp;</td>
      </tr> <!-- Vertical Spacer-->
    </tbody>
  </table>
  `
}

export default spei
