/**
 * get cfdi options
 * @param {Bolean} isMoral
 */
const cfdiOptions = (isMoral) => {
  if (isMoral) {
    return [
      { '(G01) Adquisición de mercancias': '(G01) Adquisición de mercancias' },
      { '(G02) Devoluciones, descuentos o bonificaciones': '(G02) Devoluciones, descuentos o bonificaciones' },
      { '(G03) Gastos en general': '(G03) Gastos en general' },
      { '(I01) Construcciones': '(I01) Construcciones' },
      { '(I02) Mobilario y equipo de oficina por inversiones': '(I02) Mobilario y equipo de oficina por inversiones' },
      { '(I03) Equipo de transporte': '(I03) Equipo de transporte' },
      { '(I04) Equipo de computo y accesorios': '(I04) Equipo de computo y accesorios' },
      { '(I05) Dados, troqueles, moldes, matrices y herramental': '(I05) Dados, troqueles, moldes, matrices y herramental' },
      { '(I06) Comunicaciones telefónicas': '(I06) Comunicaciones telefónicas' },
      { '(I07) Comunicaciones satelitales': '(I07) Comunicaciones satelitales' },
      { '(I08) Otra maquinaria y equipo': '(I08) Otra maquinaria y equipo' },
      { '(P01) Por definir': '(P01) Por definir' }
    ]
  } else {
    return [
      { '(G01) Adquisición de mercancias': '(G01) Adquisición de mercancias' },
      { '(G02) Devoluciones, descuentos o bonificaciones': '(G02) Devoluciones, descuentos o bonificaciones' },
      { '(G03) Gastos en general': '(G03) Gastos en general' },
      { '(I01) Construcciones': '(I01) Construcciones' },
      { '(I02) Mobilario y equipo de oficina por inversiones': '(I02) Mobilario y equipo de oficina por inversiones' },
      { '(I03) Equipo de transporte': '(I03) Equipo de transporte' },
      { '(I04) Equipo de computo y accesorios': '(I04) Equipo de computo y accesorios' },
      { '(I05) Dados, troqueles, moldes, matrices y herramental': '(I05) Dados, troqueles, moldes, matrices y herramental' },
      { '(I06) Comunicaciones telefónicas': '(I06) Comunicaciones telefónicas' },
      { '(I07) Comunicaciones satelitales': '(I07) Comunicaciones satelitales' },
      { '(I08) Otra maquinaria y equipo': '(I08) Otra maquinaria y equipo' },
      { '(D01) Honorarios médicos, dentales y gastos hospitalarios.': '(D01) Honorarios médicos, dentales y gastos hospitalarios.' },
      { '(D02) Gastos médicos por incapacidad o discapacidad': '(D02) Gastos médicos por incapacidad o discapacidad' },
      { '(D03) Gastos funerales.': '(D03) Gastos funerales.' },
      { '(D04) Donativos.': '(D04) Donativos.' },
      { '(D05) Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).': '(D05) Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
      { '(D06) Aportaciones voluntarias al SAR.': '(D06) Aportaciones voluntarias al SAR.' },
      { '(D07) Primas por seguros de gastos médicos.': '(D07) Primas por seguros de gastos médicos.' },
      { '(D08) Gastos de transportación escolar obligatoria.': '(D08) Gastos de transportación escolar obligatoria.' },
      { '(D09) Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.': '(D09) Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
      { '(D10) Pagos por servicios educativos (colegiaturas)': '(D10) Pagos por servicios educativos (colegiaturas)' },
      { '(P01) Por definir': '(P01) Por definir' }
    ]
  }
}

export default cfdiOptions
