export const methodPayEs = {
  card: 'Tarjeta',
  paypal: 'Paypal',
  cash: 'Efectivo',
  spei: 'Transferencia eléctronica'
}

export const statusPayEs = {
  payed: 'Pagado',
  pending: 'Pendiente',
  sent: 'Enviado',
  cancelled: 'Cancelado'
}

export const categories = {
  bakery: 'Panadería',
  cake_shop: 'Pastelería',
  machinery: 'Maquinaria'
}

export const recipeCategories = {
  salty: 'Salados',
  sweets: 'Dulces'
}

export const subFamilies = {
  yeastsAndImprovers: 'Levaduras y mejorantes',
  fillersAndConcentrates: 'Rellenos y concentrados',
  utensils: 'Utensilios',
  flours: 'Harinas',
  decor: 'Decoración',
  domesAndCardboards: 'Domos y Cartones',
  chocolatier: 'Chocolateria',
  sugarsAndSweeteners: 'Azucares y endulzantes',
  avios: 'Avíos',
  other: 'Otro'
}

export const categoriesWithSubFamilies = {
  bakery: ['avios', 'sugarsAndSweeteners', 'chocolatier', 'decor', 'flours', 'yeastsAndImprovers', 'fillersAndConcentrates', 'utensils'],
  cake_shop: ['avios', 'sugarsAndSweeteners', 'domesAndCardboards', 'chocolatier', 'decor', 'flours', 'yeastsAndImprovers', 'fillersAndConcentrates', 'utensils'],
  machinery: ['other']
}
