/* eslint-disable react/jsx-handler-names */
import React from 'react'
import styled from 'styled-components'
import InputBase from '../../../user/pages/checkout/input'
import propTypes from 'prop-types'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Alert as AlertBase } from '@material-ui/lab'
import { Button as ButtonBase } from '../../components/main'

const Form = props => {
  const isMd = useMediaQuery('(max-width:700px)')

  return (
    <>
      <Wrapper>
        <Content container spacing={isMd ? 0 : 3}>
          {props.errorMessage && (
            <Grid item xs={12}>
              <Alert severity='error'>{props.errorMessage}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Input
              errors={props.errors}
              handleRemoveErrors={props.handleRemoveErrors}
              placeholder='Nombre'
              data={props.data}
              handleChange={props.handleChange}
              name='name'
              max={30}
              maxLength={30}
            />
          </Grid>
          <GridSpacing item xs={12} sm={6}>
            <Input
              maxLength={50}
              errors={props.errors}
              handleRemoveErrors={props.handleRemoveErrors}
              placeholder='Correo electrónico'
              data={props.data}
              handleChange={props.handleChange}
              name='email'
            />
          </GridSpacing>
          <Grid item xs={12} sm={6}>
            <Input
              type='text'
              filter='number'
              maxLength={10}
              errors={props.errors}
              handleRemoveErrors={props.handleRemoveErrors}
              placeholder='Tel./cel.'
              data={props.data}
              handleChange={props.handleChange}
              name='number'
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              style={{ minHeight: '100%' }}
              data={props.data}
              handleChange={props.handleChange}
              name='content'
              errors={props.errors}
              handleRemoveErrors={props.handleRemoveErrors}
              placeholder='Comentario'
              multiline
              maxLength={240}
              rows={10}
            />
          </Grid>

        </Content>
        <ButtonContainer>
          <Button onClick={props.handleSend} $primary>Enviar</Button>
        </ButtonContainer>
      </Wrapper>
    </>
  )
}

Form.propTypes = {
  data: propTypes.object,
  handleChange: propTypes.func,
  handleSend: propTypes.func,
  errors: propTypes.array,
  handleRemoveErrors: propTypes.func,
  errorMessage: propTypes.string
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`
const Wrapper = styled.div`
  padding: 40px;
  background: #f2f2f2;
  border-radius: 10px;
  @media screen and (max-width:960px) {
    padding: 20px;
  }
`
const Content = styled(Grid)``

const Input = styled(InputBase)`
  margin-bottom: 10px;
  background: var(--user-gray-light);
  background: #fff;
  @media screen and (max-width:600px) {
    margin-bottom: 10px!important;
  }
  fieldset {
    border: none;
  }
  .Mui-error {
    border: 1px solid red!important;
  }
  :last-of-type {
    margin-bottom: 0px;
  }
`
const GridSpacing = styled(Grid)`
  @media screen and (min-width:960px) {
    padding-right: 10px;
  }
`

const Button = styled(ButtonBase)`
  width: 160px;
`
const Alert = styled(AlertBase)`
  margin-bottom: 15px;
`
export default Form
