import { createGlobalStyle } from 'styled-components'

import bold from './RecoletaAlt-Bold.woff'
import Medium from './RecoletaAlt-Medium.woff'

export default createGlobalStyle`
  @font-face {
    font-family: "recolecta";
    src: url(${Medium});
    font-weight: normal;
  }

  @font-face {
    font-family: "recolecta";
    src: url(${bold});
    font-weight: bold;
  }
`
