import React from 'react'
import styled from 'styled-components'
import propTypes, { string } from 'prop-types'
import lisrx from '../../../assets/li.png'

const Summary = (props) => {
  if (props.currentStep !== 2) return null

  return (
    <Container>
      <Title>{props.title}</Title>
      <Picture src={typeof props.picture === 'string' ? props.picture : props.picture.preview} />
      <Description>
        <SubTitle>Descripción</SubTitle>
        <div dangerouslySetInnerHTML={{ __html: props.process }} />
      </Description>
      <Description>
        <SubTitle style={{ marginBottom: '10px' }}>Ingredientes</SubTitle>
        {props.ingredients && props.ingredients.map((ing, key) => ing.url && ing.url.length
          ? (<Item key={key}><Icon src={lisrx} /><IngredientLink href={ing.url} target='_blank'>{ing.title}</IngredientLink></Item>)
          : (<Item key={key}><Icon src={lisrx} /><Ingredient>{ing.title}</Ingredient></Item>)
        )}
      </Description>
    </Container>
  )
}

Summary.propTypes = {
  currentStep: propTypes.number,
  title: propTypes.string,
  process: propTypes.string,
  picture: propTypes.oneOfType([propTypes.object, propTypes.string]),
  ingredients: string
}
const Icon = styled.img`
  margin-right: 10px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 1em;
`
const IngredientLink = styled.a`
  flex-grow: 1; 
  color: inherit;
  :hover {
    color: var(--front-red);
  }
`
const Ingredient = styled.span`
  color: inherit;
  flex-grow: 1; 
`
const Container = styled('div')`
  max-width: 500px;
  margin: auto;
`
const Title = styled('h2')`
  color: var(--main-blue-dark);
`
const Picture = styled('img')`
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin: auto;
  display: block;
`

const Description = styled('p')`
  color: var(--main-blue-dark);
  font-size: 1.2em;
`
const SubTitle = styled.h4`
  margin: 0px;
  padding: 0px;
`

export default Summary
