import React, { useEffect } from 'react'
import { Container } from '../../components/main'
import Layout from '../../components/layout'
import { Body } from './styled'
import Skeleton from './skeleton'
import { bool, array, string, func } from 'prop-types'
import { Grid, useMediaQuery, Box, Button } from '@material-ui/core'
import Article from './item'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import styled from 'styled-components'

const outlinedStyled = {
  color: '#ff00388a',
  border: '1px solid transparent',
  background: '#fff'
}

const ButtonStylled = styled(Button)`
  ${props => props.variant === 'outlined' ? outlinedStyled : {}}
  font-weight: bold;
  @media (max-width:500px) {
    font-size: .8em;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  @media screen and (max-width:1300px) {
    margin: 30px 0px;
  }
  @media screen and (max-width:900px) {
    margin: 25px 0px;
  }
`

const Line = styled.div`
  flex-grow: 1;
  background: #ebe6da;
  height: 5px;
  @media screen and (max-width:900px) {
    height: 3px;
  }
`
const Title = styled.div`
  font-size: 3em;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  padding: 0px 20px;
  @media screen and (max-width:1300px) {
    font-size: 2.5em;
  }
  @media screen and (max-width:900px) {
    font-size: 1.7em;
  }
`

const View = props => {
  const isMobile = useMediaQuery('(max-width:600px)')
  // scroll and focus content
  useEffect(() => {
    setTimeout(() => {
      try {
        const distance = document.getElementById('recipe').offsetTop
        window.scrollTo(0, distance - 160)
      } catch (error) {
        console.log('error')
      }
    }, 0)
  }, [])

  return (
    <Layout>
      <Cover />
      <CoverSecondary
        header='RECETAS'
        content={<div style={{ fontSize: '.9em' }}>LO ÚLTIMO DE DISTRIBUCIONES SANTA CRUZ</div>}
        controls={
          <Box mt={isMobile ? 2 : 5}>
            <ButtonStylled onClick={() => props.setCurrentCategory('sweets')} style={{ textTransform: 'none', marginRight: '20px' }} size={isMobile ? 'medium' : 'large'} variant={props.currentCategory === 'sweets' ? 'contained' : 'outlined'} color='primary'>Dulces</ButtonStylled>
            <ButtonStylled onClick={() => props.setCurrentCategory('salty')} style={{ textTransform: 'none' }} size={isMobile ? 'medium' : 'large'} variant={props.currentCategory === 'salty' ? 'contained' : 'outlined'} color='primary'>Salados</ButtonStylled>
          </Box>
        }
      />
      <Container id='recipe'>
        {props.loading && (<Skeleton />)}
        {!props.loading && (

          <Body>
            <TitleContainer>
              <Line />
              <Title>{props.currentCategory === 'sweets' ? 'Dulces' : 'Salados'}</Title>
              <Line />
            </TitleContainer>
            <Grid container spacing={isMobile ? 1 : 3}>
              {!!props.itemsPaginated.length && props.itemsPaginated.map(item => (
                <Grid key={item.id} item xs={6} lg={4}>
                  <Article {...item} />
                </Grid>
              ))}
            </Grid>
          </Body>

        )}
      </Container>
    </Layout>
  )
}

View.propTypes = {
  loading: bool,
  items: array,
  currentCategory: string,
  itemsPaginated: array,
  setCurrentCategory: func
}

export default View
