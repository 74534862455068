/* eslint-disable no-unreachable */
import { SelectStyled, Input, Row, Container } from './styled'
import React from 'react'
import { Alert } from '@material-ui/lab'
import { func, object, oneOfType, string, number } from 'prop-types'
import Icons from '../icons'
import styled from 'styled-components'
import logoSrc from '../../../../../assets/openpay/logo.png'
import cardSrc from '../../../../../assets/openpay/card.png'
import securePaySrc from '../../../../../assets/openpay/securepay.png'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import { Button } from '../../../../components/main'

var months = [
  { Enero: '01' },
  { Febrero: '02' },
  { Marzo: '03' },
  { Abril: '04' },
  { Mayo: '05' },
  { Junio: '06' },
  { Julio: '07' },
  { Agosto: '08' },
  { Septiembre: '09' },
  { Octubre: '10' },
  { Noviembre: '11' },
  { Diciembre: '12' }
]

var years = [
  { 20: 20 },
  { 21: 21 },
  { 22: 22 },
  { 23: 23 },
  { 24: 24 },
  { 25: 25 },
  { 26: 26 },
  { 27: 27 },
  { 28: 28 },
  { 29: 29 },
  { 30: 30 },
  { 31: 31 },
  { 32: 32 },
  { 33: 33 },
  { 34: 34 },
  { 35: 35 },
  { 36: 36 },
  { 37: 37 },
  { 38: 38 },
  { 39: 39 },
  { 40: 40 }
]

const Logo = styled.img`
  display: block;
  margin: auto;
  @media screen and (max-width:1300px){
    width: 200px;
  }
`
const ButtonStyled = styled(Button)`
  width: 100%;
`
const Title = styled.div`
  text-align: center;
  color: var(--front-red-dark);
  font-family: var(--front-font);
  font-size: 2.3em;
  font-weight: bold;
  margin-top: 20px;
`

const InputsContainer = styled.form`
  padding: 30px;
  border-radius: 10px;
  background: #f2f2f2;
  @media screen and (max-width:1300px){
    padding: 15px;
  }
`
const Describe = styled.div`
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 10px;
  white-space: nowrap;
`
const CardIcon = styled.img`
  width: 75px;
  margin-right: 15px;
  @media screen and (max-width:1100px){
    width: 40%;
    margin-right: 8px;
    max-width: 70px;
  }
`
const SecurePayCOntent = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 70px;
    margin-right: 15px;
  }
  @media screen and (max-width: 599px) {
    justify-content: center;
    text-align: center;
  }
`
const CreditCard = props => {
  const isMd = useMediaQuery('(max-width:1300px)')
  const isSm = useMediaQuery('(max-width:960px)')

  return (
    <Container>
      <Logo src={logoSrc} />
      <Title>Pagos con Tarjeta de crédito o débito</Title>
      <Box pt={3} pb={3}>
        <Icons type='card' />
      </Box>
      <InputsContainer onSubmit={event => event.preventDefault()} id='credit_card_form'>
        <Row>
          {!!props.errorMessage && (
            <Alert severity='error'>{props.errorMessage}</Alert>
          )}
        </Row>
        <Grid container spacing={isMd ? 3 : 5}>
          <Grid item xs={12} md={6}>
            <Describe>Nombre del titular</Describe>
            <Input
              {...props}
              maxlength={80}
              name='card_name'
              placeholder='Como aparece en la tarjeta'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Describe>Número de tarjeta</Describe>
            <Input
              {...props}
              maxlength={16}
              name='card_number'
              filter='number'
              placeholder='Ingrese los 16 dígitos'
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Describe>Fecha de expedición</Describe>
            <SelectStyled
              {...props}
              type='select'
              name='card_month'
              options={months}
              placeholder='Mes'
              onClick={props.onFocus}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Describe style={{ textAlign: 'right' }}><span style={{ color: '#f2f2f2' }}>Fn</span></Describe>
            <SelectStyled
              {...props}
              type='select'
              name='card_year'
              options={years}
              placeholder='Año'
              onClick={props.onFocus}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Describe>Código de seguridad</Describe>
            <Input
              {...props}
              maxlength={4}
              placeholder='CVV'
              name='card_cvv'
              type='password'
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Describe style={{ textAlign: 'right' }}><span style={{ color: '#f2f2f2' }}>Fn</span></Describe>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CardIcon src={cardSrc} />
              <CardIcon src={cardSrc} />
            </div>
          </Grid>
        </Grid>
        <input type='hidden' name='card_code' />
      </InputsContainer>
      <Box mt={5}>
        <Grid container spacing={isMd ? 2 : 5} direction={isSm ? 'column-reverse' : 'row'}>
          <Grid item xs={12} md={6} container>
            <Grid item xs={12} sm={5} style={{ textAlign: isSm ? 'center' : 'left' }}>
              Transaccion realizada vía:
              <Logo src={logoSrc} style={{ width: '60%', margin: isSm ? 'auto' : '0px' }} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <SecurePayCOntent>
                <img src={securePaySrc} />
                Tu pago se realiza de forma segura con encriptación de 256 bits
              </SecurePayCOntent>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} />
          <Grid item xs={12} sm={6} md={3}>
            <ButtonStyled style={{ textTransform: 'none' }} fullWidth onClick={props.onPayWidthCard} $primary>Pagar</ButtonStyled>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

CreditCard.propTypes = {
  saveOperation: func,
  startProcess: func,
  endProcess: func,
  goToStep: func,
  data: object,
  totalPrice: oneOfType([string, number]),
  errorMessage: string,
  onFocus: func,
  onPayWidthCard: func
}

export default CreditCard
