import React from 'react'
import styled from 'styled-components'
import { Collapse, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { bool } from 'prop-types'

const Content = styled.div`
  background: var(--front-red);
  width: 80vw;
  min-height: 200px;
  border-radius: 8px;
  padding: 10px;
`

const ButtonStled = styled(Button)`
  font-family: var(--front-font);
  font-weight: bold;
  font-size: 1em;
`

const MenuMobile = props => {
  return (
    <Collapse in={props.open} style={{ position: 'absolute', top: '65px' }}>
      <Content>
        <ButtonStled fullWidth>
          <Link style={{ color: '#fff' }} to='/'>HOME</Link>
        </ButtonStled>
        <ButtonStled fullWidth>
          <Link style={{ color: '#fff' }} to='/productos'>PRODUCTOS</Link>
        </ButtonStled>
        <ButtonStled fullWidth>
          <Link style={{ color: '#fff' }} to='/recetas'>RECETAS</Link>
        </ButtonStled>
        <ButtonStled fullWidth>
          <Link style={{ color: '#fff' }} to='/nosotros'>NOSOTROS</Link>
        </ButtonStled>
        <ButtonStled fullWidth>
          <Link style={{ color: '#fff' }} to='/contacto'>CONTACTO</Link>
        </ButtonStled>
      </Content>
    </Collapse>
  )
}

MenuMobile.propTypes = {
  open: bool
}

export default MenuMobile
