import React, { useEffect } from 'react'
import Layout from '../../components/layout'
import { useSelector, useDispatch } from 'react-redux'
import { FlexEnd, Summary, Total, Container, ButtonContainer, ButtonStyled, IconShipping } from './styled'
import Item from './compoenents/item'
import { updateQuantity, RemoveItem } from '../../../flux/cart'
import { setAlert } from '../../../flux/alert'
import Currency from '../../../helpers/currency'
import Button from '../../../components/inputs/Button'
import sumePrice from '../../../helpers/sumPrice'
import Empty from '../../components/empty'
import { taxPorcent } from '../../../config'
import Cover from './cover'
import ShippingIcon from '../../../assets/shipping.png'

const MyCart = props => {
  const myCart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const subtotal = sumePrice(myCart)
  const shipping = 'sin calcular'
  const tax = Math.ceil((subtotal) * (taxPorcent / 100))
  const total = subtotal + tax

  const handleRemoveItem = data => {
    dispatch(
      setAlert({
        title: 'Quitar producto del carrito',
        message: '¿Estas seguro de quitar este producto de tu carrito?',
        action: RemoveItem(data)
      })
    )
  }

  const handleChangeQuantity = (item, quantity) => {
    if (quantity === 0) return false
    dispatch(updateQuantity({
      ...item,
      quantity
    }))
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Cover />
      {!!myCart.length && (
        <Container>
          <Item header />
          {myCart.map(item => (
            <Item
              handleRemoveItem={handleRemoveItem}
              handleChangeQuantity={handleChangeQuantity}
              key={item.id}
              {...item}
            />
          ))}
          <FlexEnd>
            <Summary>
              <div>
                <span>Subtotal:</span>
                $ {Currency.formatMoney(subtotal)}
              </div>
              <div>
                <span>Envio:</span>
                {shipping}
              </div>
              {!!tax && (
                <div>
                  <span>IVA:</span>
                  $ {Currency.formatMoney(tax)}
                </div>
              )}
            </Summary>
          </FlexEnd>
          <FlexEnd>
            <Total>
              <div>
                <span style={{ marginRight: '7px' }}>Total:</span>
                ${Currency.formatMoney(total)}
                <span style={{ marginLeft: '7px' }}>MXN</span>
              </div>
            </Total>
          </FlexEnd>
          <ButtonContainer>
            <ButtonStyled style={{ marginRight: '15px', color: '#e9003396' }} to='/productos' as={Button} variant='outlined'>Seguir comprando</ButtonStyled>
            <ButtonStyled to='/checkout' as={Button} variant='contained'><IconShipping src={ShippingIcon} /> Continuar a envíos</ButtonStyled>
          </ButtonContainer>
        </Container>
      )}
      {!myCart.length && (
        <Container>
          <Empty />
        </Container>
      )}
    </Layout>
  )
}

export default MyCart
