/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout_admin'
import Title from '../../components/page_title'
import useObjectState from '../../../hooks/useObjectState'
import { getList, deleteOne } from '../../../modules/shipping'
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Box, IconButton, Hidden } from '@material-ui/core'
import { setAlert } from '../../../flux/alert'
import { useDispatch } from 'react-redux'
import Create from './create'
import currency from '../../../helpers/currency'
import { Delete, Edit } from '@material-ui/icons'
import styled from 'styled-components'

const TableContainerStyled = styled(TableContainer)`
    padding: 5px;
    .MuiTableCell-root {
      @media screen and (max-width:600px) {
        padding: 15px 2px;
      }
    }
  
`

const RowComponent = (props) => {
  return (
    <TableRow key={props.cp}>
      <TableCell> {props.cp} </TableCell>
      <TableCell>${currency.formatMoney(props.cost)}</TableCell>
      <Hidden xsDown>
        <TableCell>{props.comment}</TableCell>
      </Hidden>
      <TableCell>
        <IconButton onClick={event => props.onEdit(props)} style={{ textTransform: 'none' }} variant='contained' color='secondary' size='small'>
          <Edit />
        </IconButton>
        <IconButton onClick={event => props.onDelete(props.cp)} style={{ textTransform: 'none' }} variant='contained' color='secondary' size='small'>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const ShippingCost = props => {
  const [state, setState] = useObjectState({ loading: true, items: [] })
  const [isDialogActive, setDialog] = useState(false)
  const [edit, setEdit] = useState(null)

  const dispatch = useDispatch()

  const handleFetch = async () => {
    const items = await getList()
    setState({ loaidng: false, items })
  }

  useEffect(() => {
    handleFetch()
  }, [])

  const handleDelete = (id) => {
    dispatch(setAlert({
      title: '¿Seguro que quieres borrar este código postal?',
      message: 'Una vez realizada esta acción no podras recurar la informacion',
      action: async () => {
        await deleteOne(id)
        await handleFetch()
      },
      textAction: 'Eliminar'
    }))
  }

  const handleEdit = (data) => {
    setEdit(data)
    setDialog(true)
  }

  return (
    <Layout title='Costos de envío'>
      <Create
        open={isDialogActive}
        onClose={event => {
          setDialog(false)
          setEdit(null)
        }}
        onSuccess={() => handleFetch()}
        data={edit}
      />

      <Container maxWidth='md' disableGutters>
        <Title>Costos de envío</Title>
        <Box mt={2} mb={2}>
          <Button onClick={event => setDialog(true)} color='primary' variant='contained'>Agregar nuevo</Button>
        </Box>
        <TableContainerStyled component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código postal</TableCell>
                <TableCell>Costo</TableCell>
                <Hidden xsDown>
                  <TableCell>Comentario</TableCell>
                </Hidden>
                <TableCell>Opiones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.items.map(row => (<RowComponent key={row.cp} {...row} onDelete={handleDelete} onEdit={handleEdit} />))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      </Container>
    </Layout>
  )
}

export default ShippingCost
