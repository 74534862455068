import { db, storageRef } from './firebase'
import snapshotParser from '../helpers/snapshotparser'
import { recipeCategories } from '../constants'

const recipe = {
  /**
   * @param id::string
   * @return data::object || false
   * getSpecific article
   */
  async get (id) {
    try {
      const snap = await db.doc(`recipes/${id}`).get()
      const data = snapshotParser(snap)
      return data
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },

  /**
   * @param id::strinf
   * @return true||false::bool
   * set disable on status
   */
  async setInactive (id) {
    await db.doc(`recipes/${id}`).update({
      isActive: false
    })
  },

  /**
   * @param id::strinf
   * @return true||false::bool
   * set active on status
   */
  async setActive (id) {
    await db.doc(`recipes/${id}`).update({
      isActive: true
    })
  },

  /**
 * @param id::strinf
 * @return true||false::bool
 * delete item on database
 */
  async delete (id) {
    try {
      await db.doc(`recipes/${id}`).delete()
      return true
    } catch (error) {
      console.error('error_Descript:', error)
      return false
    }
  },
  /**
   * @params data::object
   * @return id created or false
   * create a new item
  **/
  async upload ({ category = null, title = '', picture = '', pictures = [], time = null, process = '', ingredients = [] }) {
    try {
      const { id } = await db.collection('recipes').add({ date: new Date(), category, isActive: true, title, picture, pictures, time, process, ingredients })
      await db.collection('recipes').doc(id).update({ id })
      return id
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },
  /**
   * @params id:int, data:object
   * @return true or false
   * update a data of item
   */
  async update (id, data) {
    try {
      data.date = new Date()
      await db.doc(`recipes/${id}`).update(data)
      return true
    } catch (error) {
      console.error('error_description:', error)
      return false
    }
  },
  /**
   * @params id_article::int and photo::file and OPTIONAL::bool
   * @return url or false
   */
  async uploadPicture (id, picture, primary) {
    var name = picture.name
    if (primary) name = `primary_${name}`
    const nameEncoded = encodeURIComponent(name)
    const newNameEncoded = encodeURIComponent(`thumb@1100_${name}`)
    const { ref } = await storageRef.child(`recipe_${id}/${name}`).put(picture)
    const url = await ref.getDownloadURL()
    var urlTransformed = url.replace(nameEncoded, newNameEncoded)
    urlTransformed = urlTransformed.split('&token')[0]
    return urlTransformed
  },
  /**
   * @params lastItem::snap, Limit::int
   * @return Object of = Items::array, lastItem::snap
   */
  async getList (lastItem = null, limit = null) {
    try {
      var query = db.collection('recipes').orderBy('date', 'desc')
      if (limit) query = query.limit(limit)
      if (lastItem) query = query.startAfter(lastItem)
      const snapshot = await query.get()
      const items = snapshotParser(snapshot)
      return { items, last: snapshot.docs[snapshot.docs.length - 1] }
    } catch (error) {
      console.error('error_description:', error)
      return { items: [], last: null }
    }
  },

  /**
   * @param urlPicture::string and idarticle::string
   * @return true or false :: bool
   */
  async deletePicture (id, url) {
    try {
      const urlDecoded = decodeURIComponent(url)
      var urlToDelete = urlDecoded.split(id)[1]
      urlToDelete = urlToDelete.split('?')[0]
      urlToDelete = `recipe_${id}/${urlToDelete}`
      await storageRef.child(urlToDelete).delete()
      return true
    } catch (error) {
      console.log('error_description:', error)
      return false
    }
  }

}

const makeFakeData = async () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
  const desc = 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for \'lorem ipsum\' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).'
  const ingredients = [
    { title: 'azucar', url: 'https://google.com' },
    { title: 'mas azucar', url: 'https://google.com' },
    { title: 'mas azucar pero si link', url: '' },
    { title: 'chocolate', url: 'https://google.com' },
    { title: 'mucha paciencia', url: null }
  ]
  const images = {
    salty: 'https://cdn2.cocinadelirante.com/sites/default/files/styles/gallerie/public/images/2018/08/recetas-facilisimas-con-yogurt-dulces-y-saladas-riquisimas.jpg',
    sweets: 'https://www.hogarmania.com/archivos/201609/brigadeiros-brasil-receta-trufas-chocolate-3-668x400x80xX.jpg'
  }
  const keycategories = Object.keys(recipeCategories)
  for (const key of keycategories) {
    for (const number of items) {
      const title = `prueba de titulos para ${recipeCategories[key]} -- ${number}`
      const id = await recipe.upload({ category: key, title, process: desc, picture: images[key], pictures: [], ingredients, time: '1 hora' })
      console.log(id)
    }
  }
}

export default recipe

window.create = makeFakeData
