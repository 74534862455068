/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { Container } from '../components/main'
import { Grid, Button, Box, useMediaQuery, Hidden, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Facebook, Instagram } from '@material-ui/icons'
import logosrc from '../../assets/logo.png'

const FooterStyled = styled.footer`
  background: var(--front-red);
  padding: 1px;
`
const ShadowRoundedContainer = styled.div`
  height: 170px;
  overflow: hidden;
  margin-bottom: -100px;
  @media screen and (max-width:450px) {
    height: 140px;
  }
`
const ShadowRoundedContainerYellow = styled(ShadowRoundedContainer)`
  height: 110px!important;
`
const ShadowRounded = styled.div`
  background: #e90033;
  height: 400px;
  width: 150%;
  border-radius: 100%;
  padding: 1px;
  margin-left: -25%;
  @media screen and (max-width:450px) {
    height: 260px;
  }
`
const ShadowRoundedYellow = styled(ShadowRounded)`
  background: #f9b200;
`
const ButtonStyled = styled(Button)`
  display: block;
  font-family: var(--front-font);
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none!important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  @media (max-width:1400px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.1rem;
  }
  @media (max-width:1080px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: .9rem;
  }
`

const IconContainer = styled(IconButton)`
  position: relative;
  background: #f9b200;
  margin-right: 15px;
  svg {
    color: red;
    @media screen and (max-width:700px) {
      font-size:1rem;
    }
  }
  &:hover {
    background: #f9b200;
  }
`
const Text = styled.div`
  color: #f9b200;
  font-size: 1.2rem;
  @media screen and (max-width:700px) {
    font-size:.8rem;
  }
`

const TextNumber = styled.div`
  font-family: var(--front-font);
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  @media screen and (max-width:700px) {
    font-size: .7rem;
  }
`

const GridItemMenu = styled(Grid)`
  @media screen and (max-width: 700px) {
    width: 100%;
    flex-basis: auto;
  }
`
const Picture = styled.img`
  z-index: 10;
  width: 120px;
  height: 120px;
  @media screen and (max-width:450px) {
    width: 80px;
    height: 80px;
  }
  @media screen and (max-width:400px) {
    width: 60px;
    height: 60px;
  }
`

const PictureCOntainer = styled(Grid)`
  margin-bottom: -60px;
  @media screen and (max-width:450px) {
    margin-bottom: -40px;;
  }
`

const Footer = props => {
  const isMobile = useMediaQuery('(max-width:700px)')

  return (
    <>
      <PictureCOntainer container justify='center'>
        <Picture src={logosrc} />
      </PictureCOntainer>
      <ShadowRoundedContainerYellow>
        <ShadowRoundedYellow />
      </ShadowRoundedContainerYellow>
      <ShadowRoundedContainer>
        <ShadowRounded style={{ transform: isMobile ? 'rotate(-3deg)' : 'rotate(-0.6deg)' }} />
      </ShadowRoundedContainer>
      <FooterStyled>
        <Container>
          <Box pt={3} pb={3}>
            <Grid container justify='space-between' alignItems='center'>
              <GridItemMenu item container alignItems='center' xs><Link style={{ margin: 'auto' }} to='/'><ButtonStyled>Home</ButtonStyled></Link></GridItemMenu>
              <GridItemMenu item container alignItems='center' xs><Link style={{ margin: 'auto' }} to='/recetas'><ButtonStyled>Recetas</ButtonStyled></Link></GridItemMenu>
              <GridItemMenu item container alignItems='center' xs><Link style={{ margin: 'auto' }} to='/productos'><ButtonStyled>Productos</ButtonStyled></Link></GridItemMenu>
              <GridItemMenu item container alignItems='center' xs><Link style={{ margin: 'auto' }} to='/nosotros'><ButtonStyled>Nosotros</ButtonStyled></Link></GridItemMenu>

              <Hidden smUp>
                <GridItemMenu item container alignItems='center' xs={12} justify='center'>
                  <Box mt={2}>
                    <a href='https://www.facebook.com/Distribuciones-Santa-Cruz-SA-de-CV-402912076567276' target='_blank'>
                      <IconContainer size='medium' style={{ margin: '0px' }}>
                        <Facebook />
                      </IconContainer>
                    </a>
                    <Box component='span' padding='.5em' />
                    <a href='https://www.instagram.com/distribucionesantacruzmx/' target='_blank'>
                      <IconContainer size='medium' style={{ margin: '0px' }}>
                        <Instagram />
                      </IconContainer>
                    </a>
                  </Box>
                </GridItemMenu>
              </Hidden>
            </Grid>
          </Box>
        </Container>
      </FooterStyled>
      <div style={{ backgroundColor: '#cd002c' }}>
        <Container>
          <Grid container justify='space-between' wrap='nowrap'>
            <div style={{ width: isMobile ? 'auto' : '50%' }}>
              <Text style={{ fontWeight: 'bold' }}>
                Contacto
              </Text>
              <TextNumber>
                664-902-0177
              </TextNumber>
            </div>
            <Hidden smDown>
              <Box style={{ width: '30%', textAlign: 'center' }}>
                <a href='https://www.facebook.com/Distribuciones-Santa-Cruz-SA-de-CV-402912076567276' target='_blank'>
                  <IconContainer size='medium' style={{ margin: '0px' }}>
                    <Facebook />
                  </IconContainer>
                </a>
                <Box component='span' padding='.5em' />
                <a href='https://www.instagram.com/distribucionesantacruzmx/' target='_blank'>
                  <IconContainer size='medium' style={{ margin: '0px' }}>
                    <Instagram />
                  </IconContainer>
                </a>
              </Box>
            </Hidden>
            <div style={{ width: isMobile ? 'auto' : '50%' }}>
              <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>
                Escríbenos
              </Text>
              <TextNumber style={{ textAlign: 'right' }}>
                ventas@distribucionessantacruz.com
              </TextNumber>
            </div>
          </Grid>
        </Container>
      </div>
      <div style={{ backgroundColor: '#b60027' }}>
        <Container>
          <Box pt={1} pb={1}>
            <Grid container justify='space-between'>
              <Text style={{ color: '#fff' }}>
                2020, derechos reservados
              </Text>
              <Link to='/terminos-y-condiciones'>
                <Text style={{ color: '#fff' }}>
                  Terminos y condiciones
                </Text>
              </Link>
              <Link to='/aviso-de-privacidad'>
                <Text style={{ color: '#fff' }}>
                  Aviso de privacidad
                </Text>
              </Link>
            </Grid>
            <div>
              <Text style={{ color: '#fff' }}>Calle Alba num 4355 int 18, La Mesa, <Hidden xsDown><br /> </Hidden>Tijuana Baja California CP 22115</Text>
            </div>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default Footer
