import React from 'react'
import styled from 'styled-components'
import coverImage from '../../../assets/recipes.png'
import Header from '../../components/header'
import { string } from 'prop-types'
import wheatSrc from '../../../assets/wheat.png'

const Content = styled.section`
  padding: 1px;
  min-height: 55vh;
  background-image: url(${coverImage});
  background-size: cover;
  background-position: center;
  line-height: initial;
  @media screen and (max-width:700px) {
    min-height: 45vh;
  }
`
const ContentSecond = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding: 40px 0px;
  margin-top: 50px;
  @media screen and (max-width:520px) {
    margin-top: 0px;
  }
`

const Title = styled.h1`
  text-align: center;
  font-size: 5rem;
  font-family: var(--front-font);
  color: #fff;
  margin: 0px;
  line-height: 1em;
  @media screen and (max-width:1250px) {
    font-size: 4rem;
  }
  @media screen and (max-width:700px) {
    font-size: 3rem;
  }
  @media screen and (max-width:520px) {
    font-size: 2rem;
  }
`
const Picture = styled.img`
  width: 100px;
  margin-bottom: 20px;
  @media screen and (max-width:400px) {
    width: 70px;
  }
`
const HeaderText = styled.div`
  color: #fff;
  margin-bottom: 10px;
  font-size: 1.1rem;
  @media screen and (max-width:700px) {
    font-size: 1rem;
  }
  @media screen and (max-width:400px) {
    font-size: .7rem;
  }
`

const Cover = props => {
  return (
    <Content>
      <Header />
      <ContentSecond>
        <Picture src={wheatSrc} />
        <HeaderText>
          RECETAS
        </HeaderText>
        <Title>
          {props.title}
        </Title>
      </ContentSecond>
    </Content>
  )
}

Cover.propTypes = {
  title: string
}

export default Cover
