import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout'
import { Container } from '../../components/main'
import Cover from './cover'
import CoverSecondary from '../../components/coverSecondary'
import validateForm from '../../../helpers/validateform'
import useObjectState from '../../../hooks/useObjectState'
import Form from './form'
import message from '../../../modules/message'
import { CircularProgress, Grid, Paper, Box } from '@material-ui/core'
import fill from '../../../assets/fill_cover_secondary.png'
import Card from './card'

const stores = [
  { Title: 'TIJUANA', address: 'Calle Alba No. 4355 Int. 18, Las Brisas Norte, TIJUANA, BAJA CALIFORNIA, C.P. 22115,', tel: '(664) 686 07 66 / 686 00 16', email: 'tijuana@distribuicionessantacruz.com' },
  { Title: 'MEXICALI', address: 'Calle Calera No. 1462, Colonia Ex Ejido Zacatecas, MEXICALI, BAJA CALIFORNIA, C.P. 21090', tel: '(686) 565 55 35', email: 'mexicali@distribuicionessantacruz.com' },
  { Title: 'ENSENADA', address: 'Calle Belisario Domínguez, No.353-2 Esquina con Boulevard Venustiano Carranza, Colonia Aviación, ENSENADA, BAJA CALIFORNIA, C.P. 22800', tel: '(646) 177 11 26', email: 'ensenada@distribuicionessantacruz.com' },
  { Title: 'HERMOSILLO', address: 'Boulevard Agustín Gómez Del Campo No. 93, Colonia El Llano, HERMOSILLO, SONORA, C.P. 83210', tel: '(662) 260 43 35', email: 'hemosillo@distribuicionessantacruz.com' },
  { Title: 'MAGDALENA DE KINO', address: 'Avenida Niños Héroes No. 300, Col. Centro, Esquina Jesús Arellano, MAGDALENA DE KINO, C.P. 84160', tel: '(632) 322 70 56', email: 'magdalenadekino@distribuicionessantacruz.com' },
  { Title: 'GUADALAJARA', address: 'Calle 14 No. 2334, Zona Industrial, GUADALAJARA, JALISCO  C.P. 44940', tel: '(33) 3666-9378', email: 'guadalajara@distribuicionessantacruz.com' },
  { Title: 'LOS MOCHIS', address: 'Calle De La Industria #1057   Parque Ecológico Ind Los Mochis, Sinaloa C.P. 81254', tel: '(668) 812 92 88', email: 'losmochis@distribuicionessantacruz.com' },
  { Title: 'MONTERREY', address: 'Calle Puerto Mazatlán No. 240-10, Colonia La Fe, San Nicolás de los Garza,Nuevo León, C.P.  66477', tel: '(81) 8240 9910', email: 'monterrey@distribuicionessantacruz.com' },
  { Title: 'MÉXICO', address: 'Calle Bilbao No. 13, Esquina con Independencia, Col. Ejidos de Santa María De Tulpetlac ECATEPEC DE MORELOS, Estado de México, C.P. 55404', tel: '(55) 38740107', email: 'mexico@distribuicionessantacruz.com' },
  { Title: 'LA PAZ', address: 'Blvd. Francisco J. Mujica, Entre Blvd. Luis Donaldo Colosio Y Arroyo Col. Libertad, C.P. 23070, La Paz, B.C.S. (Frente A La Clínica #34 Imss)', tel: '(612) 146 15 67', email: 'lapaz@distribuicionessantacruz.com' },
  { Title: 'CIUDAD JUÁREZ', address: 'Calle Aguirre No. 6715-S, Entre Ave. Tecnológico Y Valentín Fuentes, Col.  Partido De La Fuente, Cd. Juárez, Chihuahua C.P. 32370', tel: '(656) 375 2642', email: 'ciudadjuarez@distribuicionessantacruz.com' },
  { Title: 'TUXTLA', address: 'Carretera Juan Crispin Km. 1.3 S/N, Bodega 2,  Col. Plan De Ayala, Tuxtla Gutierrez, Chiapas, C.P. 29020 ', tel: '(961) 294 0435', email: 'tuxtla@distribuicionessantacruz.com' },
  { Title: 'CULIACÁN', address: 'BOULEVARD DIAMANTE  No.1825, INT 27, COL. FOVISSSTE DIAMANTE CULIACÁN, SINALOA. ', tel: '(671) 750 10 96', email: 'culiacan@distribuicionessantacruz.com' },
  { Title: 'MAZATLÁN', address: 'AV. JUAN CARRASCO #717 COL. CENTRO CP 82000 MAZATLÁN, SINALOA', tel: '(669) 136 0237', email: 'mazatlan@distribuicionessantacruz.com' },
  { Title: 'DURANGO', address: 'ENRIQUE CARROLA ANTUNA #409 COL. CENTRO CP 34000 DURANGO, DURANGO ', tel: '(618) 811 2435', email: 'durango@distribuicionessantacruz.com' }
]

const Contact = () => {
  const [currentView, setCurrentView] = useState('form') // form || loading || sucess
  const [data, setData] = useObjectState({})
  const [errors, setErrors] = useState({ errors: [], message: null })

  const handleChange = ({ name, value }) => {
    setData({ [name]: value })
  }

  const handleSend = async event => {
    const inputsWithErrors = validateForm.requires(data, ['name', 'email', 'number', 'content'])
    if (inputsWithErrors) setErrors({ errors: inputsWithErrors, message: 'Todos los campos son requeridos' })
    else if (!validateForm.validateEmail(data.email)) {
      setErrors({ errors: ['email'], message: 'El correo no es válido' })
    } else {
      setCurrentView('loading')
      await message.add(data)
      setCurrentView('success')
    }
  }

  const handleRemoveErrors = params => {
    const NewErrors = errors.errors.filter(error => error !== params.name)
    setErrors({
      errors: NewErrors,
      message: NewErrors.length > 0 ? errors.message : null
    })
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Cover />
      <CoverSecondary $disableFill content={<span style={{ fontSize: '.8em', lineHeight: '.8em' }}>CONTACTANOS</span>} />
      <Container>
        {currentView === 'form' && (
          <Form
            data={data}
            handleChange={handleChange}
            handleSend={handleSend}
            errors={errors.errors}
            errorMessage={errors.message}
            handleRemoveErrors={handleRemoveErrors}
          />
        )}
        {currentView === 'loading' && (
          <Grid container justify='center' alignItems='center' style={{ minHeight: '100px', marginBottom: '50px' }}>
            <CircularProgress />
          </Grid>
        )}
        {currentView === 'success' && (
          <Grid container justify='center' alignItems='center' style={{ minHeight: '100px', marginBottom: '50px' }}>
            <Paper elevation={3}>
              <Box p={5}>
                <ContentText>
                  Listo, hemos enviado tu mensaje
                </ContentText>
              </Box>
            </Paper>
          </Grid>
        )}
      </Container>
      <FulWidth>
        <Container>
          <SubtitleSecondary>CENTROS DE DISTRIBUCIÓN</SubtitleSecondary>
          <Paragraph>De Norte a Sur distribuyendo calidad y servicio</Paragraph>
          <Grid container spacing={4} style={{ marginTop: '50px' }}>
            {stores.map((store, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Card {...store} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </FulWidth>
    </Layout>
  )
}
const ContentText = styled.div`
  font-family: var(--front-font);
  color: var(--front-red);
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  max-width: 700px;
  @media screen and (max-width:1400px) {
    font-size: 2.5rem;
    max-width: 600px;
  }
  @media screen and (max-width:700px) {
    font-size: 1.6rem;
    max-width: 400px;
  }
`

const FulWidth = styled.div`
  background-image: url(${fill});
  padding: 50px 0px;
`

const SubtitleSecondary = styled.div`
  font-family: var(--front-font);
  font-weight: bold;
  text-align: center;
  font-size: 2.7em;
  line-height: 1em;
  color: var(--front-red-dark);
  @media screen and (max-width:1600px) {
    font-size: 2.5em;
  }
  @media screen and (max-width:1250) {
    font-size: 2.3em;
  }
  @media screen and (max-width:960px) {
    font-size: 2em;
  }
`
const Paragraph = styled.div`
  text-align: center;
  color: var(--front-red-dark);
  font-size: 1.7em;
`

export default Contact
