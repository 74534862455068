import styled from 'styled-components'
import { Container as ContainerBase } from '../../components/main'

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Summary = styled.div`
  color: var(--front-red);
  font-family: var(--front-font);
  background: #f1f1f1;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
  width: 400px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: right;
  & span {
    font-family: sans-serif;
    font-weight: normal;
    color: var(--user-gray);
    margin-right: 10px;
  }
  @media (max-width: 1600px) {
    width: 300px;
    font-size: 1.3em;
    padding: 20px;
  }
  @media (max-width: 400px) {width: 100%}
  @media (max-width: 700px) { 
    font-size: 1.1em;
    padding: 10px;
  }
`
export const Total = styled.div`
  color: #fff;
  margin-top: 10px;
  background: var(--front-red-dark);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  font-size: 1.8em;
  font-weight: bold;
  text-align: right;
  & span {
    font-size: .8em;
    font-weight: normal;
  }
  @media (max-width: 1600px) {
    width: 300px;
    font-size: 1.6em;
  }
  @media (max-width:400px) {width: 100%}
  @media (max-width: 700px) { 
    font-size: 1.1em;
    padding: 10px;
  }
`
export const Container = styled(ContainerBase)`
  max-width: 1600px;
  margin-bottom: 50px;
`
export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`
export const ButtonStyled = styled.div`
  font-size: 1em;
  max-width:230px;
  width:100%;
  font-weight: bold;
  padding-top: 15!important;
  padding-bottom: 15!important;
  @media (max-width:1600px) {
    padding-top: 13!important;
    padding-bottom: 13!important;
  }
  @media (max-width:900px) {
    font-size: .9em!important;
  }
`
export const IconShipping = styled.img`
  width: 50px;
  margin-right: 10px;
  @media (max-width:1300px) {
    width: 40px;
  }
  @media (max-width:900px) {
    width: 30px;
  }
  @media (max-width:600px) {
    display: none;
  }
`
