import React from 'react'
import styled from 'styled-components'
import { Container } from '../../components/main'
import { Grid, Box, ButtonBase, Hidden } from '@material-ui/core'
import productsSrc from '../../../assets/home_products.png'
import assesoriaSrc from '../../../assets/asesoria.png'
import recetasSrc from '../../../assets/recetas.png'
import recetasSrcMobile from '../../../assets/recetasm.png'
import SuportSrc from '../../../assets/suport.png'
import sucursalesSrc from '../../../assets/sucursales.PNG'
import { Link as LinkBase } from 'react-router-dom'

const Link = styled(LinkBase)`
  color: inherit;
  text-decoration: none;
`

const Section = styled.section`
  padding: 50px 0px;
  @media screen and (max-width:1600px) {
    font-size: .8em;
  }
  @media screen and (max-width:1150px) {
    font-size: .7em;
  }
  @media screen and (max-width:800px) {
    font-size: .5em;
    padding: 30px 0px;
  }
`

const Card = styled.div`
  padding: 40px 30px;
  border-radius: 15px;
  background: #cdcdcd;
  min-height: 200px;
  height: 100%;
  @media screen and (max-width:1400px) {
    padding: 20px 20px;
  }
  @media screen and (max-width:800px) {
    padding: 10px;
    min-height: 100px;
  }
`

const ProducstsCard = styled(Card)`
  background: #ffac00;
  background-image: url(${productsSrc});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  @media (max-width:800px) {
    background-size: 55%;
  }
`
const RecetasMobileCard = styled(Card)`
  background: #bf3500;
  background-image: url(${recetasSrcMobile});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  @media (max-width:800px) {
    background-size: 43%;
  }
`

const AssesoriaCard = styled(Card)`
  background: #e1dcd0;
`
const SuportPicture = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 230px;
`

const Title = styled.div`
  font-size: 3em;
  width: 100%;
  box-sizing: border-box;
  color: var(--front-red);
  font-family: var(--front-font);
  font-weight: bold;
  @media (max-width:600px) {
    font-size: 2.5em;
  }
`
const Text = styled.div`
  font-size: 1.5em;
  color: var(--front-red-dark);
`

const Button = styled(ButtonBase)`
  font-size: 1.3em;
  background: #fff;
  color: var(--front-red);
  border-radius: 10px;
  padding: 20px  30px;
  @media screen and (max-width:1400px) {
    padding: 10px  15px;
  }
`
const SuportLineRed = styled.div`
  background: #ab0025;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
  width: 130%;
  @media screen and (max-width:600px) {
    padding: 5px;
    font-size: .8em;
  }
`

const HoursSuport = styled(Text)`
  font-size: 1.1em;
  margin-top: 20px;
  @media screen and (max-width:600px) {
    font-size: .9em;
    margin-top: 10px;
  }
`

const RecetCard = styled(Card)`
  background: #bf3500;
`
const SucursalesCard = styled(Card)`
border-bottom: 25px solid #a0acc7;
  background: #ebe6da;
  & img {
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 30px;
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 30px;
    margin-bottom: -40px;
    @media screen and (max-width:1400px) {
      margin-bottom: -20px;
    }
    @media screen and (max-width:959px) {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  @media screen and (max-width:959px) {
    border-width: 15px;
  }
`
const SupportCard = styled(Card)`
  position: relative;
  height: 100%;
  background: #e2cb88;
  & img {
    width: 50%;
    display: block;
  }
`

const Products = () => (
  <ProducstsCard as={Grid} container>
    <Grid item xs={7} lg={6}>
      <Box mb={1}>
        <Title>Productos</Title>
      </Box>
      <Text>
        Todo lo que necesitas para tu pastelería o panadería, te ofrecemos lo mejor en materia prima.
      </Text>
      <Box pt={{ xs: 1, lg: 4 }} pb={{ xs: 1, lg: 2 }}>
        <Link to='/productos'>
          <Button>Ver productos</Button>
        </Link>
      </Box>
    </Grid>
  </ProducstsCard>
)

const RecetasMobile = () => (
  <RecetasMobileCard as={Grid} container>
    <Grid item xs={7} lg={6}>
      <Box mb={1}>
        <Title style={{ color: '#fff' }}>Recetas</Title>
      </Box>
      <Text style={{ color: '#fff' }}>Cocina algo diferente todos los días.</Text>
      <Box pt={1} pb={1}>
        <Link to='/recetas'>
          <Button>Ver recetas</Button>
        </Link>
      </Box>
    </Grid>
  </RecetasMobileCard>
)

const Asesoria = () => (
  <AssesoriaCard as={Grid} container>
    <Grid item xs={12}>
      <Box mb={1}>
        <Title>Asesoría</Title>
      </Box>
      <Text>
        Emprende tu negocio, nosotros te asesoramos.
      </Text>
      <Box pt={{ xs: 1, lg: 2 }} pb={{ xs: 1, lg: 2 }}>
        <Link to='/contacto'>
          <Button>Contactanos</Button>
        </Link>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <img style={{ display: 'block', margin: 'auto' }} src={assesoriaSrc} />
    </Grid>
  </AssesoriaCard>
)

const Suport = () => (
  <SupportCard as={Grid} container alignItems='flex-start'>
    <Title>Contacto</Title>
    <Grid item xs={7}>
      <Text>
        Te ayudamos a resolver tus dudas
      </Text>
      <SuportLineRed>
        <Text style={{ color: '#fff' }}>
          Llámanos
        </Text>
        <Text style={{ fontWeight: 'bold', color: '#fff', fontSize: '1.6em', fontFamily: 'var(--front-font)' }}>
          664-902-0177
        </Text>
      </SuportLineRed>
      <HoursSuport>
        <div style={{ fontWeight: 'bold' }}>
          Horario de atencion
        </div>
        <div>
          Lun - Sáb de 9:00 a 18:00 hrs.
        </div>
      </HoursSuport>

    </Grid>
    <SuportPicture src={SuportSrc} />
  </SupportCard>
)

const Recetas = () => (
  <RecetCard as={Grid}>
    <Title style={{ color: '#fff' }}>Recetas</Title>
    <Text style={{ color: '#fff' }}>Cocina algo diferente todos los días.</Text>
    <Box pt={3}>
      <Link to='/recetas'>
        <Button>Ver recetas</Button>
      </Link>
    </Box>
    <img style={{ margin: '40px auto 0px auto', display: 'block' }} src={recetasSrc} />
  </RecetCard>
)

const Sucursales = () => (
  <SucursalesCard>
    <Title>Sucursales</Title>
    <Text>De norte a sur distribuyendo calidad y servicio.</Text>
    <img src={sucursalesSrc} />
  </SucursalesCard>
)
const SucursalesMobile = () => (
  <SucursalesCard as={Grid} container alignItems='center'>
    <Grid item xs={7}>
      <Title>Sucursales</Title>
      <Text>De norte a sur distribuyendo calidad y servicio.</Text>
    </Grid>
    <Grid item xs={5}>
      <img src={sucursalesSrc} />
    </Grid>
  </SucursalesCard>
)

const Cards = props => {
  return (
    <Container>
      <Section>
        <Hidden smDown>
          <Grid container spacing={2} alignItems='stretch'>
            <Grid item xs={8} container spacing={2}>
              <Grid item xs={12} style={{ height: '40%' }}>
                <Products />
              </Grid>
              <Grid item xs={6} style={{ height: '60%' }}>
                <Asesoria />
              </Grid>
              <Grid item xs={6}>
                <Suport />
              </Grid>
            </Grid>
            <Grid item xs={4} container spacing={2}>
              <Grid item xs={12}>
                <Recetas />
              </Grid>
              <Grid item xs={12}>
                <Sucursales />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Products />
            </Grid>
            <Grid item xs={12}>
              <RecetasMobile />
            </Grid>
            <Grid item xs={6}>
              <Asesoria />
            </Grid>
            <Grid item xs={6}>
              <Suport />
            </Grid>
            <Grid item xs={12}>
              <SucursalesMobile />
            </Grid>
          </Grid>
        </Hidden>
      </Section>
    </Container>
  )
}

export default Cards
