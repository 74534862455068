import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { toArray } from '../../../helpers/transformer'
import { dispatch } from '../../../store'
import { fetchAll } from '../../../flux/items'
import { useParams } from 'react-router-dom'
import suffle from '../../../helpers/array_suffle'
import Product from '../../components/item'
import { Grid, Box } from '@material-ui/core'
import { Container } from '../../components/main'

const Related = props => {
  var { items, isfinally } = useSelector(state => state.items)
  const { id } = useParams()
  items = suffle(toArray(items).filter(item => item.id !== id))
  items = items.slice(0, 4)

  console.log('related', items)

  // fetch data
  useEffect(() => {
    if (items.length < 4 && !isfinally) {
      console.log('fetching data of inside related...')
      dispatch(fetchAll())
    }
  }, [])

  if (items.length < 4) return null

  return (
    <Container>
      <Box pt={{ xs: 2, md: 4, lg: 8 }} pb={{ xs: 2, md: 4, lg: 8 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <Line />
          </Grid>
          <Grid item xs={6} md={5}>
            <Title>
              Productos que puedes necesitar
            </Title>
          </Grid>
          <Grid item xs>
            <Line />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {items.map(item => (
          <Grid key={item.id} item xs={6} lg={3} md={4}>
            <Product
              {...item}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
const Title = styled.div`
  text-align: center;
  font-size: 2.5rem;
  font-family: var(--front-font);
  color: var(--front-red);
  font-weight: bold;
  @media screen and (max-width:1600px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width:1300px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width:1100px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width:959px) {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  @media screen and (max-width:500px) {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
`

const Line = styled.div`
  border: 2px solid #f7f5f0;
`

export default Related
